import * as angular from 'angular';
import MapService from '../services/MapService';
import SiteService from '../services/SiteService';

const EditSiteController = function ($scope, $state, $stateParams, $timeout, $translate, user, organization, SiteService, sitesMap, 
  GoogleMapApi, MapService) {

  const vm = this;
  vm.translate = $translate.instant;
  const defaultSite = {
    longitude: organization.longitude,
    latitude: organization.latitude,
  };

  vm.siteid = $stateParams.siteid;
  vm.user = user;
  vm.mapOptions = MapService.DEFAULT_OPTIONS;
  vm.site = sitesMap[$stateParams.siteid] || defaultSite;
  vm.longitude = vm.site.longitude;
  vm.latitude = vm.site.latitude;
  vm.errors = {};

  GoogleMapApi.then((maps) => {
    vm.map = {
      center: {
        longitude: vm.site.longitude || defaultSite.longitude,
        latitude: vm.site.latitude || defaultSite.latitude,
      },
      zoom: organization.zoomLevel,
    };

    vm.marker = {
      id: 0,
      coords: {
        longitude: vm.site.longitude || defaultSite.longitude,
        latitude: vm.site.latitude || defaultSite.latitude,
      },
      options: { draggable: true },
      events: {
        drag(marker, eventName, args) {
          vm.longitude = marker.getPosition().lng();
          vm.latitude = marker.getPosition().lat();
          $scope.$apply();
        },
      },
    };
  });

  vm.editSite = function () {
    vm.showAlert = false;
    vm.alertClass = null;
    vm.alertMessage = null;

    vm.site.longitude = vm.longitude;
    vm.site.latitude = vm.latitude;
    vm.site.depth = parseFloat(vm.site.depth);

    SiteService.update(vm.site).then((ret) => {
      vm.showAlert = true;
      vm.alertClass = 'success';
      vm.alertMessage = $translate.instant('SAVE_SUCCESS');

      $timeout(() => {
        $state.transitionTo($state.current, $stateParams, {
          reload: true,
          inherit: false,
          notify: true,
        });
      }, 2000);
    }, (err) => {
      vm.showAlert = true;
      vm.alertClass = 'danger';
      vm.alertMessage = $translate.instant('SAVE_FAILED');
      vm.errors = err.errors;
      Object.entries(err.errors).forEach(([key, error]) => {
        if (error.path) {
          $(`#${error.path}`).addClass('is-invalid');
        } else {
          $(`#${key}`).addClass('is-invalid');
        }
      });
    });
  };
}
// eslint-disable-next-line max-len
EditSiteController.$inject = ['$scope', '$state', '$stateParams', '$timeout', '$translate', 'user', 'organization', 'SiteService', 'sitesMap', 'uiGmapGoogleMapApi', 'MapService'];

export default angular.module('EditSiteController', [MapService, SiteService]).controller('EditSiteController', EditSiteController).name;
