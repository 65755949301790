import * as angular from 'angular';

const ReportsController = function ($stateParams, user, currentUser) {
  const vm = this;
  vm.user = user;
  vm.currentUser = currentUser;
  vm.isAdmin = ['admin', 'superadmin'].includes(currentUser.role);
  vm.diverId = $stateParams.uid;
}

ReportsController.$inject = ['$stateParams', 'user', 'currentUser'];

export default angular.module('ReportsController', []).controller('ReportsController', ReportsController).name;
