import * as angular from 'angular';
import ItemService from '../services/ItemService';

const NewItemController = function ($state, $stateParams, $translate, userContext, ItemService) {
  const vm = this;
  const translate = $translate.instant;
  vm.user = userContext.user;
  vm.item = {};
  vm.errors = {};

  vm.createItem = function () {
    vm.showAlert = false;
    vm.alertClass = null;
    vm.alertMessage = null;

    const newItem = {};
    newItem.name = vm.item.name || '';
    newItem.amount = vm.item.amount || 0;
    newItem.description = vm.item.description || '';

    ItemService.add(newItem).then((res) => {
      $state.go('index.users.organization.items.list', {
        uid: vm.user.id,
        organizationId: $stateParams.organizationId,
        inventoryId: $stateParams.inventoryId,
      });
    }, (err) => {
      vm.showAlert = true;
      vm.alertClass = 'danger';
      vm.alertMessage = translate('COULD_NOT_ADD_ITEM');
      vm.errors = err.errors;
      Object.entries(err.errors).forEach(([key, error]) => {
        if (error.path) {
          $(`#${error.path}`).addClass('is-invalid');
        } else {
          $(`#${key}`).addClass('is-invalid');
        }
      });
    });
  };
  vm.item.name = '';
  vm.item.amount = 0;
  vm.item.description = '';
}
NewItemController.$inject = ['$state', '$stateParams', '$translate', 'userContext', 'ItemService'];

export default angular.module('NewItemController', [ItemService]).controller('NewItemController', NewItemController).name;
