import * as angular from 'angular';

const sumValues = function () {
  return function (data, key1) {
    if (angular.isUndefined(data) || angular.isUndefined(key1))
      return '';
    var sum = 0;
    angular.forEach(data, function (value) {
      let val = parseInt(value[key1], 10);
      if (isNaN(val)) {
        sum = '';
      } else {
        sum = sum + val;
      }
    });
    if (typeof sum === 'number') {
      return sum;
    } else {
      return '';
    }
  };
};

export default angular.module('sumValues', []).filter('sumValues', sumValues).name;
