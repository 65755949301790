import * as angular from 'angular';

const uploadFile = function ($parse) {

  return {
    restrict: 'A', // the directive can be used as an attribute only

    /*
            link is a function that defines functionality of directive
            scope: scope associated with the element
            element: element on which this directive used
            attrs: key value pair of element attributes
            */
    link(scope, element, attrs) {
      const model = $parse(attrs.uploadFile);
      const modelSetter = model.assign; // define a setter for demoFileModel

      // Bind change event on the element
      element.bind('change', () => {
        // Call apply on scope, it checks for value changes and reflect them on UI
        scope.$apply(() => {
          // set the model value
          modelSetter(scope, element[0].files[0]);
        });
      });
    },
  };
}
uploadFile.$inject = ['$parse'];

export default angular.module('uploadFile', []).directive('uploadFile', uploadFile).name;
