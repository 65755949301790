import * as angular from 'angular';
import ExposureService from '../services/ExposureService';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const EditExposureController = function ($scope, $state, $stateParams, $translate, user, ExposureService) {

  const vm = this;
  const translate = $translate.instant;
  vm.disabled = false;
  vm.user = user;
  vm.diverId = $stateParams.uid;
  vm.exposureId = $stateParams.eid;
  vm.errors = {};
  vm.fields = {
    exposure_type: [],
    substance_type: [],
    protection: [],
    dirty_equipment_handling: [],
    post_incident_actions: {
      protection_level: [],
    },
  };
  vm.chosen = [];
  vm.exposure = null;
  vm.chosenExposureMethod = null;
  vm.chosenExposureTypes = [];
  vm.chosenSubstanceTypes = [];
  vm.exposureMethods = ['rescuework', 'firefighting', 'smokedive', 'waterdive', 'chemicaldive', 
    'maintenance', 'forestfire', 'firecauseinspection', 'other'];
  vm.exposureMethodItems = vm.exposureMethods.map((v, i) => { return { id: i, name: translate(v.toUpperCase()), value: v } });
  vm.exposureTypes = ['airway', 'skin', 'mucous_membrane', 'eyes', 'injection', 'ingestion', 'other'];
  vm.exposureTypeItems = vm.exposureTypes.map((v, i) => { return { id: i, name: translate(v.toUpperCase()), value: v } });
  vm.exposureReasons = ['long_operating_time', 'insufficient_protection', 'anomaly', 'other'];
  vm.postIncidentProtection = ['motorMask', 'fffp1', 'fffp2', 'fffp3', 'vinylGloves', 'tyvekSuit', 'apron', 'protectiveGlasses']
  vm.postIncidentProtectionItems =  vm.postIncidentProtection.map((v, i) => { return { id: i, name: translate(v.toUpperCase()), value: v } });
  vm.protection = ['fireSuit', 'fireGloves', 'fireBoots', 'fireHelmet', 'breathingApparatus', 'smokeDiveMask', 'drySuit', 'chemicalSuit', 
    'coldSuit', 'lightSuit', 'vinylGloves', 'rubberGloves', 'wetGloves', 'dryGloves', 'cottonGloves', 'skiMask', 'fullFaceMask', 'respiratorFilter', 
    'motorMask', 'secureBoots'];
  vm.protectionItems = vm.protection.map((v, i) => { return { id: i, name: translate(v.toUpperCase()), value: v } });
  vm.substanceTypes = ['pah_compounds', 'metals', 'solvents', 'asbestos', 'carbonfiberscomposites', 'radiation', 'other'];
  vm.substanceTypeItems = vm.substanceTypes.map((v, i) => { return { id: i, name: translate(v.toUpperCase()), value: v } });
  // vm.dirtyEquipmentHandling = ['washed_on_site', 'undressing_area_setup', 'protective_platform_in_undressing_area',
  //   'fire_suit_undressed_with_respirator_filter', 'fire_suit_undressed_with_gloves', 'fire_suit_put_to_sealable_cleaning_bag',
  //   'fire_suit_put_to_plastic_bag', 'equipment_cleaned_on_site', 'equipment_sent_for_cleaning', 'exposed_items_transported_in_own_unit',
  //   'exposed_items_transported_in_trailer', 'exposed_items_transported_in_maintenance_unit'];
  vm.dirtyEquipmentHandling = ['maintenance_unit_on_site', 'maintenance_area_defined', 'maintenance_area_responsible_designated', 
    'maintenance_protection_adequate', 'maintenance_equipment_prewashed', 'maintenance_bagging', 'maintenance_unit_transport',
    'maintenance_own_unit_transport'];
  vm.dirtyEquipmentHandlingItems = vm.dirtyEquipmentHandling.map((v, i) => { return { id: i, name: translate(v.toUpperCase()), value: v } });
  vm.checked = true;
  vm.exposureMethodCallback = function (add, option, model, evt) {
    vm.chosenExposureMethod = model[0].value;
  }
  vm.exposureTypeCallback = function (add, option, model, evt) {
    vm.chosenExposureTypes = model.map((v) => v.value);
  }
  vm.substanceTypeCallback = function (add, option, model, evt) {
    vm.chosenSubstanceTypes = model.map((v) => v.value);
  }

  ExposureService.get(vm.exposureId).then((exposure) => {
    vm.exposure = exposure;
    vm.chosenExposureMethod = vm.exposure.exposure_method;
    vm.chosenExposureTypes = vm.exposure.exposure_type;
    vm.chosenSubstanceTypes = vm.exposure.substance_type;

    vm.fields = {
      ...vm.exposure,
      time_start: dayjs(vm.exposure.time_start).utc().second(0).millisecond(0).toDate(),
      protection: vm.protectionItems.filter((v) => vm.exposure.protection && vm.exposure.protection[v.value]),
      post_incident_actions: {
        protection_level: vm.postIncidentProtectionItems.filter((v) => vm.exposure.post_incident_actions?.protection_level[v.value]),
      },
      exposure_type: vm.exposureTypeItems.filter((v) => vm.exposure.exposure_type && vm.exposure.exposure_type[v.value]),
      substance_type: vm.substanceTypeItems.filter((v) => vm.exposure.substance_type && vm.exposure.substance_type[v.value]),
      dirty_equipment_handling: vm.dirtyEquipmentHandlingItems.filter((v) => vm.exposure.dirty_equipment_handling && 
        vm.exposure.dirty_equipment_handling[v.value]),
      equipment_cleaning: {
        number_of_washes: vm.exposure.equipment_cleaning?.number_of_washes || 0,
        equipment_destroyed: vm.exposure.equipment_cleaning?.equipment_destroyed || false,
        ozonisation: vm.exposure.equipment_cleaning?.ozonisation || false,
        additional_care_required: vm.exposure.equipment_cleaning?.additional_care_required || false,
      }
    };
  });

  vm.updateExposure = function () {
    const data = {
      ...vm.fields,
      user_id: vm.diverId,
      time_start: dayjs(vm.fields.time_start).valueOf(),
      exposure_type: Object.fromEntries(vm.fields.exposure_type.map((v) => [v.value, true])),
      substance_type: Object.fromEntries(vm.fields.substance_type.map((v) => [v.value, true])),
      protection: Object.fromEntries(vm.fields.protection.map((v) => [v.value, true])),
      dirty_equipment_handling: Object.fromEntries(vm.fields.dirty_equipment_handling.map((v) => [v.value, true])),
      post_incident_actions: {
        ...vm.fields.post_incident_actions,
        protection_level: Object.fromEntries(vm.fields.post_incident_actions.protection_level.map((v) => [v.value, true])),
      },
      equipment_cleaning: {
        ...vm.fields.equipment_cleaning,
      }
    }
    vm.showAlert = false;
    vm.alertClass = null;
    vm.alertMessage = null;

    ExposureService.update(data).then(() => {
      $state.go('index.users.organization.exposures', { uid: vm.diverId });
    }, (err) => {
      vm.showAlert = true;
      vm.alertClass = 'danger';
      vm.alertMessage = translate('COULD_NOT_UPDATE_EXPOSURE');
      vm.errors = err.errors;
      console.log(err);
      Object.values(err.errors).forEach((error) => {
        if (error.path) {
          $(`#${error.path}`).addClass('is-invalid');
        } else {
          $(`#${key}`).addClass('is-invalid');
        }
      });
    });
  };
}

EditExposureController.$inject = ['$scope', '$state', '$stateParams', '$translate', 'user', 'ExposureService'];

export default angular.module('EditExposureController', [ExposureService]).controller('EditExposureController', EditExposureController).name;
