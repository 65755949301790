import * as angular from 'angular';
import AuthenticationService from '../services/AuthenticationService';
import UserService from '../services/UserService';

const LoginController = function ($state, $window, $translate, UserService, AuthenticationService) {

  const vm = this;
  vm.loginShowAlert = false;
  vm.translate = $translate.instant;
  vm.fields = {
    username: '',
    password: '',
  };
  vm.lang = 'fi';

  vm.changeLanguage = function (key) {
    $translate.use(key);
  };

  vm.login = function () {
    vm.loginShowAlert = false;
    vm.loginAlertClass = null;
    vm.loginAlertMessage = null;
    vm.errors = {};

    if (vm.fields.username && vm.fields.password) {
      UserService.login(vm.fields.username, vm.fields.password)
        .then((data) => {
          AuthenticationService.isAuthenticated = true;
          AuthenticationService.isAdmin = data.user.admin;
          UserService.currentUser = data.user;
          $window.sessionStorage.token = data.token;
          $window.sessionStorage.apiKey = data.apiKey;
          // Go to new dive page by default
          $state.go('index.users.organization.new.waterdive', { uid: data.user._id, organizationId: data.organization._id });
        }, (err) => {
          if (err && err.status === 401) {
            vm.loginShowAlert = true;
				  	vm.loginAlertClass = 'danger';
				  	vm.loginAlertMessage = 'LOGIN_FAILED_CHECK_CREDENTIALS';
          } else if (err) {
            vm.loginShowAlert = true;
				  	vm.loginAlertClass = 'danger';
				  	vm.loginAlertMessage = 'LOGIN_FAILED';
				  	vm.errors = err.errors;
          } else {
            vm.loginShowAlert = true;
            vm.loginAlertClass = 'danger';
            vm.loginAlertMessage = 'LOGIN_FAILED_CONTACT_SUPPORT';
          }
          Object.entries(err.errors).forEach(([key, error]) => {
            if (error.path) {
              $(`#${error.path}`).addClass('is-invalid');
            } else {
              $(`#${key}`).addClass('is-invalid');
            }
          });
        });
    } else {
      vm.loginShowAlert = true;
      vm.loginAlertClass = 'danger';
      vm.loginAlertMessage = 'LOGIN_FAILED_CHECK_CREDENTIALS';
    }
  };
}

LoginController.$inject = ['$state', '$window', '$translate', 'UserService', 'AuthenticationService'];

export default angular.module('LoginController', [AuthenticationService, UserService]).controller('LoginController', LoginController).name;
