import * as angular from 'angular';
import options from './options';

const DocumentService = function (options) {

  const documentService = {};
  documentService.MOBILE_PDF_URL = 'https://s3-eu-west-1.amazonaws.com/pelastus20.com/'; // options.app.base_url
  documentService.PDF_URL = `${options.app.base_url}docs/`;

  return documentService;
}
DocumentService.$inject = ['options'];

export default angular.module('DocumentService', [options]).factory('DocumentService', DocumentService).name;
