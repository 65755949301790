import * as angular from 'angular';
import Highcharts from 'highcharts/highstock';
import UserService from '../services/UserService';

const StatisticsAllDivesController = function ($stateParams, $translate, sitesMap, user, DiveService, UserService) {

  const vm = this;
  const sitesObj = {};
  const sites = [];
  const siteNames = [];

  vm.user = user;
  vm.diveData = [];
  vm.diverId = $stateParams.uid;
  vm.deepDives = DiveService.getDeepDives;
  vm.problemDives = DiveService.getProblemDives;
  vm.alertDives = DiveService.getAlertDives;
  vm.testDives = DiveService.getTestDives;

  vm.otherTestDives = DiveService.getOtherTestDives;
  vm.trainingDives = DiveService.getTrainingDives;
  vm.poolDives = DiveService.getPoolDives;
  vm.iceDives = DiveService.getIceDives;
  vm.otherDives = DiveService.getOtherDives;
  vm.specialExercises = DiveService.getSpecialExerciseDives;

  vm.getLast = DiveService.getLast;
  vm.getFormer = DiveService.getFormer;
  vm.divers = [];
  vm.translate = $translate.instant;

  const plotSites = function (data) {
    angular.forEach(data, (value, index) => {
      if (value.site in sitesObj) {
        sitesObj[value.site].value++;
      } else if (value.site) {
        sitesObj[value.site] = { id: value.site, value: 1 };
      } else {
        sitesObj['?'] = { id: '?', value: 1 };
      }
    });

    const sorted = _.sortBy(Object.values(sitesObj), 'value').reverse();
    angular.forEach(sorted, (value, index) => {
      if (sitesMap[value.id]) {
        sites.push(value.value);
        siteNames.push(sitesMap[value.id].name);
      } else {
        console.log(`Could not find dive site for dive: ${value.id} ${value.value}`);
        sites.push(value.value);
        siteNames.push(value.id);
      }
    });

    Highcharts.chart('sites-container', {
      title: {
        text: `${vm.translate('ALL_DIVES')} / ${vm.translate('SITE_SHORT')}`,
        style: {
          color: '#fff'
        }
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: siteNames,
        labels: {
          style: {
            color: '#ffffff',
            fontSize: '14px'
          }
        }
      },
      yAxis: {
        opposite: true,
        title: '',
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white',
          },
        },
      },
      legend: {
        enabled: false,
      },
      chart: {
        height: `${Math.max(sites.length * 20, 500)}px`,
        type: 'bar',
      },
      series: [
        {
          name: vm.translate('DIVES'),
          data: sites,
        },
      ],
    });
  }

  const plotDives = function (data) {
    const tmpDives = [];
    const currentDate = Date.now();
    vm.diveData = data;
    angular.forEach(data, (value, index) => {
      if (currentDate - value.time_start > 0) {
        const tmpDive = [value.time_start, 1];
        tmpDives.push(tmpDive);
      }
    });
    Highcharts.StockChart('dives-container', {
      title: {
        text: vm.translate('ALL_ORGANIZATION_DIVES'),
      },
      chart: {
        height: '500px',
        type: 'line',
      },
      credits: {
        enabled: false
      },
      series: [
        {
          name: vm.translate('DIVES'),
          dataGrouping: {
            approximation: 'sum',
            forced: true,
            units: [
              ['month', [1]],
            ],
          },
          data: tmpDives.reverse(),
        },
      ],
    });
  }

  UserService.list($stateParams.organizationId).then((divers) => {
    vm.divers = divers.data.filter((diver) => diver.diver);
  });

  DiveService.listAll(vm.diverId).then((data) => {
    plotDives(data);
    plotSites(data);
  })
}
StatisticsAllDivesController.$inject = ['$stateParams', '$translate', 'sitesMap', 'user', 'DiveService', 'UserService'];

// eslint-disable-next-line max-len
export default angular.module('StatisticsAllDivesController', [UserService]).controller('StatisticsAllDivesController', StatisticsAllDivesController).name;
