import * as angular from 'angular';
import DiveService from '../services/DiveService';

const DivesController = function ($state, $translate, DiveService) {

  const vm = this;
  vm.translate = $translate.instant;

  vm.removeDive = function (diveId) {
    if (!diveId) {
      return;
    }

    DiveService.delete(diveId)
      .then((ret) => {
        console.log(ret);
        $state.go($state.current, {}, { reload: true });
      })
      .catch((err) => {
        console.log(err);
        alert(vm.translate('COULD_NOT_REMOVE'));
      });
  }
}
  
DivesController.$inject = ['$state', '$translate', 'DiveService'];

export default angular.module('DivesController', [DiveService]).controller('DivesController', DivesController).name;
