import * as angular from 'angular';

const filterRescueDive = () => function (input, filterVal, callback) {

  const filteredInput = {};
  angular.forEach(input, (value, key) => {
    const tmp_keys = ['time_start', 'address', 'type'];
    for (let i = 0; i < tmp_keys.length; i++) {
      if (value && value[tmp_keys[i]]) {
        let v = '';
        if (tmp_keys[i] == 'address' || tmp_keys[i] == 'type') {
          v = `${value[tmp_keys[i]]}`;
        } else if (tmp_keys[i] == 'time_start' && callback) {
          v = callback(value[tmp_keys[i]]);
        }
        if (v && v.name && v.name.toLowerCase().indexOf(filterVal) != -1) {
          filteredInput[key] = value;
        } else if (typeof v === 'string' && v.toLowerCase().indexOf(filterVal) != -1) {
          filteredInput[key] = value;
        }
      }
    }
  });
  return filteredInput;
}

export default angular.module('filterRescueDive', []).filter('filterRescueDive', filterRescueDive).name;
