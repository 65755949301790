/* eslint-disable max-len */
import 'jquery';
import * as angular from 'angular';
import Highcharts from 'highcharts/highstock';

const OrganizationController = function ($rootScope, $scope, $state, $window, $stateParams, $translate, $transitions, organization, user, userContext, inventories) {

  const vm = this;
  const translate = $translate.instant;
  vm.user = user;
  vm.currentUser = userContext.user;
  vm.organizationId = $stateParams.organizationId;
  vm.stateName = $state.current.name
  vm.isRescueState = $state.current.name.includes('rescue');
  vm.translate = $translate.instant;

  const organizationId = userContext.organization._id;
  vm.isMobile = $window.innerWidth < 768;
  vm.isAuthenticated = true;
  vm.currentUser = userContext.user;
  vm.currentOrganization = userContext.organization;
  const params = {
    uid: user.id,
    organizationId: vm.organizationId,
    inventoryId: inventories .length > 0 ? inventories[0]._id : ''
  }

  if (userContext.organization.name === 'Helsingin Pelastuslaitos') {
    $('#bg-div').empty();
    $('#bg-div').append('<img class="bg-image" src="./images/erottaja-black.jpg"/>');
  } else {
    $('#bg-div').empty();
    $('#bg-div').append('<img class="bg-image" src="./images/ocean_top_black.jpg"/>');
  }

  const defaultDiveType = user.diver ? 'water' : 'smoke';

  const handleItem = (item) => {
    if (item.state.includes('newdive')) {
      item.state = `${item.state}.${defaultDiveType}`
    }
    return item.state;
  }

  vm.updateMenus = () => {
    if (vm.currentOrganization.menus) {
      vm.left_items = vm.currentOrganization.menus.left.map((menu) => {
        const menuParams = menu.params.map((p) => `${p}:'${params[p]}'`).join(',');
        return {
          ...menu,
          name: $translate.instant(menu.name),
          state: `${handleItem(menu)}({${menuParams}})`
        }
      });

      vm.right_items = vm.currentOrganization.menus.right.map((menu) => {
        const menuParams = menu.params.map((p) => `${p}:'${params[p]}'`).join(',');
        return {
          ...menu,
          name: $translate.instant(menu.name),
          state: `${menu.state}({${menuParams}})`
        }
      });
    } else {
      // TODO remove this when all organizations have menus
      vm.left_items = [
        { name: $translate.instant('DIVES'), state: `index.users.organization.dives.map({uid:'${vm.user.id}', organizationId:'${organizationId}'})` },
        { name: $translate.instant('ADD_NEW'), state: `index.users.organization.new.waterdive({uid:'${vm.user.id}'})` },
        { name: $translate.instant('SITES'), state: `index.users.organization.sites.map({uid:'${vm.user.id}'})` },
        { name: $translate.instant('NEW_SITE'), state: `index.users.organization.new.site({uid:'${vm.user.id}'})` },
        { name: $translate.instant('DIVERS'), state: `index.users.organization.divers({uid:'${vm.user.id}'})` },
        { name: $translate.instant('SKIPPERS'), state: `index.users.organization.skippers({uid:'${vm.user.id}'})` },
      ];

      if (inventories.length > 0) {
        vm.left_items.push({
          name: $translate.instant('STORAGE'),
          state: `index.users.organization.items.list({uid:'${vm.user.id
          }', organizationId:'${organizationId}', inventoryId:'${inventories[0]._id}'})`
        });
      }

      vm.right_items = [
        { name: $translate.instant('PROFILE'), state: `index.users.organization.settings.info({uid:'${vm.user.id}'})` },
        { name: $translate.instant('CONTACTS'), state: `index.users.organization.contacts({uid:'${vm.user.id}'})` },
        { name: $translate.instant('LINKS'), state: `index.users.organization.links({uid:'${vm.user.id}'})` },
        { name: $translate.instant('DOCUMENTS'), state: `index.users.organization.documents({uid:'${vm.user.id}'})` },
        { name: $translate.instant('STATISTICS'), state: `index.users.organization.statistics.user({uid:'${vm.user.id}'})` },
        { name: $translate.instant('REPORTS'), state: `index.users.organization.reports.user({uid:'${vm.user.id}'})` },
        { name: $translate.instant('FEEDBACK'), state: `index.users.organization.feedback({uid:'${vm.user.id}'})` },
        { name: $translate.instant('GUIDE'), state: `index.users.organization.guide({uid:'${vm.user.id}'})` },
        { name: $translate.instant('LOGOUT'), state: 'index.logout' },
      ];
    }
  }

  if (vm.user) {
    if (vm.currentUser.settings?.lang) {
      $rootScope.lang = vm.currentUser.settings.lang;
      $translate.use(vm.currentUser.settings.lang).then(() => {
        vm.updateMenus();
      });
    } else if (organization.settings?.lang) {
      $rootScope.lang = organization.settings.lang;
      $translate.use(organization.settings.lang).then(() => {
        vm.updateMenus();
      });
    } else {
      $rootScope.lang = 'fi';
      $translate.use('fi').then(() => {
        vm.updateMenus();
      });
    }
  }

  $scope.$on('update-language', function(event, profileObj) {
    $translate.use(profileObj.key).then(() => {
      vm.updateMenus();
    });
  });

  // $transitions.onSuccess({ }, function(trans) {
  //   // const SpinnerService = trans.injector().get('SpinnerService');
  //   // SpinnerService.transitionStart();
  //   // trans.promise.finally(SpinnerService.transitionEnd);
  //   console.log('onStart', trans.to());
  //   if (trans.to() != null && trans.to().name.includes('rescue')) {
  //     vm.isRescueState = true;
  //     vm.updateMenus(true);
  //     console.log('true')
  //   } else {
  //     vm.isRescueState = false;
  //     vm.updateMenus(false);
  //   }
  // });

  Highcharts.setOptions({
    lang: {
      loading: translate('LOADING') + '...',
      months: ['Tammikuu', 'Helmikuu', 'Maaliskuu', 'Huhtikuu', 'Toukokuu', 'Kesäkuu', 'Heinäkuu', 'Elokuu', 
        'Syyskuu', 'Lokakuu', 'Marraskuu', 'Joulukuu'],
      weekdays: ['Sunnuntai', 'Maanantai', 'Tiistai', 'Keskiviikko', 'Torstai', 'Perjantai', 'Lauantai'],
      shortMonths: ['Tam', 'Hel', 'Maa', 'Huh', 'Tou', 'Kes', 'Hei', 'Elo', 'Syy', 'Lok', 'Mar', 'Jou'],
      exportButtonTitle: translate('EXPORT'),
      contextButtonTitle: translate('MENU'),
      printButtonTitle: translate('PRINT'),
      printChart: translate('PRINT'),
      rangeSelectorFrom: translate('STARTING'),
      rangeSelectorTo: translate('ENDING'),
      rangeSelectorZoom: translate('RANGE'),
      downloadPNG: translate('DOWNLOAD_PNG'),
      downloadJPEG: translate('DOWNLOAD_JPEG'),
      downloadPDF: translate('DOWNLOAD_PDF'),
      downloadSVG: translate('DOWNLOAD_SVG'),
      // resetZoom: "Reset",
      // resetZoomTitle: "Reset,
      // thousandsSep: ".",
      // decimalPoint: ','
    },
  });
  angular.element($window).bind('resize', () => {
    vm.isMobile = $window.innerWidth < 768;
    // manuall $digest required as resize event
    // is outside of angular
    $scope.$digest();
  });
}

OrganizationController.$inject = ['$rootScope', '$scope', '$state', '$window', '$stateParams', '$translate', '$transitions', 'organization', 'user', 'userContext', 'inventories'];

export default angular.module('OrganizationController', []).controller('OrganizationController', OrganizationController).name;
