import * as angular from 'angular';
import OrganizationService from '../services/OrganizationService';

const FeedbackController = function ($translate, organization, OrganizationService) {
  const vm = this;
  vm.content = '';
  const organizationId = organization._id;
    
  vm.sendFeedback = () => {
    console.log('sending feedback', { feedback: vm.content })
    OrganizationService.sendFeedback(vm.content, organizationId).then(() => {
      console.log('success')
      vm.showAlert = true;
      vm.alertClass = 'success';
      vm.alertMessage = $translate.instant('FEEDBACK_SENT');
    }, (err) => {
      vm.showAlert = true;
      vm.alertClass = 'danger';
      vm.alertMessage = $translate.instant('COULD_NOT_SEND_FEEDBACK');
      vm.errors = err.errors;
      Object.values(err.errors).forEach((error) => {
        $(`#feedback-${error.path}`).addClass('is-invalid');
      });
    });
  }
}

FeedbackController.$inject = ['$translate', 'organization', 'OrganizationService'];


export default angular.module('FeedbackController', [OrganizationService]).controller('FeedbackController', FeedbackController).name;
