import * as angular from 'angular';

const LinksController = function ($translate, organization) {

  const vm = this;
  vm.translate = $translate.instant;
  vm.links = organization.links;
}

LinksController.$inject = ['$translate', 'organization'];

export default angular.module('LinksController', []).controller('LinksController', LinksController).name;
