import * as angular from 'angular';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import DiveService from '../services/DiveService';
import Highcharts from 'highcharts/highstock';
import MapService from '../services/MapService';
import SiteService from '../services/SiteService';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(AdvancedFormat);

const EditWaterDiveController = function ($scope, $state, $stateParams, $timeout, $translate, user, organization, 
  sites, sitesMap, DiveService, GoogleMapApi, MapService, SiteService) {

  const translate = $translate.instant;
  const vm = this;
  vm.did = $stateParams.did;
  vm.diverId = $stateParams.uid;
  vm.user = user;
  vm.mapOptions = MapService.DEFAULT_OPTIONS;
  vm.errors = {};
  vm.layerOptions = MapService.getLayerOptions();
  vm.showNauticalLayer = false;
  vm.calcConsumption = DiveService.calcConsumption;
  vm.calcDivetime = DiveService.calcDivetime;
  vm.showComputerData = true;
  vm.divesites = sites;
  const volumes = DiveService.getVolumes();
  vm.updateVolume = () => {
    vm.dive.volume = volumes[vm.dive.apparatus];
  }
  const defaultConditions = DiveService.getDefaultConditions();

  vm.translateConditions = function (key) {
    return DiveService.translateConditions(key);
  };

  vm.dive_apparatus = DiveService.getApparatus();
  vm.search = organization.searchMethods ? organization.searchMethods.map((type) => { return { name: translate(type.name), value: type.name } } ) 
    : DiveService.getSearchMethods().map((v) => { return {name: translate(v), value: v }});
  vm.types = organization.diveTypes ? organization.diveTypes.map((type) => { return { name: translate(type.name), value: type.name } } ) 
    : DiveService.getDiveTypes().map((v) => { return {name: translate(v), value: v }});

  let diveProfile = [];
  let diveTemperatures = [];
  let tankPressures = {
    0: [],
    1: [],
    2: [],
  }
  let hasTankPressureData = false;
  const markerOptions = DiveService.getMarkerOptions();

  const diveModesMap = {
    'OpenCircuit': translate('OPEN_CIRCUIT'),
    'ClosedCircuit': translate('CLOSED_CIRCUIT'),
    'SemiClosedCircuit': translate('SEMI_CLOSED_CIRCUIT')
  }

  vm.toggleNauticalLayer = () => {
    vm.showNauticalLayer = !vm.showNauticalLayer;
  }

  vm.toggleShowComputerData = () => {
    vm.showComputerData = !vm.showComputerData;
  }

  vm.getDivemode = (computerDivemode) => {
    return diveModesMap[computerDivemode];
  }

  const { label1, label2 } = MapService.getLabels();

  const defaultSite = {
    longitude: organization.longitude,
    latitude: organization.latitude,
  };

  const updateMap = function () {
    GoogleMapApi.then(() => {
      vm.map = {
        center: {
          longitude: vm.site.longitude,
          latitude: vm.site.latitude,
        },
        zoom: organization.zoomLevel,
      };
    });
  };

  MapService.createMarkerStyle(label1, document);
  MapService.createMarkerStyle(label2, document);
  MapService.updateMarkers(sites, {}, vm.diverId, markerOptions);

  DiveService.get(vm.did).then((dive) => {
    if (dive.dive_type) {
      console.log('Invalid dive type, redirecing to correct one..')
      $state.go(`index.users.organization.editdive.${dive.dive_type}`, { uid: vm.diverId, did: dive._id });
    }
    vm.site = dive.site && dive.site !== '????' ? sitesMap[dive.site] : defaultSite;
    if (!sitesMap[dive.site] && dive.site !== '????') {
      SiteService.get(dive.site).then((data) => {
        sitesMap[dive.site] = data;
        vm.site = data;
        vm.sitename = data.name;
        vm.site.options = {
          labelAnchor: '12 45',
          labelClass: 'labelMarker2',
          labelContent: data.depth || '?',
          title: data.name,
        };
        updateMap();
      });
    }
    vm.sitename = vm.site?.name || translate('UNKNOWN');
    if (!dive.conditions) {
      console.log('Using default conditions');
      dive.conditions = defaultConditions;
    } else {
      console.log(dive);
      dive.conditions = { ...defaultConditions, ...dive.conditions };
    }
    dive.depth = typeof dive.depth === 'string' ? parseFloat(dive.depth.replace(',', '.')) : dive.depth;
    vm.dive = dive;
    
    // Note! date format changed to UTC string here!
    dive.time_start = dayjs(dive.time_start).second(0).millisecond(0).toDate();
    dive.time_end = dayjs(dive.time_end).second(0).millisecond(0).toDate();
    vm.diveMethod = { name: translate(dive.method), value: dive.method };
    vm.diveType = { name: translate(dive.type), value: dive.type };

    vm.diveTypesMap =  DiveService.getDiveTypesMap(organization);
    vm.isDive = () => {
      return vm.dive ? DiveService.isDive(vm.dive.type, organization) : true;
    }
    if (dive.computerData) {
      dive.computerData.samples.forEach((sample) => {
        diveProfile.push([dayjs(dive.time_start).valueOf() + (sample.Time * 1000), sample.Depth || 0]);
        diveTemperatures.push([dayjs(dive.time_start).valueOf() + (sample.Time * 1000), sample.Temperature || 0]);
        if (sample.Pressure) {
          hasTankPressureData = true;
          sample.Pressure.forEach((pressure) => {
            tankPressures[pressure.Tank].push([dayjs(dive.time_start).valueOf() + (sample.Time * 1000), pressure.Pressure]);
          });
        }
      });
      loadProfile();
    }
    MapService.updateMarkers(sites, vm.site, vm.diverId, markerOptions);
    updateMap();
  }, (err) => {
    console.log('Could not fetch dive data', err);
    $state.go(`index.users.organization.editdive.rescue`, { uid: vm.diverId, did: vm.did });
    vm.showAlert = true;
    vm.alertClass = 'danger';
    vm.alertMessage = translate('COULD_NOT_FETCH_DIVE');
    vm.errors = err.errors;
    Object.entries(err.errors).forEach(([key, error]) => {
      if (error.path) {
        $(`#dive-${error.path}`).addClass('is-invalid');
      } else {
        $(`#dive-${key}`).addClass('is-invalid');
      }
    });
  });

  vm.markersEvents = {
    click(gMarker, eventName, model) {
      vm.site = sitesMap[model._id];
      vm.dive.site = model._id;
      vm.sitename = model.name;
      vm.dive.depth = typeof model.depth === 'string' ? parseFloat(model.depth.replace(',', '.')) : model.depth;
      MapService.updateMarkers(sites, model, vm.diverId, markerOptions);
      $scope.$apply();
    },
  };

  vm.editDive = function () {
    vm.showAlert = false;
    vm.alertClass = null;
    vm.alertMessage = null;
    const updatedDive = { 
      ...vm.dive,
      type: vm.diveType.value,
      method: vm.diveMethod.value,
      time_start: dayjs(vm.dive.time_start).valueOf(),
      time_end: dayjs(vm.dive.time_end).valueOf(),
      volume: volumes[vm.dive.apparatus],
      pressure_end: parseFloat(vm.dive.pressure_end)
    }

    DiveService.update(updatedDive).then((ret) => {
      vm.showAlert = true;
      vm.alertClass = 'success';
      vm.alertMessage = translate('SAVE_SUCCESS');

      $timeout(() => {
        $state.transitionTo($state.current, $stateParams, {
          reload: true,
          inherit: false,
          notify: true,
        });
      }, 2000);
    }, (err) => {
      vm.showAlert = true;
      vm.alertClass = 'danger';
      vm.alertMessage = translate('COULD_NOT_SAVE');
      vm.errors = err.errors;
      Object.values(err.errors).forEach((error) => {
        $(`#dive-${error.path}`).addClass('is-invalid');
        // vm.alertMessage += error.message;
      });
    });
  };

  const loadProfile = function () {
    let chartConf = {
      title: '',
      chart: {
        type: 'spline'
      },
      credits: {
        enabled: false
      },
      yAxis: [
        {
          title: {
            text: translate('DEPTH') + ' (' + translate('METRES_SHORT') + ')'
          },
          reversed: true,
          min: 0
        },
        {
          title: {
            text: translate('TEMPERATURE') + ' (\u2103)'
          },
          reversed: false,
          opposite: true
        },
        // {
        //   title: {
        //     text: 'Consumption (l/min)'
        //   },
        //   reversed: false,
        //   opposite: true
        // },
      ],
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: { // don't display the year
          second: '%S',
        },
        title: {
          text: translate('TIME')
        }
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
            radius: 1.5
          },
          lineWidth: 1
        }
      },

      colors: ['#6CF', '#39F', '#06C', '#036', '#000'],

      // Define the data points. All series have a year of 1970/71 in order
      // to be compared on the same x axis. Note that in JavaScript, months start
      // at 0 for January, 1 for February etc.
      series: [
        {
          name: translate('PROFILE'),
          data: diveProfile,
          tooltip: {
            headerFormat: '',
            pointFormat: '{point.x:%H:%M:%S}: {point.y:.2f} m'
          },
          yAxis: 0
        },
        {
          name: translate('TEMPERATURE'),
          data: diveTemperatures,
          tooltip: {
            headerFormat: '',
            pointFormat: '{point.x:%H:%M:%S}: {point.y:.2f}\u2103'
          },
          yAxis: 1,
          visible: false
        },
        
        // {
        //   name: 'Ilmankulutus',
        //   dataGrouping: {
        //     approximation: function () {
        //       let start = this.dataGroupInfo.start;
        //       return this.dataGroupInfo.length;
        //     },
        //     forced: true,
        //     units: [
        //       ['minute', [1]],
        //     ],
        //     enabled: true
        //   },
        //   data: tankPressures[0],
        //   yAxis: 3
        // },
      ]
    }
    if (hasTankPressureData) {
      chartConf.yAxis.push({
        title: {
          text: translate('PRESSURE') + ' (bar)'
        },
        reversed: false,
        opposite: true
      });
      chartConf.series.push({
        name: translate('TANK_PRESSURE'),
        data: tankPressures[0],
        tooltip: {
          headerFormat: '',
          pointFormat: '{point.x:%H:%M:%S}: {point.y:.2f} bar'
        },
        yAxis: 2,
        visible: false
      });
    }
    Highcharts.chart('hc-container', chartConf);
  }
}

// eslint-disable-next-line max-len
EditWaterDiveController.$inject = ['$scope', '$state', '$stateParams', '$timeout', '$translate', 'user', 'organization', 'sites', 'sitesMap', 'DiveService', 'uiGmapGoogleMapApi', 'MapService', 'SiteService'];

// eslint-disable-next-line max-len
export default angular.module('EditWaterDiveController', [SiteService, MapService, DiveService]).controller('EditWaterDiveController', EditWaterDiveController).name;
