import * as angular from 'angular';
import DiveService from '../services/DiveService';
import MapService from '../services/MapService';
import SiteService from '../services/SiteService';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

// eslint-disable-next-line max-len
const NewWaterDiveController = function ($scope, $state, $stateParams, $translate, user, organization, DiveService, GoogleMapApi, sites, MapService, SiteService) {

  const vm = this;
  const translate = $translate.instant;
  vm.disabled = false;
  vm.user = user;
  vm.diverId = $stateParams.uid;
  vm.divesites = sites;
  vm.mapOptions = MapService.DEFAULT_OPTIONS;
  vm.errors = {};
  vm.siteErrors = {};
  vm.layerOptions = MapService.getLayerOptions();
  vm.showNauticalLayer = false;
  vm.chosen = [];
  vm.site = {};
  vm.showNewSite = false;
  vm.calcConsumption = DiveService.calcConsumption;
  vm.calcDivetime = DiveService.calcDivetime;
  vm.translateConditions = DiveService.translateConditions;
  vm.diveTypesMap =  DiveService.getDiveTypesMap(organization);
  vm.search = organization.searchMethods ? organization.searchMethods.map((type) => { return { name: translate(type.name), value: type.name } } ) 
    : DiveService.getSearchMethods().map((v) => { return {name: translate(v), value: v }});
  vm.types = organization.diveTypes ? organization.diveTypes.map((type) => { return { name: translate(type.name), value: type.name } } ) 
    : DiveService.getDiveTypes().map((v) => { return {name: translate(v), value: v }});
  vm.checked = true;
  vm.diveApparatus = DiveService.getApparatus();
  const markerOptions = DiveService.getMarkerOptions();

  const volumes = DiveService.getVolumes();
  const { label1, label2 } = MapService.getLabels();
  MapService.createMarkerStyle(label1, document);
  MapService.createMarkerStyle(label2, document);

  vm.toggleNauticalLayer = () => {
    vm.showNauticalLayer = !vm.showNauticalLayer;
  }

  vm.toggleShowNewSite = () => {
    vm.showNewSite = !vm.showNewSite;
  }

  // for fetching wms capabilities from trafi's interface, not needed currently
  // MapService.getCapabilities();

  const plotMap = function (pos) {
    GoogleMapApi.then((maps) => {
      MapService.updateMarkers(vm.divesites, {}, vm.diverId, markerOptions);
      vm.map = {
        center: {
          longitude: (pos && pos.coords.longitude) || organization.longitude,
          latitude: (pos && pos.coords.latitude) || organization.latitude,
        },
        zoom: organization.zoomLevel,
      };

      vm.markersEvents = {
        click(gMarker, eventName, model) {
          vm.fields.site = model._id;
          vm.fields.depth = model.depth;
          vm.chosen = [model];
          MapService.updateMarkers(vm.divesites, model, vm.diverId, markerOptions);
          $scope.$apply();
        },
      };
      vm.marker = {
        id: 0,
        coords: {
          longitude: pos && pos.coords.longitude || organization.longitude,
          latitude: pos && pos.coords.latitude || organization.latitude,
        },
        title: translate('DIVE_SITE'),
        options: { draggable: true },
        events: {
          dragend(marker, eventName, args) {
            vm.site.longitude = marker.getPosition().lng();
            vm.site.latitude = marker.getPosition().lat();
            // marker.zIndex = 950;
            // marker.optimized = false;
            $scope.$apply();
          },
        },
      };
    });
  };

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((pos) => {
      plotMap(pos);
    }, (err) => {
      console.log(err);
      plotMap();
    });
  } else {
    plotMap();
  }

  vm.fields = {
    pressure_start: 300,
    apparatus: '2x4',
    volume: volumes['2x4'],
    depth: 0,
    mean_depth: 0,
    equipment: {
      lamp: true,
      phone: true,
      computer: true,
      knife: true
    },
    time_start: dayjs().utc().second(0).millisecond(0).toDate(),
    time_end: dayjs().add(15, 'minute').utc().second(0).millisecond(0).toDate(),
    conditions: {
      deep: false,
      dark: true,
      stream: false,
      cold: false,
      under_ice: false,
      wreck: false,
    },
  };
  vm.diveMethod = vm.search[0];
  vm.diveType = vm.types[0];
  vm.updateVolume = () => {
    vm.fields.volume = volumes[vm.fields.apparatus];
  }

  vm.updateChosen = function (add, option, model, evt) {
    vm.fields.site = model[0]._id;
    vm.fields.depth = model[0].depth;
    vm.map = {
      center: {
        longitude: model[0].longitude,
        latitude: model[0].latitude,
      },
      zoom: organization.zoomLevel,
    };
    vm.chosen = [model[0]];
    MapService.updateMarkers(vm.divesites, model[0], vm.diverId, markerOptions);
  }

  vm.createDive = function () {
    const data = vm.fields;
    vm.diveShowAlert = false;
    vm.diveAlertClass = null;
    vm.diveAlertMessage = null;
    data.time_start = dayjs(data.time_start).valueOf();
    data.time_end = dayjs(data.time_end).valueOf();

    data.user_id = vm.diverId;
    data.volume = volumes[data.apparatus];
    data.pressure_end = parseFloat(data.pressure_end, 10);
    data.type = vm.diveType.value;
    data.method = vm.diveMethod.value;

    if (!vm.isDive()) {
      data.pressure_end = 0;
      data.depth = 0;
      data.mean_depth = 0;
      data.assistant = '-';
    }

    DiveService.add(data).then(() => {
      $state.go('index.users.organization.dives.list', { uid: vm.diverId, diveType: 'water' });
    }, (err) => {
      vm.diveShowAlert = true;
      vm.diveAlertClass = 'danger';
      vm.diveAlertMessage = translate('COULD_NOT_ADD_DIVE');
      vm.errors = err.errors;
      console.log(err.errors);
      Object.values(err.errors).forEach((error) => {
        if (error.path) {
          $(`#dive-${error.path}`).addClass('is-invalid');
        } else {
          $(`#dive-${key}`).addClass('is-invalid');
        }
      });
    });
  };

  vm.createSite = function () {
    const data = vm.site;
    vm.diveShowAlert = false;
    vm.diveAlertClass = null;
    vm.diveAlertMessage = null;

    data.longitude = vm.site.longitude;
    data.latitude = vm.site.latitude;
    data.depth = parseFloat(vm.site.depth);

    SiteService.add(data).then((addedSite) => {
      vm.siteShowAlert = true;
      vm.siteAlertClass = 'success';
      vm.siteAlertMessage = translate('ADDED_SUCCESSFULLY');
      SiteService.list(organization._id).then((result) => {
        vm.divesites = result;
        vm.updateChosen(null, null, [addedSite]);
      });
    }, (err) => {
      vm.siteShowAlert = true;
      vm.siteAlertClass = 'danger';
      vm.siteAlertMessage = translate('COULD_NOT_ADD_SITE');
      vm.siteErrors = err.errors;
      Object.entries(err.errors).forEach(([key, error]) => {
        if (error.path) {
          $(`#site-${error.path}`).addClass('is-invalid');
        } else {
          $(`#site-${key}`).addClass('is-invalid');
        }
      });
    });
  };

  vm.isDive = () => {
    return DiveService.isDive(vm.diveType, organization);
  }
}

// eslint-disable-next-line max-len
NewWaterDiveController.$inject = ['$scope', '$state', '$stateParams', '$translate', 'user', 'organization', 'DiveService', 'uiGmapGoogleMapApi', 'sites', 'MapService', 'SiteService'];

// eslint-disable-next-line max-len
export default angular.module('NewWaterDiveController', [DiveService, MapService, SiteService]).controller('NewWaterDiveController', NewWaterDiveController).name;
