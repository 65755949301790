import * as angular from 'angular';
import FileService from '../services/FileService';

const ComputerController = function ($translate, FileService) {

  const vm = this;
  vm.translate = $translate.instant;

  const osMap = {
    'exe': 'Windows',
    'deb': 'Linux',
    'dmg': 'Mac OS'
  }

  FileService.getDownloadLinks().then((urlMap) => {
    vm.links = Object.keys(urlMap).map((fileExtension) => {
      return { name: osMap[fileExtension], url: urlMap[fileExtension] }
    });
  }, (err) => {
    console.log(err)
  });
}
ComputerController.$inject = ['$translate', 'FileService'];

export default angular.module('ComputerController', [FileService]).controller('ComputerController', ComputerController).name;
