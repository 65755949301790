import * as angular from 'angular';

const EditDiveController = function (user) {

  const vm = this;
  vm.user = user;
}

EditDiveController.$inject = ['user'];

export default angular.module('EditDiveController', []).controller('EditDiveController', EditDiveController).name;
