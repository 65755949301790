import * as angular from 'angular';
import MapService from '../services/MapService';
import SiteService from '../services/SiteService';

const NewSiteController = function ($scope, $state, $stateParams, $timeout, $translate, user, organization, SiteService, GoogleMapApi, MapService) {

  const vm = this;
  const translate = $translate.instant;
  vm.user = user;
  vm.mapOptions = MapService.DEFAULT_OPTIONS;
  vm.errors = {};
  vm.layerOptions = MapService.getLayerOptions();

  const { label1 } = MapService.getLabels();

  vm.fields = {};
  vm.longitude = organization.longitude;
  vm.latitude = organization.latitude;
  vm.showNauticalLayer = false;

  vm.toggleNauticalLayer = () => {
    vm.showNauticalLayer = !vm.showNauticalLayer;
  }
  MapService.createMarkerStyle(label1, document);

  vm.updateLocation = function (fieldName) {
    vm.map.center[fieldName] = vm[fieldName];
    vm.marker.coords[fieldName] = vm[fieldName];
  }

  const plotMap = function (pos) {
    GoogleMapApi.then((maps) => {
      vm.map = {
        center: {
          longitude: pos && pos.coords.longitude || organization.longitude,
          latitude: pos && pos.coords.latitude || organization.latitude,
        },
        zoom: organization.zoomLevel,
      };

      vm.marker = {
        id: 0,
        coords: {
          longitude: pos && pos.coords.longitude || organization.longitude,
          latitude: pos && pos.coords.latitude || organization.latitude,
        },
        title: translate('NEW_SITE'),
        options: { draggable: true },
        events: {
          drag(marker, eventName, args) {
            vm.longitude = marker.getPosition().lng();
            vm.latitude = marker.getPosition().lat();
            $scope.$apply();
          },
        },
      };
    });
  };

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((pos) => {
      plotMap(pos);
    }, (err) => {
      console.log(err)
      plotMap();
    });
  } else {
    plotMap();
  }

  vm.createSite = function () {
    const data = vm.fields;
    vm.diveShowAlert = false;
    vm.diveAlertClass = null;
    vm.diveAlertMessage = null;

    data.longitude = vm.longitude;
    data.latitude = vm.latitude;
    data.depth = parseFloat(data.depth);

    SiteService.add(data).then((ret) => {
      vm.diveShowAlert = true;
      vm.diveAlertClass = 'success';
      vm.diveAlertMessage = translate('ADDED_SUCCESSFULLY');
      $timeout(() => {
        $state.transitionTo('index.users.organization.sites.list', $stateParams, {
          reload: true,
          inherit: false,
          notify: true,
        });
      }, 2000);
      // $state.go('index.users.organization.sites.list', {uid: $stateParams.uid});
    }, (err) => {
      vm.diveShowAlert = true;
      vm.diveAlertClass = 'danger';
      vm.diveAlertMessage = translate('COULD_NOT_ADD_SITE');
      vm.errors = err.errors;
      Object.entries(err.errors).forEach(([key, error]) => {
        if (error.path) {
          $(`#${error.path}`).addClass('is-invalid');
        } else {
          $(`#${key}`).addClass('is-invalid');
        }
      });
    });
  };
}

// eslint-disable-next-line max-len
NewSiteController.$inject = ['$scope', '$state', '$stateParams', '$timeout', '$translate', 'user', 'organization', 'SiteService', 'uiGmapGoogleMapApi', 'MapService'];

export default angular.module('NewSiteController', [MapService, SiteService]).controller('NewSiteController', NewSiteController).name;
