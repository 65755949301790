import * as angular from 'angular';
import options from './options';

const ItemService = function ($q, $http, $stateParams, options) {

  const itemService = {};

  itemService.get = function (itemId) {
    const d = $q.defer();
    $http({
      url: `${options.api.base_url}/organizations/${$stateParams.organizationId
      }/inventories/${$stateParams.inventoryId}/items/${$stateParams.itemId}`,
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((results) => {
        console.log(results);
        d.resolve(results.data);
      }, (err) => {
        console.log(err);
        d.reject(err.data);
      });

    return d.promise;
  };

  itemService.update = function (data) {
    const d = $q.defer();
    $http({
      url: `${options.api.base_url}/organizations/${$stateParams.organizationId
      }/inventories/${$stateParams.inventoryId}/items/${$stateParams.itemId}`,
      method: 'PUT',
      data: JSON.stringify({ item: data }),
      headers: { 'Content-Type': 'application/json' },
    })
      .then((results) => {
        console.log(results);
        d.resolve(results.data);
      }, (err) => {
        console.log(err);
        d.reject(err.data);
      });

    return d.promise;
  };

  itemService.list = function (organizationId, inventoryId) {
    const d = $q.defer();
    $http({
      url: `${options.api.base_url}/organizations/${organizationId
      }/inventories/${inventoryId}/items`,
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => {
        d.resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        d.reject(err.data);
      });

    return d.promise;
  };

  itemService.add = function (data) {
    const d = $q.defer();
    $http({
      url: `${options.api.base_url}/organizations/${$stateParams.organizationId
      }/inventories/${$stateParams.inventoryId}/items/`,
      method: 'POST',
      data: JSON.stringify({ item: data }),
      headers: { 'Content-Type': 'application/json' },
    })
      .then((results) => {
        console.log(results);
        d.resolve(results.data);
      }, (err) => {
        console.log(err);
        d.reject(err.data);
      });

    return d.promise;
  };

  itemService.delete = function (itemId) {
    const d = $q.defer();
    $http({
      url: `${options.api.base_url}/organizations/${$stateParams.organizationId
      }/inventories/${$stateParams.inventoryId}/items/${itemId}`,
      method: 'DELETE',
    })
      .then((results) => {
        d.resolve(results.data);
      }, (err) => {
        d.reject(err.data);
      });

    return d.promise;
  };

  return itemService;
}
ItemService.$inject = ['$q', '$http', '$stateParams', 'options'];

export default angular.module('ItemService', [options]).factory('ItemService', ItemService).name;
