import 'lodash';
import 'bootstrap';
import './assets/scss/app.scss';
const Highcharts = require('highcharts/highstock');
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/pattern-fill')(Highcharts);
import 'angular-ui-router';
import 'ui-bootstrap4';
import 'bs4-selectbox';
import 'angular-translate';
import 'angular-translate-loader-static-files';
import 'angular-sanitize';
import 'angular-simple-logger';
import 'angular-google-maps';
import * as angular from 'angular';

import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import ApiKeyInterceptor from './services/ApiKeyInterceptor';
import ComputerController from './controllers/ComputerController';
import ContactController from './controllers/ContactController';
import DiverController from './controllers/DiverController';
import DivesController from './controllers/DivesController';
import DivesListController from './controllers/DivesListController';
import DivesMapController from './controllers/DivesMapController';
import DocumentController from './controllers/DocumentController';
import DocumentsController from './controllers/DocumentsController';
import EditDiveController from './controllers/EditDiveController';
import EditExposureController from './controllers/EditExposureController';
import EditItemController from './controllers/EditItemController';
import EditRescueDiveController from './controllers/EditRescueDiveController';
import EditSiteController from './controllers/EditSiteController';
import EditWaterDiveController from './controllers/EditWaterDiveController';
import ExposureService from './services/ExposureService';
import ExposuresController from './controllers/ExposuresController';
import FeedbackController from './controllers/FeedbackController';
import GuideController from './controllers/GuideController';
import InventoriesController from './controllers/InventoriesController';
import InventoryService from './services/InventoryService';
import ItemsController from './controllers/ItemsController';
import LinksController from './controllers/LinksController';
import LoginController from './controllers/LoginController';
import LogoutController from './controllers/LogoutController';
import MaritimeController from './controllers/MaritimeController';
import NewDiveController from './controllers/NewDiveController';
import NewExposureController from './controllers/NewExposureController';
import NewItemController from './controllers/NewItemController';
import NewRescueDiveController from './controllers/NewRescueDiveController';
import NewSiteController from './controllers/NewSiteController';
import NewWaterDiveController from './controllers/NewWaterDiveController';
import OrganizationController from './controllers/OrganizationController';
import PlanController from './controllers/PlanController';
// import PlanService from './services/PlanService';
import ReportsController from './controllers/ReportsController';
import ReportsOrganizationController from './controllers/ReportsOrganizationController';
import ReportsUserController from './controllers/ReportsUserController';
import RescueDiveService from './services/RescueDiveService';
import ResetPasswordController from './controllers/ResetPasswordController';
import ResetPasswordUsernameController from './controllers/ResetPasswordUsernameController';
import RootController from './controllers/RootController';
import SettingsController from './controllers/SettingsController';
import SettingsInfoController from './controllers/SettingsInfoController';
import SettingsPasswordController from './controllers/SettingsPasswordController';
import SettingsTestsController from './controllers/SettingsTestsController';
import SignupConfirmationController from './controllers/SignupConfirmationController';
import SignupController from './controllers/SignupController';
import SiteService from './services/SiteService';
import SitesController from './controllers/SitesController';
import SitesListController from './controllers/SitesListController';
import SitesMapController from './controllers/SitesMapController';
import SkipperController from './controllers/SkipperController';
import StatisticsAllDivesController from './controllers/StatisticsAllDivesController';
import StatisticsAllExposuresController from './controllers/StatisticsAllExposuresController';
import StatisticsController from './controllers/StatisticsController';
import StatisticsUserController from './controllers/StatisticsUserController';
import TokenInterceptor from './services/TokenInterceptor';
import UserController from './controllers/UserController';
import UserService from './services/UserService';

import computer from './views/computer.html';
import confirmClick from './directives/confirmClick';
import confirmInput from './directives/confirmInput';
import contacts from './views/contacts.html';
import dayjs from 'dayjs';
import divers from './views/divers.html';
import dives from './views/dives.html';
import divesEdit from './views/dive.edit.html';
import divesEditRescue from './views/dive.edit.rescue.html';
import divesEditWater from './views/dive.edit.water.html';
import divesList from './views/dives.list.html';
import divesMap from './views/dives.map.html';
import divesNew from './views/dive.new.html';
import divesNewRescue from './views/dive.new.rescue.html';
import divesNewWater from './views/dive.new.water.html';
import document from './views/document.html';
import documents from './views/documents.html';
import exposureFilter from './filters/exposureFilter';
import exposures from './views/exposures.html';
import exposuresEdit from './views/exposure.edit.html';
import exposuresNew from './views/exposure.new.html';
import favicon from '../favicon.ico';
import feedback from './views/feedback.html';
import filterRescueDive from './filters/filterRescueDive';
import fontAwesomeCss from '../node_modules/@fortawesome/fontawesome-free/css/all.min.css';
import guide from './views/guide.html';
import items from './views/items.html';
import itemsEdit from './views/item.edit.html';
import itemsNew from './views/item.new.html';
import links from './views/links.html';
import localeEn from '../locales/en/main.json';
// import localeEs from '../locales/es/main.json';
import localeFi from '../locales/fi/main.json';
import localeSv from '../locales/sv/main.json';
import login from './views/login.html';
import mainCss from './assets/css/main.css';
import maritime from './views/maritime.html';
import objectByKeyVal from './filters/objectByKeyVal';
import orderObjectBy from './filters/orderObjectBy';
import organizationTemplate from './views/organization.html';
import passwordReset from './views/password.reset.html';
import passwordUsername from './views/password.username.html';
import plans from './views/plans.html';
import reports from './views/reports.html';
import reportsOrganization from './views/reports.organization.html';
import reportsUser from './views/reports.user.html';
import rescue from './views/rescue.html';
import reverse from './filters/reverse';
import settings from './views/settings.html';
import settingsInfo from './views/settings.info.html';
import settingsPassword from './views/settings.password.html';
import settingsTests from './views/settings.tests.html';
import signup from './views/signup.html';
import signupConfirmation from './views/signup.confirmation.html';
import signupWait from './views/signup.wait.html';
import sites from './views/sites.html';
import sitesEdit from './views/site.edit.html';
import sitesList from './views/sites.list.html';
import sitesMap from './views/sites.map.html';
import sitesNew from './views/site.new.html';
import skippers from './views/skippers.html';
import statistics from './views/statistics.html';
import statisticsAllDives from './views/statistics.alldives.html';
import statisticsAllExposures from './views/statistics.allexposures.html';
import statisticsUser from './views/statistics.user.html';
import sumValues from './filters/sumValues';
import uploadFile from './directives/uploadFile';

require.context('../images', false, /\.(png|jpe?g|svg)$/);

dayjs.extend(AdvancedFormat);

export default angular.module('diveLogger', [
  'ui.router',
  'ui.bootstrap',
  'pascalprecht.translate',
  'bs4-selectbox',
  'uiGmapgoogle-maps',
  'ngSanitize',
  ApiKeyInterceptor,
  ComputerController,
  confirmClick,
  confirmInput,
  ContactController,
  DiverController,
  DivesController,
  DivesListController,
  DivesMapController,
  DocumentController,
  DocumentsController,
  EditDiveController,
  EditExposureController,
  EditRescueDiveController,
  EditWaterDiveController,
  EditItemController,
  EditSiteController,
  ExposuresController,
  ExposureService,
  FeedbackController,
  filterRescueDive,
  GuideController,
  InventoryService,
  InventoriesController,
  ItemsController,
  LinksController,
  LoginController,
  LogoutController,
  MaritimeController,
  NewDiveController,
  NewExposureController,
  NewWaterDiveController,
  NewItemController,
  NewSiteController,
  NewRescueDiveController,
  OrganizationController,
  PlanController,
  ReportsController,
  ReportsUserController,
  ReportsOrganizationController,
  RescueDiveService,
  ResetPasswordController,
  ResetPasswordUsernameController,
  RootController,
  SettingsController,
  SettingsInfoController,
  SettingsPasswordController,
  SettingsTestsController,
  SignupConfirmationController,
  SignupController,
  SitesController,
  SitesListController,
  SitesMapController,
  SiteService,
  SkipperController,
  StatisticsAllDivesController,
  StatisticsAllExposuresController,
  StatisticsController,
  StatisticsUserController,
  TokenInterceptor,
  UserController,
  UserService,
  orderObjectBy,
  objectByKeyVal,
  exposureFilter,
  sumValues,
  reverse,
  uploadFile
])
  .config(['$stateProvider', '$urlRouterProvider', function ($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.otherwise('/login');
    $stateProvider
      .state('index', {
        abstract: true,
        url: '',
        css: mainCss,
        template: "<div class='col-md-12 col-sm-12 col-xs-12 no-padding' ui-view></div>",
        controller: 'RootController',
        controllerAs: 'root',
      })
      .state('index.users', {
        url: '/users/:uid',
        template: "<div class='col-md-12 col-sm-12 col-xs-12 no-padding' ui-view></div>",
        access: { requiredAuthentication: true },
        controller: 'UserController',
        controllerAs: 'user'
      })
      .state('index.users.organization', {
        url: '/organizations/:organizationId',
        abstract: true,
        template: organizationTemplate,
        resolve: {
          userContext($stateParams, UserService) {
            // Logged-in user's data
            return UserService.getContext($stateParams.uid, $stateParams.organizationId).then((res) => {
              res.user.role = res.role;
              return res;
            }, (err) => {
              console.log('Error fetching logged in user\'s data', err);
            });
          },
          currentUser(userContext) {
            return userContext.user;
          },
          organization(userContext) {
            return userContext.organization;
          },
          user($stateParams, userContext, UserService) {
            // The currently viewed user's data
            if ($stateParams.uid === userContext.user.id) {
              return userContext.user;
            } else {
              return UserService.getTargetUser($stateParams.uid, $stateParams.organizationId)
                .then((res) => {
                  return res;
                }, (err) => {
                  console.log('Error fetching user data', err);
                });
            }
          },
          diversMap($stateParams, UserService) {
            return UserService.listMap($stateParams.organizationId).then(({ data }) => data, (err) => {
              console.log(err);
            });
          },
          inventories($stateParams, InventoryService) {
            return InventoryService.list($stateParams.organizationId)
              .then((inventoryList) => inventoryList, (err) => {
                console.log('Error fetching inventories', err);
              });
          },
          sites($stateParams, SiteService) {
            return SiteService.list($stateParams.organizationId).then((data) => data, (err) => {
              console.log(err);
              return null;
            });
          },
          sitesMap(sites) {
            const tmpSites = {};
            const len = sites.length;
            for (let i = 0; i < len; i++) {
              tmpSites[sites[i]._id] = sites[i];
            }
            return tmpSites;
          },
        },
        controller: 'OrganizationController',
        controllerAs: 'organization',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.exposures', {
        url: '/exposures',
        template: exposures,
        controller: 'ExposuresController',
        controllerAs: 'exposures',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.editexposure', {
        url: '/exposures/:eid',
        template: exposuresEdit,
        controller: 'EditExposureController',
        controllerAs: 'editexposure',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.dives', {
        url: '/dives',
        abstract: true,
        template: dives,
        controller: 'DivesController',
        controllerAs: 'dives',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.dives.list', {
        url: '/list',
        params: {
          diveType: null
        },
        template: divesList,
        controller: 'DivesListController',
        controllerAs: 'divesList',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.dives.map', {
        url: '/map',
        template: divesMap,
        controller: 'DivesMapController',
        controllerAs: 'divesMap',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.new', {
        url: '/dives/new',
        abstract: true,
        template: divesNew,
        controller: 'NewDiveController',
        controllerAs: 'newdive',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.new.waterdive', {
        url: '/water',
        template: divesNewWater,
        controller: 'NewWaterDiveController',
        controllerAs: 'newwaterdive',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.new.rescuedive', {
        url: '/rescue',
        template: divesNewRescue,
        controller: 'NewRescueDiveController',
        controllerAs: 'newrescuedive',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.new.exposure', {
        url: '/exposure',
        template: exposuresNew,
        controller: 'NewExposureController',
        controllerAs: 'newexposure',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.new.site', {
        url: '/site',
        template: sitesNew,
        controller: 'NewSiteController',
        controllerAs: 'newSite',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.computer', {
        url: '/computer',
        template: computer,
        controller: 'ComputerController',
        controllerAs: 'computer',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.editdive', {
        url: '/dives/:did/edit',
        abstract: true,
        template: divesEdit,
        controller: 'EditDiveController',
        controllerAs: 'editdive',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.editdive.water', {
        url: '/water',
        template: divesEditWater,
        controller: 'EditWaterDiveController',
        controllerAs: 'editwaterdive',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.editdive.rescue', {
        url: '/rescue',
        template: divesEditRescue,
        controller: 'EditRescueDiveController',
        controllerAs: 'editrescuedive',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.settings', {
        url: '/settings',
        template: settings,
        controller: 'SettingsController',
        controllerAs: 'settings',
        abstract: true,
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.settings.info', {
        url: '/info',
        template: settingsInfo,
        controller: 'SettingsInfoController',
        controllerAs: 'settingsInfo',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.settings.password', {
        url: '/password',
        template: settingsPassword,
        controller: 'SettingsPasswordController',
        controllerAs: 'settingsPassword',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.settings.tests', {
        url: '/tests',
        template: settingsTests,
        controller: 'SettingsTestsController',
        controllerAs: 'settingsTests',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.documents', {
        url: '/documents',
        template: documents,
        controller: 'DocumentsController',
        controllerAs: 'documents',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.document', {
        url: '/documents/:docId',
        template: document,
        controller: 'DocumentController',
        controllerAs: 'document',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.contacts', {
        url: '/contacts',
        template: contacts,
        controller: 'ContactController',
        controllerAs: 'contacts',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.plans', {
        url: '/plans',
        template: plans,
        controller: 'PlanController',
        controllerAs: 'plan',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.links', {
        url: '/links',
        template: links,
        controller: 'LinksController',
        controllerAs: 'links',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.skippers', {
        url: '/skippers',
        template: skippers,
        controller: 'SkipperController',
        controllerAs: 'skippers',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.divers', {
        url: '/divers',
        template: divers,
        controller: 'DiverController',
        controllerAs: 'divers',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.sites', {
        url: '/sites',
        abstract: true,
        template: sites,
        controller: 'SitesController',
        controllerAs: 'sites',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.sites.map', {
        url: '/map',
        template: sitesMap,
        controller: 'SitesMapController',
        controllerAs: 'sitesMap',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.sites.list', {
        url: '/list',
        template: sitesList,
        controller: 'SitesListController',
        controllerAs: 'sitesList',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.statistics', {
        url: '/statistics',
        abstract: true,
        template: statistics,
        controller: 'StatisticsController',
        controllerAs: 'statistics',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.statistics.user', {
        url: '/user',
        template: statisticsUser,
        controller: 'StatisticsUserController',
        controllerAs: 'stUser',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.statistics.alldives', {
        url: '/alldives',
        template: statisticsAllDives,
        controller: 'StatisticsAllDivesController',
        controllerAs: 'stAllDives',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.statistics.allexposures', {
        url: '/allexposures',
        template: statisticsAllExposures,
        controller: 'StatisticsAllExposuresController',
        controllerAs: 'stAllExposures',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.reports', {
        url: '/reports',
        abstract: true,
        template: reports,
        controller: 'ReportsController',
        controllerAs: 'reports',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.reports.user', {
        url: '/user/:diveType',
        params: {
          diveType: {
            value: 'water',
            squash: false
          }
        },
        template: reportsUser,
        controller: 'ReportsUserController',
        controllerAs: 'reportsUser',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.reports.organization', {
        url: '/organization/:diveType',
        params: {
          diveType: {
            value: 'water',
            squash: false
          }
        },
        template: reportsOrganization,
        controller: 'ReportsOrganizationController',
        controllerAs: 'reportsOrganization',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.editsite', {
        url: '/sites/:siteid/edit',
        template: sitesEdit,
        controller: 'EditSiteController',
        controllerAs: 'editSite',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.maritime', {
        url: '/maritime',
        template: maritime,
        controller: 'MaritimeController',
        controllerAs: 'maritime',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.feedback', {
        url: '/feedback',
        template: feedback,
        controller: 'FeedbackController',
        controllerAs: 'feedback',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.guide', {
        url: '/guide',
        template: guide,
        controller: 'GuideController',
        controllerAs: 'guide',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.items', {
        url: '/inventories/:inventoryId/items',
        abstract: true,
        template: "<div class='col-md-12 no-padding' ui-view>",
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.items.list', {
        url: '/list',
        template: items,
        controller: 'ItemsController',
        controllerAs: 'items',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.items.new', {
        url: '/new',
        template: itemsNew,
        controller: 'NewItemController',
        controllerAs: 'newItem',
        access: { requiredAuthentication: true },
      })
      .state('index.users.organization.items.edit', {
        url: '/:itemId',
        template: itemsEdit,
        controller: 'EditItemController',
        controllerAs: 'editItem',
        access: { requiredAuthentication: true },
      })
      .state('index.resetpassword', {
        url: '/password/:token',
        template: passwordReset,
        controller: 'ResetPasswordController',
        controllerAs: 'resetPassword',
        access: { requiredAuthentication: false },
      })
      .state('index.resetpasswordusername', {
        url: '/password',
        template: passwordUsername,
        controller: 'ResetPasswordUsernameController',
        controllerAs: 'resetPasswordUsername',
        access: { requiredAuthentication: false },
      })
      .state('index.signup', {
        url: '/signup',
        template: signup,
        controller: 'SignupController',
        controllerAs: 'signup',
      })
      .state('index.signupconfirmation', {
        url: '/confirmation/:token',
        template: signupConfirmation,
        controller: 'SignupConfirmationController',
        controllerAs: 'signupConfirmation',
        access: { requiredAuthentication: false },
      })
      .state('index.waitconfirmation', {
        url: '/wait',
        template: signupWait,
        access: { requiredAuthentication: false },
      })
      .state('index.login', {
        url: '/login',
        template: login,
        controller: 'LoginController',
        controllerAs: 'login',
        access: { requiredAuthentication: false },
      })
      .state('index.logout', {
        url: '/logout',
        template: '',
        access: {
          requiredAuthentication: true,
        },
        controller: 'LogoutController',
        controllerAs: 'logout',
      });
  }])
  .config(['uiGmapGoogleMapApiProvider', function (GoogleMapApi) {
    GoogleMapApi.configure({
      key: 'AIzaSyAeON6wCEqYhGsQwJlRxMYYprkA5DrmuZM',
      v: '3', // defaults to latest 3.X anyhow
      libraries: 'geometry,visualization,places',
    })
  }])
  .config(($httpProvider) => {
    $httpProvider.interceptors.push('TokenInterceptor')
    $httpProvider.interceptors.push('ApiKeyInterceptor')
  })
  .config(($translateProvider) => {
    $translateProvider
      .useStaticFilesLoader({
        prefix: '/locales/',
        suffix: '/main.json'
      }) 
    // remove the warning from console log by putting the sanitize strategy
      .useSanitizeValueStrategy('sanitizeParameters')    
      .preferredLanguage('fi');
  })
