import * as angular from 'angular';
import UserService from '../services/UserService';

const ResetPasswordController = function ($state, $timeout, $translate, $stateParams, UserService) {
  const vm = this;
  vm.showAlert = false;
  vm.fields = {};
  vm.errors = {};
  const translate = $translate.instant;

  vm.resetPassword = function () {
    vm.passwordShowAlert = false;

    const newPassword = vm.fields.new_confirm;

    if (vm.fields.new_password && vm.fields.new_password === vm.fields.new_confirm) {
      UserService.resetPassword($stateParams.token, newPassword)
        .then((ret) => {
          vm.passwordShowAlert = true;
          vm.passwordAlertClass = 'success';
          vm.passwordAlertMessage = translate('PASSWORD_RESET_SUCCESSFUL');
          $timeout(() => {
            $state.go('index.login');
          }, 4000);
        }, (err) => {
          vm.passwordShowAlert = true;
          vm.passwordAlertClass = 'danger';
          vm.passwordAlertMessage = translate('PASSWORD_RESET_FAILED');
          vm.errors = err.errors;
          Object.entries(err.errors).forEach(([key, error]) => {
            if (error.path) {
              $(`#password-${error.path}`).addClass('is-invalid');
            } else {
              $(`#password-${key}`).addClass('is-invalid');
            }
          });
        })
        .catch(angular.noop); // fixes https://github.com/angular-ui/ui-grid/issues/5890 ??
    } else {
      vm.passwordShowAlert = true;
      vm.passwordAlertClass = 'danger';
      vm.passwordAlertMessage = translate('PASSWORDS_DO_NOT_MATCH');
    }
  };
}
ResetPasswordController.$inject = ['$state', '$timeout', '$translate', '$stateParams', 'UserService'];

export default angular.module('ResetPasswordController', [UserService]).controller('ResetPasswordController', ResetPasswordController).name;
