import 'regenerator-runtime/runtime'
import * as angular from 'angular';
import options from './options';

const MapService = function ($http, $stateParams, options) {
  const mapService = {};

  mapService.DEFAULT_OPTIONS = {
    mapTypeId: 'hybrid',
    disableDoubleClickZoom: true,
    backgroundColor: '#29292E',
    streetViewControl: false,
  };

  mapService.getLabels = function () {
    return {
      label1: {
        name: 'labelMarker1',
        fontSize: 12,
        size: 20,
        color: '#0000F0',
        fillOpacity: 0.4,
      },
      label2: {
        name: 'labelMarker2',
        fontSize: 18,
        size: 23,
        color: '#5CB85C',
      },
      label3: {
        name: 'labelMarker3',
        fontSize: 12,
        size: 20,
        color: 'grey',
        fillOpacity: 0.4,
      },
      label4: {
        name: 'labelMarker4',
        fontSize: 12,
        size: 20,
        color: 'red',
        fillOpacity: 0.4,
      },
      label5: {
        name: 'labelMarker5',
        fontSize: 12,
        size: 20,
        color: '#8b008b',
        fillOpacity: 0.4,
      }
    }
  }

  const EXTENT = [-Math.PI * 6378137, Math.PI * 6378137];

  function xyzToBounds(x, y, z) {
    const tileSize = EXTENT[1] * 2 / Math.pow(2, z);
    const minx = EXTENT[0] + x * tileSize;
    const maxx = EXTENT[0] + (x + 1) * tileSize;
    // remember y origin starts at top
    const miny = EXTENT[1] - (y + 1) * tileSize;
    const maxy = EXTENT[1] - y * tileSize;
    return [minx, miny, maxx, maxy];
  }

  mapService.getCapabilities = async () => {
    // eslint-disable-next-line max-len
    const res = await $http.get(`${options.api.base_url}/users/${$stateParams.uid}/organizations/${$stateParams.organizationId}/dives/getWmsCapabilities`)
      .catch((err) => {
        console.log(err);
      });
    return res;
  }
    
  mapService.createMarkerStyle = function (settings, documentElement) {
    // eslint-disable-next-line angular/document-service
    const style = document.createElement('style');
    style.innerHTML = `.${settings.name} { font-size:${settings.fontSize}px; 
        font-weight:300; color:#FFFFFF;font-family:"Arial"; border-radius:80%; 
        background-color:${settings.color};width:${settings.size}px;text-align: center;
        vertical-align: middle;line-height: ${settings.size}px; }`;
    documentElement.getElementsByTagName('head')[0].appendChild(style);
  };

  const getPngMarker = function (icon) {
    const iconBase = "https://developers.google.com/maps/documentation/javascript/examples/full/images/";
    const icons = {
      parking: {
        icon: iconBase + "parking_lot_maps.png",
      },
      library: {
        icon: iconBase + "library_maps.png",
      },
      info: {
        icon: iconBase + "info-i_maps.png",
      }
    }
    return icons[icon].icon;
  }

  const getSvgMarker = function(color) {
    return {
      path:
      // ok-mark, to be added :
      // 'M10.453 14.016 l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406z'
      // eslint-disable-next-line max-len
      'M12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z',
      fillColor: color,
      fillOpacity: 0.7,
      strokeWeight: 0,
      rotation: 0,
      scale: 1.7,
      anchor: new google.maps.Point(15, 30),
    }
  };

  const getTileUrl = function(coordinates, zoom) {
    return (
      // karttakuvat:
      // "https://julkinen.traficom.fi/rasteripalvelu/wmts?service=WMTS" +
      "https://julkinen.traficom.fi/s57/wms?service=WMS" +
        "&request=GetMap" +
        "&layers=cells" +
        "&format=image%2Fpng" +
        "&srs=EPSG:3857&width=256&height=256" +
        "&BBOX=" +
        xyzToBounds(coordinates.x, coordinates.y, zoom).join(",")
    );
  };

  mapService.getLayerOptions = function () {
    return {
      getTileUrl,
      name: "Merimerkit",
      alt: "Merimerkit ja loistot",
      minZoom: 0,
      maxZoom: 19,
      opacity: 1.0
    }
  }

  mapService.updateMarkers = function (sitesArr, chosenSite, userId, markerOptions) {
    if (!sitesArr || !chosenSite) {
      return;
    }
    const { userDiveMarker, diveMarker, currentDiveMarker, alarmDiveMarker } = markerOptions;
    angular.forEach(sitesArr, (value, key) => {
      value.options = {
        labelAnchor: value._id === chosenSite._id ? currentDiveMarker.pos : userDiveMarker.pos,
        labelClass: value._id === chosenSite._id ? currentDiveMarker.name : userDiveMarker.name,
        labelContent: value.depth || '?',
        title: value.name,
        icon: getSvgMarker('blue'),
        zIndex: value._id === chosenSite._id ? 950 : 600,
        optimized: false
      };
    });
  };

  mapService.updateDiveMarkers = function (divesArr, chosenDive, sitesMap, userId, markerOptions ) {
    if (!divesArr || !chosenDive) {
      return;
    }
    console.log('updating dive markers')
    const { userDiveMarker, diveMarker, currentDiveMarker, alarmDiveMarker, visitedAlarmDiveMarker } = markerOptions;
    const sites = {};
    divesArr.map((value) => {
      if (!value.site || !sitesMap[value.site]) return null;

      let site = sites[value.site];
      if (!site) {
        site = {
          _id: value.site,
          name: sitesMap[value.site].name,
          description: sitesMap[value.site].description,
          depth: sitesMap[value.site].depth,
          longitude: sitesMap[value.site].longitude,
          latitude: sitesMap[value.site].latitude,
          count: 1,
          dives: [value],
          isChosen: false,
          hasUserDives: false,
          hasAlarmDives: false,
          hasDives: false
        };
        sites[value.site] = site;
      } else {
        sites[value.site].count++;
        sites[value.site].dives.push(value);
      }

      let labelAnchor, labelClass, labelContent, markerColor = 'blue';
      if (value.site === chosenDive.site) {
        site.isChosen = true;
      }
      if (value.user_id === userId) {
        site.hasUserDives = true;
      } else {
      }
      if (value.type === 'ALARM') {
        site.hasAlarmDives = true;
      } else {
        site.hasDives = true;
      }
      
      labelContent = sites[value.site].count;
      if (site.isChosen && site.hasAlarmDives) {
        labelAnchor = visitedAlarmDiveMarker.pos;
        labelClass = visitedAlarmDiveMarker.name;
        markerColor = 'darksalmon';
      } else if (site.isChosen) {
        labelAnchor = currentDiveMarker.pos;
        labelClass = currentDiveMarker.name;
        markerColor = 'green';
      } else if (site.hasAlarmDives && site.hasDives) {
        labelAnchor = visitedAlarmDiveMarker.pos;
        labelClass = visitedAlarmDiveMarker.name;
        markerColor = '#8b008b';
      } else if (site.hasAlarmDives) {
        labelAnchor = alarmDiveMarker.pos;
        labelClass = alarmDiveMarker.name;
        markerColor = 'red';
      } else if (site.hasUserDives) {
        labelAnchor = userDiveMarker.pos;
        labelClass = userDiveMarker.name;
        markerColor = 'blue';
      } else {
        labelAnchor = diveMarker.pos;
        labelClass = diveMarker.name;
        markerColor = 'grey';
      }
      
      site.options = {
        labelAnchor,
        labelClass,
        labelContent,
        title: value.type + ' ' + dayjs(value.time_start).format('DD.MM.YYYY'),
        icon: getSvgMarker(markerColor),
        optimized: true
      }
      return site;
    }).filter((v) => v !== null);

    return Object.values(sites);
  };

  return mapService;
}
MapService.$inject = ['$http', '$stateParams', 'options'];

export default angular.module('MapService', [options]).factory('MapService', MapService).name;
