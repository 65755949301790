import * as angular from 'angular';

const NewDiveController = function (user) {

  const vm = this;
  vm.user = user;
}

NewDiveController.$inject = ['user'];

export default angular.module('NewDiveController', []).controller('NewDiveController', NewDiveController).name;
