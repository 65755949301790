import * as angular from 'angular';
import OrganizationService from '../services/OrganizationService';
import UserService from '../services/UserService';

const SignupController = function ($state, $translate, UserService, OrganizationService) {

  const vm = this;
  vm.fields = {};
  vm.organizations = {};
  vm.translate = $translate.instant;

  OrganizationService.list().then((res) => {
    vm.organizations = res;
    const defaultOrg = res.find((org) => org.type === 'public');
    vm.fields.organization = defaultOrg;
  }, (err) => {
    vm.registerShowAlert = true;
    vm.registerAlertClass = 'danger';
    vm.registerAlertMessage = 'FAILED_TO_FETCH_ORGANIZATIONS';
    vm.errors = {};
  });

  vm.changeLanguage = function (key) {
    $translate.use(key);
  };

  vm.createUser = function () {
    vm.registerShowAlert = false;
    vm.registerAlertClass = null;
    vm.registerAlertMessage = null;
    vm.errors = {};
    const tmp = JSON.stringify(vm.fields);
    const pass = JSON.parse(tmp);
    pass.email = vm.fields.email;
    pass.organization = vm.fields.organization._id;
    pass.diver = vm.fields.role === 'diver';
    pass.admin = vm.fields.role === 'admin';
    pass.role = vm.fields.role;

    UserService.register(pass)
      .then((data) => {
        $state.go('index.waitconfirmation');
      }, (err) => {
        console.log(err);
        vm.registerShowAlert = true;
        vm.registerAlertClass = 'danger';
        vm.registerAlertMessage = 'REGISTRATION_FAILED';
        vm.errors = err.errors;
        Object.entries(err.errors).forEach(([key, error]) => {
          if (error.path) {
            $(`#${error.path}`).addClass('is-invalid');
          } else {
            $(`#${key}`).addClass('is-invalid');
          }
        });
      });
  }
}
SignupController.$inject = ['$state', '$translate', 'UserService', 'OrganizationService'];
      
export default angular.module('SignupController', [UserService, OrganizationService]).controller('SignupController', SignupController).name;
