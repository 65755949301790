import * as angular from 'angular';

const objectByKeyVal = ($translate) => function (input, filterVal, callback) {

  const filteredInput = {};
  angular.forEach(input, (value, key) => {
    const tmp_keys = ['time_start', 'depth', 'site', 'type'];
    for (let i = 0; i < tmp_keys.length; i++) {
      if (value && value[tmp_keys[i]]) {
        let v = '';
        if (['depth'].includes(tmp_keys[i])) {
          v = `${value[tmp_keys[i]]}`;
        } else if (['site', 'time_start'].includes(tmp_keys[i]) && callback) {
          v = callback(value[tmp_keys[i]]);
        } else if (['type'].includes(tmp_keys[i])) {
          v = $translate.instant(`${value[tmp_keys[i]]}`.toUpperCase());
        }
        if (v && v.name && v.name.toLowerCase().indexOf(filterVal) != -1) {
          filteredInput[key] = value;
        } else if (angular.isString(v) && v.toLowerCase().indexOf(filterVal) != -1) {
          filteredInput[key] = value;
        }
      }
    }
  });
  return filteredInput;
}

objectByKeyVal.$inject = ['$translate'];

export default angular.module('objectByKeyVal', []).filter('objectByKeyVal', objectByKeyVal).name;
