import * as angular from 'angular';

const ApiKeyInterceptor = function ($window) {

  return {
    request(config) {
      config.headers = config.headers || {};
      if ($window.sessionStorage.apiKey) {
        config.headers['X-API-Key'] = $window.sessionStorage.apiKey;
      }
      return config;
    },
  };
}
ApiKeyInterceptor.$inject = ['$window'];

export default angular.module('ApiKeyInterceptor', []).factory('ApiKeyInterceptor', ApiKeyInterceptor).name;
