import * as angular from 'angular';
import options from './options';

const FileService = function ($q, $http, $stateParams, options) {

  const fileService = {};

  fileService.getFileUrl = function (filename) {
    const d = $q.defer();
    $http.get(`${options.api.base_url}/users/${$stateParams.uid}/organizations/${$stateParams.organizationId}/files/${filename}`)
      .then((response) => {
        console.log(response);
        d.resolve(response.data);
      }, (error) => {
        console.log(error);
        d.reject(error);
      });
    return d.promise;
  };

  fileService.getDownloadLinks = function () {
    const d = $q.defer();
    $http.get(`${options.api.base_url}/users/${$stateParams.uid}/organizations/${$stateParams.organizationId}/files/download-connect`)
      .then((response) => {
        d.resolve(response.data);
      }, (error) => {
        console.log(error);
        d.reject(error);
      });
    return d.promise;
  };

  fileService.getFile = function (filename) {
    const d = $q.defer();
    $http.get(`${options.api.base_url}/users/${$stateParams.uid}/organizations/${$stateParams.organizationId}/files/${filename}`)
      .then((response) => {
        d.resolve(response.data);
      }, (error) => {
        console.log(error);
        d.reject(error);
      });
    return d.promise;
  };

  fileService.deleteDocument = function (fileId) {
    const d = $q.defer();
    $http({
      url: `${options.api.base_url}/users/${$stateParams.uid}/organizations/${$stateParams.organizationId}/files/${fileId}`,
      method: 'DELETE',
    })
      .then((response) => {
        console.log(response);
        d.resolve(response.data);
      }, (error) => {
        console.log(error);
        d.reject(error);
      });
    return d.promise;
  };

  fileService.getFiles = function () {
    const d = $q.defer();
    $http.get(`${options.api.base_url}/users/${$stateParams.uid}/organizations/${$stateParams.organizationId}/files`)
      .then((response) => {
        console.log(response);
        d.resolve(response);
      }, (error) => {
        console.log(error);
        d.reject(error);
      });
    return d.promise;
  };

  fileService.uploadFile = function (file, name) {
    const fileFormData = new FormData();
    const d = $q.defer();
    if (!name) {
      console.error('No name provided for file upload. Rejecting promise.');
      d.reject({ errors: { 'DiveloggerError': new Error('FILENAME_VALIDATION_ERROR_NOTE') }});
    }
    fileFormData.append(name, file);
    $http.post(`${options.api.base_url}/users/${$stateParams.uid}/organizations/${$stateParams.organizationId}/files`, fileFormData, {
      transformRequest: angular.identity,
      headers: { 'Content-Type': undefined },
    }).then((response) => {
      d.resolve(response);
    }, (error) => {
      console.log(error);
      d.reject(error);
    });

    return d.promise;
  };
  return fileService;
}
FileService.$inject = ['$q', '$http', '$stateParams', 'options'];

export default angular.module('FileService', [options]).factory('FileService', FileService).name;
