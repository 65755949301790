import * as angular from 'angular';
import ItemService from '../services/ItemService';

const EditItemController = function ($stateParams, $translate, userContext, ItemService) {
  const vm = this;
  const translate = $translate.instant;
  vm.orgId = $stateParams.organizationId;
  vm.invId = $stateParams.inventoryId;
  vm.user = userContext.user;
  vm.errors = {};

  ItemService.get(vm.itemId).then((item) => {
    vm.item = item;
  });

  vm.saveItem = function () {
    vm.showAlert = false;
    vm.alertClass = null;
    vm.alertMessage = null;
    console.log(vm.item);
    ItemService.update(vm.item).then((ret) => {
      vm.showAlert = true;
      vm.alertClass = 'success';
      vm.alertMessage = translate('SAVE_SUCCESS');
    }, (err) => {
      vm.showAlert = true;
      vm.alertClass = 'danger';
      vm.alertMessage = translate('SAVE_FAILED');
      vm.errors = err.errors;
      Object.entries(err.errors).forEach(([key, error]) => {
        if (error.path) {
          $(`#${error.path}`).addClass('is-invalid');
        } else {
          $(`#${key}`).addClass('is-invalid');
        }
      });
    });
  };
}
EditItemController.$inject = ['$stateParams', '$translate', 'userContext', 'ItemService'];

export default angular.module('EditItemController', [ItemService]).controller('EditItemController', EditItemController).name;
