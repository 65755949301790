import * as angular from 'angular';

const exposureFilter = ($translate) => function (input, filterVal, callback) {

  const filteredInput = {};
  angular.forEach(input, (value, key) => {
    const tmp_keys = ['time_start', 'exposure_method', 'exposure_reason', 'site'];
    for (let i = 0; i < tmp_keys.length; i++) {
      if (value && value[tmp_keys[i]]) {
        let v = '';
        if (['exposure_method', 'exposure_reason'].includes(tmp_keys[i])) {
          v = $translate.instant(`${value[tmp_keys[i]]}`.toUpperCase());
        } else if (['site'].includes(tmp_keys[i])) {
          v = `${value[tmp_keys[i]]}`;
        } else if (tmp_keys[i] === 'time_start' && callback) {
          v = callback(value[tmp_keys[i]]);
        }
        if (v && v.name && v.name.toLowerCase().indexOf(filterVal) != -1) {
          filteredInput[key] = value;
        } else if (angular.isString(v) && v.toLowerCase().indexOf(filterVal) != -1) {
          filteredInput[key] = value;
        }
      }
    }
  });
  return filteredInput;
}

exposureFilter.$inject = ['$translate'];

export default angular.module('exposureFilter', []).filter('exposureFilter', exposureFilter).name;
