import * as angular from 'angular';
import ExposureService from '../services/ExposureService';

const ExposuresController = function ($state, $stateParams, $translate, $window, ExposureService, currentUser, user) {

  const vm = this;
  vm.user = user;
  vm.currentUser = currentUser;
  vm.diverId = $stateParams.uid;
  vm.searchExposure = '';
  vm.exposures = [];
  vm.newExposureState = `index.users.organization.new.exposure({uid:${vm.diverId}})`;

  vm.fetchExposures = () => {
    ExposureService.list(vm.diverId).then((data) => {
      vm.exposures = data;
    });
  }

  vm.alert = (msg) => {
    $window.alert(msg);
  }

  vm.fetchExposures();

  vm.exposureTypeFilter = (value) => {
    return value;
  }

  vm.filterSolver = (value) => {
    if (angular.isNumber(value)) {
      return vm.convertTime(value);
    }
    return null;
  };

  vm.convertTime = function (time) {
    return dayjs(time).format('DD.MM.YYYY');
  };

  vm.getTime = function (value) {
    return Math.round(((value.time_end - value.time_start) / 60000) * 100) / 100;
  };

  vm.removeExposure = function (params) {
    ExposureService.delete(params.did).then((ret) => {
      console.log('success', ret);
      $state.go($state.current, {}, { reload: true });
    }, (err) => {
      alert($translate.instant('COULD_NOT_REMOVE') + `${err}`);
    });
  };
}
  
ExposuresController.$inject = ['$state', '$stateParams', '$translate', '$window', 'ExposureService', 'currentUser', 'user', 'organization'];

export default angular.module('ExposuresController', [ExposureService])
  .controller('ExposuresController', ExposuresController).name;
