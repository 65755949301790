import * as angular from 'angular';
import options from './options';

const DiveService = function ($q, $http, $stateParams, $translate, options) {

  const translate = $translate.instant;
  const diveService = {};

  diveService.getDiveTypesMap = (organization) => {
    return organization.diveTypes ? organization.diveTypes.reduce(function(map, obj) {
      map[obj.name] = obj;
      return map;
    }, {}) : {};
  }

  diveService.getDefaultConditions = () => {
    return {
      deep: false,
      dark: false,
      stream: false,
      cold: false,
      under_ice: false,
      wreck: false,
    };
  }

  diveService.isDive = (diveType, organization) => {
    // TODO: check below function for rescue dives
    return !organization.diveTypes.some((t) => t.name === diveType && t.type && t.type !== 'dive');
  }

  diveService.roundDepth = (depth) => {
    return Math.round(depth * 100) / 100;
  }

  diveService.getDeepDives = (dives) => {
    return dives.filter((d) => d.type === 'TRAINING_DEPTH' || d.depth > 39);
  }

  diveService.getProblemDives = (dives) => {
    return dives.filter((d) => d.type === 'EMERGENCY_PROCEDURES');
  }

  diveService.getTestDives = (dives) => {
    return dives.filter((d) => d.type === '200M2_TEST');
  }

  diveService.getOtherTestDives = (dives) => {
    return dives.filter((d) => d.type === 'OTHER_TEST');
  }

  diveService.getTrainingDives = (dives) => {
    return dives.filter((d) => d.type === 'TRAINING');
  }

  diveService.getPoolDives = (dives) => {
    return dives.filter((d) => d.type === 'POOL');
  }

  diveService.getIceDives = (dives) => {
    return dives.filter((d) => d.type === 'ICE'); // TODO: check if icy conditions need to be added here
  }

  diveService.getAlertDives = (dives) => {
    return dives.filter((d) => d.type === 'ALARM');
  }

  diveService.getOtherDives = (dives) => {
    return dives.filter((d) => d.type === 'OTHER_DIVE');
  }

  diveService.getSpecialExerciseDives = (dives) => {
    return dives.filter((d) => d.type === 'OTHER_SPECIAL_EXERCISE');
  }

  diveService.getLast = (dives, type) => {
    return dives.filter((d) => {
      const pastDate = dayjs(d.time_start).unix();
      const thisMonth = dayjs().startOf(type).unix();
      return pastDate > thisMonth;
    })
  }

  diveService.getFormer = (dives, type) => {
    return dives.filter((d) => {
      const pastDate = dayjs(d.time_start).unix();
      const lastDate = dayjs().startOf(type).unix();
      const formerDate = dayjs().startOf(type).subtract(1, type).unix();
      return pastDate > formerDate && pastDate < lastDate;
    })
  }

  diveService.calcDivetime = function (dive) {
    if (!dive) return;
    if (dive.computerData) {
      return getMinutesFromSeconds(dive.computerData.divetime);
    } else {
      return getMinutes(dive.time_end - dive.time_start);
    }
  }

  const getMinutes = function (timeMilliseconds) {
    return Math.round(timeMilliseconds / 1000 / 60 * 100) / 100;
  }

  const getMinutesFromSeconds = function (timeSeconds) {
    return Math.round(timeSeconds / 60 * 100) / 100;
  }

  diveService.getMarkerOptions = function () {
    return {
      userDiveMarker: {
        name: 'labelMarker1',
        pos: '15 45'
      },
      diveMarker: {
        name: 'labelMarker3',
        pos: '15 45'
      },
      currentDiveMarker: {
        name: 'labelMarker2',
        pos: '17 49'
      },
      alarmDiveMarker: {
        name: 'labelMarker4',
        pos: '15 45'
      },
      visitedAlarmDiveMarker: {
        name: 'labelMarker5',
        pos: '15 45'
      }
    }
  }

  diveService.calcConsumption = function (dive) {
    if (!dive) return;
    
    let usedDepth;
    if (dive.mean_depth) { 
      usedDepth = dive.mean_depth;
    } else if (dive.computerData) {
      usedDepth = dive.computerData.avgDepth ?? dive.computerData.maxDepth;
    } else {
      usedDepth = dive.depth;
    }
    const depthFactor = usedDepth / 10 + 1;
    const diveTime = ((dive.time_end - dive.time_start) / 60000);
    const diveVolume = ((dive.pressure_start - dive.pressure_end) * dive.volume);
    return Math.round((diveVolume / diveTime) / depthFactor * 100) / 100 || 0;
  };

  diveService.find = function (name) {
    const d = $q.defer();
    $http({
      url: `${options.api.base_url}/users/${$stateParams.uid}/organizations/${$stateParams.organizationId}/dives`,
      method: 'GET',
    })
      .then((results) => {
        angular.forEach(results, (value, key) => {
          const regex = new RegExp(name, 'i');
          if (value.description.match(regex)) {
            results.data.push(value);
          }
        });
        d.resolve(results.data);
      }, (err) => {
        d.reject(err.data);
      });

    return d.promise;
  };

  diveService.get = function (id) {
    const d = $q.defer();
    $http({
      url: `${options.api.base_url}/users/${$stateParams.uid}/organizations/${$stateParams.organizationId}/dives/${id}`,
      method: 'GET',
    })
      .then((results) => {
        d.resolve(results.data);
      }, (err) => {
        d.reject(err.data);
      });

    return d.promise;
  };

  diveService.add = function (data) {
    const d = $q.defer();
    $http({
      url: `${options.api.base_url}/users/${$stateParams.uid}/organizations/${$stateParams.organizationId}/dives`,
      method: 'POST',
      data: JSON.stringify({ dive: data }),
      headers: { 'Content-Type': 'application/json' },
    })
      .then((results) => {
        console.log(results);
        d.resolve(results.data);
      }, (err) => {
        console.log(err);
        d.reject(err.data);
      });

    return d.promise;
  };

  diveService.delete = function (id) {
    const d = $q.defer();
    $http({
      url: `${options.api.base_url}/users/${$stateParams.uid}/organizations/${$stateParams.organizationId}/dives/${id}`,
      method: 'DELETE',
    })
      .then((results) => {
        d.resolve(results.data);
      }, (err) => {
        d.reject(err.data);
      });

    return d.promise;
  };

  diveService.list = function (userId) {
    const d = $q.defer();
    $http({
      url: `${options.api.base_url}/users/${userId}/organizations/${$stateParams.organizationId}/dives`,
      method: 'GET',
      cache: false,
    })
      .then((results) => {
        d.resolve(results.data);
      }, (err) => {
        d.reject(err.data);
      });

    return d.promise;
  };

  diveService.listAll = function (userId) {
    const d = $q.defer();
    $http({
      url: `${options.api.base_url}/users/${userId}/organizations/${$stateParams.organizationId}/dives/all`,
      method: 'GET',
      cache: false,
    })
      .then((results) => {
        d.resolve(results.data);
      }, (err) => {
        d.reject(err.data);
      });
    return d.promise;
  };

  diveService.update = function (data) {
    const d = $q.defer();
    $http({
      url: `${options.api.base_url}/users/${$stateParams.uid}/organizations/${$stateParams.organizationId}/dives/${$stateParams.did}`,
      method: 'PUT',
      data: JSON.stringify({ dive: data }),
      headers: { 'Content-Type': 'application/json' },
    })
      .then((results) => {
        console.log(results);
        d.resolve(results.data);
      }, (err) => {
        console.log(err);
        d.reject(err.data);
      });

    return d.promise;
  };

  diveService.loadOptionsAjax = function (queryParams) {
    return diveService.find(queryParams.data.q).then(queryParams.success);
  };

  diveService.formatResult = function (res) {
    return res.name;
  };

  diveService.formatSelection = function (res) {
    return res.name;
  };

  diveService.getSearchMethods = function () {
    return ['ARC', 'HALF_CIRCLE', 'COLUMN', 'VERTICAL_COLUMN', 'SIDE_ARC', 'CIRCLE', 'DEPTH_CURVE', 'OTHER'];
  };

  diveService.getDiveTypes = function () {
    return ['TRAINING', '200M2_TEST', 'SEARCH_METHOD', 'OTHER_TEST', 'ALARM', 'EMERGENCY_PROCEDURES', 'TRAINING_DEPTH',
      'HUET', 'OTHER_SPECIAL_EXERCISE', 'BORDER_HELICOPTER', 'ICE', 'POOL', 'OTHER']
  };

  diveService.translateConditions = function (key) {
    const cond = {
      deep: translate('DEEP'),
      dark: translate('DARK'),
      stream: translate('STREAM'),
      under_ice: translate('UNDER_ICE'),
      cold: translate('COLD'),
      wreck: translate('WRECK'),
    };
    return cond[key] || false;
  };

  diveService.getVolumes = function () {
    return {
      '1x6': 6,
      '1x7': 7,
      '1x10': 10,
      '1x12': 12,
      '1x15': 15,
      '2x4': 8,
      '2x6': 12,
      '2x7': 14,
      '2x10': 20,
      '2x12': 24,
      '2x15': 30
    };
  };

  diveService.getApparatus = function () {
    return ['1x3', '2x3', '1x4', '2x4', '1x6', '2x6', '1x7', '2x7', '1x10', '2x10', '1x12', '2x12', '1x15', '2x15'];
  };

  diveService.getConditions = function (dive) {
    return {
      deep: { id: 'deep', name: translate('DEEP'), val: (dive && dive.conditions && dive.conditions.deep) || true },
      dark: { id: 'dark', name: translate('DARK'), val: (dive && dive.conditions && dive.conditions.dark) || true },
      stream: { id: 'stream', name: translate('STREAM'), val: (dive && dive.conditions && dive.conditions.stream) || false },
      under_ice: { id: 'under_ice', name: translate('UNDER_ICE'), val: (dive && dive.conditions && dive.conditions.under_ice) || false },
      cold: { id: 'cold', name: translate('COLD'), val: (dive && dive.conditions && dive.conditions.cold) || false },
      wreck: { id: 'wreck', name: translate('WRECK'), val: (dive && dive.conditions && dive.conditions.wreck) || false },
    };
  };

  diveService.getMinutes = getMinutes;
  diveService.getMinutesFromSeconds = getMinutesFromSeconds;

  return diveService;
}

DiveService.$inject = ['$q', '$http', '$stateParams', '$translate', 'options'];

export default angular.module('DiveService', [options]).factory('DiveService', DiveService).name;
