import * as angular from 'angular';
import DiveService from '../services/DiveService';
import OrganizationService from '../services/OrganizationService';
import SiteService from '../services/SiteService';
import UserService from '../services/UserService';
import dayjs from 'dayjs';
import { jsPDF } from 'jspdf';

class ReportsUserController {
  constructor($state, $stateParams, $translate, $window, UserService, currentUser, user, organization, DiveService, SiteService, 
    OrganizationService, sitesMap) {

    const vm = this;
    vm.user = user;
    vm.currentUser = currentUser;
    vm.diverId = $stateParams.uid;
    vm.date = '';
    vm.exposureTimeLimit = 120;
    vm.diveTimeLimit = 60;
    vm.diveType = $state.params.diveType || 'water';
    const { organizationId } = $stateParams;
    vm.years = [];
    vm.organizationId = organizationId;
    vm.dives = [];
    vm.rescueDives = [];
    vm.translate = $translate.instant;
    vm.sites = sitesMap;
    vm.exposures = [];
    vm.months = ['MONTH_SHORT', ...Array(12).keys().map((v) => (++v) + '')].map((m) => {
      return { name: vm.translate(m), value: m };
    });

    vm.alert = (msg) => {
      $window.alert(msg);
    }

    const getNestedObjectValues = (obj) => {
      let list = {};
      Object.entries(obj).forEach(([key, value]) => {
        if (value && typeof value === 'object') {
          list = { ...list, ...getNestedObjectValues(value)};
        } else {
          list[key] = value;
        }
      });
      return list;
    }

    vm.allDiveParameters = OrganizationService.getAllDiveParameters(vm.sites);
    vm.diveParameters = [vm.allDiveParameters[1], vm.allDiveParameters[2], vm.allDiveParameters[3], vm.allDiveParameters[4], 
      vm.allDiveParameters[5], vm.allDiveParameters[6], vm.allDiveParameters[7],];

    vm.allRescueDiveParameters = OrganizationService.getAllRescueDiveParameters();
    vm.rescueDiveParameters = [vm.allRescueDiveParameters[1], vm.allRescueDiveParameters[2], vm.allRescueDiveParameters[3],];

    vm.allExposureParameters = OrganizationService.getAllExposureParameters();
    vm.exposureParameters = [vm.allExposureParameters[0], vm.allExposureParameters[1], vm.allExposureParameters[3], vm.allExposureParameters[4], 
      vm.allExposureParameters[6], vm.allExposureParameters[8], vm.allExposureParameters[9], vm.allExposureParameters[11], 
      vm.allExposureParameters[13], vm.allExposureParameters[14], vm.allExposureParameters[15],
    ]

    const startDate = dayjs('2000-01-01').startOf('year');
    const endDate = dayjs().endOf('year');
    let yearDate = endDate;

    if (endDate.isBefore(startDate)) {
      throw 'End date must be greater than start date.';
    }

    vm.years.push({ name: vm.translate('YEAR'), value: 'ALL' });
    while (yearDate.isAfter(startDate)) {
      vm.years.push({ name: yearDate.format('YYYY'), value: yearDate.format('YYYY') });
      yearDate = yearDate.subtract(1, 'year').startOf('year');
    }
    vm.year = vm.years[0];
    vm.month = vm.months[0];

    DiveService.list(vm.diverId).then((data) => {
      data.forEach((d) => {
        if (!sitesMap[d.site] && d.site) {
          vm.fetchSite(d.site);
        }
      });
    });

    vm.convertTime = function (time, format) {
      return dayjs(time).format(format || 'DD.MM.YYYY hh:mm');
    };

    vm.roundDepth = DiveService.roundDepth;

    vm.getTime = function (value) {
      return Math.round(((value.time_end - value.time_start) / 60000) * 100) / 100;
    };

    vm.fetchSite = function (id) {
      return SiteService.get(id).then((data) => {
        vm.sites[id] = data;
        return Promise.resolve();
      }, (err) => {
        console.log(err);
        return Promise.reject();
      });
    };

    vm.downloadPDF = function () {
      const doc = new jsPDF({
        format: 'a4',
        orientation: 'landscape',
      });
      doc.setFont('Helvetica');
      // const imgData = `data:image/png;base64,${organization.graphics.logo}`;
      // doc.addImage(imgData, 'png', 10, 10, 40, 23);

      let row = 32;
      let page = 1;
      let len = vm.years.length;
      doc.setFontSize(10);
      const dateFormat = vm.lang === 'en' ? 'MM/DD/YYYY HH:mm' : 'DD/MM/YYYY HH:mm';
      doc.text(`${vm.translate('PRINTED_DATE')}: ${vm.convertTime(dayjs(), dateFormat)}`, 15, 7)
      doc.text(`${organization.name}`, 80, 7);
      doc.setFontSize(25);
      doc.text(`${user.firstname} ${user.lastname}`, 15, 22);
      doc.setFontSize(16);
      doc.setFont('Helvetica', 'bold');
      const dates = `${vm.year.value === 'ALL' ? vm.years[len - 1].value + '-' + vm.years[1].value : vm.year.value}`;
      if (vm.diveType === 'water') {
        doc.text(`${vm.translate('DIVES')} ${dates}`, 15, row);
      } else if (vm.diveType === 'rescue') {
        doc.text(`${vm.translate('RESCUE_DIVES')} ${dates}`, 15, row);
      } else {
        doc.text(`${vm.translate('EXPOSURES')} ${dates}`, 15, row);
      }
      row += 15;
      doc.setFontSize(12);
      doc.text(`${vm.translate('DATE_SHORT')}`, 15, row);
      doc.text(`${vm.translate('SITE')}`, 50, row);
      
      if (vm.diveType === 'water') {
        doc.text(`${vm.translate('DEPTH')} (${vm.translate('UNIT_METRES_SHORT')})`, 120, row);
        doc.text(`${vm.translate('DURATION')} (${vm.translate('UNIT_MINUTES_SHORT')})`, 150, row);
        doc.text(`${vm.translate('PURPOSE')}`, 180, row);
        doc.text(`${vm.translate('SEARCH_METHOD')}`, 230, row);
      } else if (vm.diveType === 'rescue') {
        doc.text(`${vm.translate('PURPOSE')}`, 120, row);
        doc.text(`${vm.translate('DURATION')} (${vm.translate('UNIT_MINUTES_SHORT')})`, 160, row); 
        doc.text(`${vm.translate('DIVE_TYPE_SHORT')}`, 190, row);
        doc.text(`${vm.translate('EXPOSURE')}`, 230, row);
      } else {
        doc.text(`${vm.translate('ROLE_SHORT')}`, 110, row);
        doc.text(`${vm.translate('EXPOSURE_REASON')}`, 150, row);
        doc.text(`${vm.translate('DIVE_TIME_SHORT')}(min)`, 200, row);
        if (vm.translate('EXPOSURE_TIME').length > 18) {
          doc.text(`${vm.translate('EXPOSURE_TIME').substr(0, 18)}`, 240, row);
          row += 10;
          doc.text(`${vm.translate('EXPOSURE_TIME').substr(18, 20)}`, 240, row);
        } else {
          doc.text(`${vm.translate('EXPOSURE_TIME')}`, 240, row);
        }
      }
      row += 10;
      
      doc.setFont('Helvetica', 'normal');
      if (vm.diveType === 'water') {
        vm.dives.forEach((d) => {
          doc.text(`${vm.convertTime(d.time_start)}`, 15, row);
          doc.text(`${vm.sites[d.site]?.name || ''}`, 50, row);
          doc.text(`${vm.roundDepth(d.depth) || ''}`, 120, row);
          doc.text(`${vm.getTime(d)}`, 150, row);
          doc.text(`${vm.translate(d.type) || ''}`, 180, row);
          doc.text(`${vm.translate(d.method) || ''}`, 230, row);
          row += 10;
          if (row > 195) {
            doc.addPage();
            page += 1;
            doc.setPage(page);
            row = 10;
          }
        });
      } else if (vm.diveType === 'rescue') {
        vm.rescueDives.forEach((d) => {
          doc.text(`${vm.convertTime(d.time_start)}`, 15, row);
          doc.text(`${d.address.substr(0, 35) || ''}`, 50, row);
          doc.text(`${vm.translate(d.type) || ''}`, 120, row);
          doc.text(`${vm.getTime(d)}`, 160, row);
          doc.text(`${vm.translate(d.dive_type.toUpperCase()) || '-'}`, 190, row);
          doc.text(`${d.exposureId ? 'X' : '-'}`, 230, row);
          row += 10;
          if (row > 195) {
            doc.addPage();
            page += 1;
            doc.setPage(page);
            row = 10;
          }
        });
      } else if (vm.diveType === 'exposure') {
        let totalExposureTime = 0;
        let totalDiveTime = 0;
        vm.exposures.forEach((d) => {
          doc.text(`${vm.convertTime(d.time_start)}`, 15, row);
          doc.text(`${d.site.substr(0, 20) || ''}`, 50, row);
          doc.text(`${vm.translate(d.role)}`, 110, row);
          doc.text(`${vm.translate(d.exposure_reason.toUpperCase())}`, 150, row);
          doc.text(`${d.dive_time}`, 200, row);
          doc.text(`${d.exposure_time || ''}`, 240, row);
          row += 10;
          if (row > 195) {
            doc.addPage();
            page += 1;
            doc.setPage(page);
            row = 10;
          }
          totalExposureTime += d.exposure_time;
          totalDiveTime += d.dive_time;
        });
        doc.setFont('Helvetica', 'bold');
        doc.text(`${vm.translate('TOTAL_SHORT')}`, 150, row);
        doc.text(`${totalDiveTime}`, 200, row);
        doc.text(`${totalExposureTime}`, 240, row);
      }
      doc.save(`${vm.translate(vm.diveType === 'exposure' ? 'EXPOSURE_REPORT' : 'DIVE_REPORT')}-${vm.year.value === 'ALL' ? 
        vm.translate('ALL') : vm.year.value}${vm.month.value === 'MONTH_SHORT' ? 
        '' : '-' + vm.month.value}.pdf`);
    };
    vm.checkMonth = function () {
      alert("CHECK_MONTH")
    }

    vm.downloadCsv = function () {
      let csv = [];
      if (vm.diveType === 'water') {
        csv.push([vm.translate('DATE'), ...vm.diveParameters.map((p) => vm.translate(p.name))]);
        vm.dives.forEach((d) => {
          csv.push([
            vm.convertTime(d.time_start),
            ...vm.diveParameters.map((p) => p.converted(d, p.value))
            // vm.convertTime(d.time_start), vm.sites[d.site]?.name, vm.getTime(d), vm.roundDepth(d.depth), vm.roundDepth(d.mean_depth) || '',
            // vm.translate(d.type), vm.translate(d.method), d.assistant, d.pressure_start, d.pressure_end, d.volume, d.apparatus, d.description
          ]);
        });
      } else if (vm.diveType === 'rescue') {
        csv.push([vm.translate('DATE'), ...vm.rescueDiveParameters.map((p) => vm.translate(p.name))]);
        vm.rescueDives.forEach((d) => {
          csv.push([
            vm.convertTime(d.time_start),
            ...vm.rescueDiveParameters.map((p) => p.converted(d, p.value))
          ]);
        });
      } else {
        csv.push([vm.translate('DATE'), ...vm.exposureParameters.map((p) => vm.translate(p.name))]);
        let totals = [];
        vm.exposures.forEach((d) => {
          vm.exposureParameters.forEach((p, i) => {
            if (typeof  p.converted(d, p.value) === 'number') {
              if (!totals[i]) {
                totals[i] = 0;
              }
              totals[i] += p.converted(d, p.value);
            } else {
              if (!totals[i]) {
                totals[i] = '';
              }
            }
          });
          csv.push([
            vm.convertTime(d.time_start),
            ...vm.exposureParameters.map((p) => p.converted(d, p.value))
          ]);
        });
        csv.push([vm.translate('TOTAL_SHORT'), ...totals]);
      }
      const csvContent = `data:text/csv;charset=utf-8,${csv.map(e => e.join(';')).join('\n')}`;
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', `${vm.translate(vm.diveType === 'exposure' ? 'EXPOSURE_REPORT' : 'DIVE_REPORT')}-${vm.year.value === 'ALL' ? 
        vm.translate('ALL') : vm.year.value}${vm.month.value === 'MONTH_SHORT' ? 
        '' : '-' + vm.month.value}.csv`);
      document.body.appendChild(link);
      link.click();
    }

    vm.getReport = () => {
      if (vm.year.value === 'ALL') {
        vm.month = vm.months[0];
      }
      if (vm.month.value === 'MONTH_SHORT') {
        vm.from = vm.year.value !== 'ALL' ? dayjs(vm.year.value, 'YYYY').startOf('year') : dayjs('1980-01-01').startOf('year');
        vm.to = vm.year.value !== 'ALL' ? dayjs(vm.year.value, 'YYYY').endOf('year') : dayjs();
        vm.fromStr = vm.from.format('DD.MM.YYYY hh:mm');
        vm.toStr = vm.to.format('DD.MM.YYYY hh:mm');
      } else {
        vm.from = dayjs(`${vm.year.value}-${vm.month.value}-1`).startOf('month');
        vm.to = dayjs(`${vm.year.value}-${vm.month.value}-1`).endOf('month');
        vm.fromStr = vm.from.format('DD.MM.YYYY hh:mm');
        vm.toStr = vm.to.format('DD.MM.YYYY hh:mm');
      }
      UserService.getUserReport(vm.organizationId, vm.diverId, vm.from.valueOf(), vm.to.valueOf(), vm.diveType).then((dives) => {
        if (vm.diveType === 'water') {
          vm.dives = dives;
        } else if (vm.diveType === 'rescue') {
          vm.rescueDives = dives;
        } else {
          vm.exposures = dives;
        }
      }, (err) => {
        console.log(err);
      });
    };
    vm.getReport();
  }
}

// eslint-disable-next-line max-len
ReportsUserController.$inject = ['$state', '$stateParams', '$translate', '$window', 'UserService', 'currentUser', 'user', 'organization', 'DiveService', 'SiteService', 'OrganizationService', 'sitesMap'];

// eslint-disable-next-line max-len
export default angular.module('ReportsUserController', [UserService, DiveService, SiteService, OrganizationService]).controller('ReportsUserController', ReportsUserController).name;
