import * as angular from 'angular';
import options from './options';

const ExposureService = function ($q, $http, $stateParams, $translate, options) {

  const translate = $translate.instant;
  const exposureService = {};
  
  exposureService.getExposuresByKey = (exposures, key, value) => {
    return exposures.filter((d) => d[key] === value);
  }

  exposureService.find = function (name) {
    const d = $q.defer();
    $http({
      url: `${options.api.base_url}/users/${$stateParams.uid}/organizations/${$stateParams.organizationId}/exposures`,
      method: 'GET',
    })
      .then((results) => {
        angular.forEach(results, (value, key) => {
          const regex = new RegExp(name, 'i');
          if (value.description.match(regex)) {
            results.data.push(value);
          }
        });
        d.resolve(results.data);
      }, (err) => {
        d.reject(err.data);
      });

    return d.promise;
  };

  exposureService.get = function (id) {
    const d = $q.defer();
    $http({
      url: `${options.api.base_url}/users/${$stateParams.uid}/organizations/${$stateParams.organizationId}/exposures/${id}`,
      method: 'GET',
    })
      .then((results) => {
        d.resolve(results.data);
      }, (err) => {
        d.reject(err.data);
      });

    return d.promise;
  };

  exposureService.add = function (data) {
    const d = $q.defer();
    $http({
      url: `${options.api.base_url}/users/${$stateParams.uid}/organizations/${$stateParams.organizationId}/exposures`,
      method: 'POST',
      data: JSON.stringify({ exposure: data }),
      headers: { 'Content-Type': 'application/json' },
    })
      .then((results) => {
        console.log(results);
        d.resolve(results.data);
      }, (err) => {
        console.log(err);
        d.reject(err.data);
      });

    return d.promise;
  };

  exposureService.delete = function (id) {
    const d = $q.defer();
    $http({
      url: `${options.api.base_url}/users/${$stateParams.uid}/organizations/${$stateParams.organizationId}/exposures/${id}`,
      method: 'DELETE',
    })
      .then((results) => {
        d.resolve(results.data);
      }, (err) => {
        d.reject(err.data);
      });

    return d.promise;
  };

  exposureService.list = function (userId) {
    const d = $q.defer();
    $http({
      url: `${options.api.base_url}/users/${userId}/organizations/${$stateParams.organizationId}/exposures`,
      method: 'GET',
      cache: false,
    })
      .then((results) => {
        d.resolve(results.data);
      }, (err) => {
        d.reject(err.data);
      });

    return d.promise;
  };

  exposureService.listAll = function (userId) {
    const d = $q.defer();
    $http({
      url: `${options.api.base_url}/users/${userId}/organizations/${$stateParams.organizationId}/exposures/all`,
      method: 'GET',
      cache: false,
    })
      .then((results) => {
        d.resolve(results.data);
      }, (err) => {
        d.reject(err.data);
      });
    return d.promise;
  };

  exposureService.update = function (data) {
    const d = $q.defer();
    $http({
      url: `${options.api.base_url}/users/${$stateParams.uid}/organizations/${$stateParams.organizationId}/exposures/${data._id}`,
      method: 'PUT',
      data: JSON.stringify({ exposure: data }),
      headers: { 'Content-Type': 'application/json' },
    })
      .then((results) => {
        d.resolve(results.data);
      }, (err) => {
        console.log(err);
        d.reject(err.data);
      });

    return d.promise;
  };

  exposureService.formatResult = function (res) {
    return res.name;
  };

  exposureService.formatSelection = function (res) {
    return res.name;
  };

  return exposureService;
}

ExposureService.$inject = ['$q', '$http', '$stateParams', '$translate', 'options'];

export default angular.module('ExposureService', [options]).factory('ExposureService', ExposureService).name;
