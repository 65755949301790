import * as angular from 'angular';
import UserService from '../services/UserService';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const SettingsInfoController = function ($scope, $state, $timeout, $translate, user, organization, UserService) {

  const vm = this;
  const translate = $translate.instant;
  user.course_date = dayjs(user.course_date).second(0).millisecond(0).toDate();
  user.skipper_course_date = dayjs(user.skipper_course_date).second(0).millisecond(0).toDate();
  vm.user = user;
  vm.userdata = user;
  vm.showAlert = false;
  vm.errors = {};
  vm.diveCourse = user.diver;
  vm.skipperCourse = user.skipper;
  if (!vm.userdata.settings) {
    vm.userdata.settings = {
      lang: organization.settings?.lang || 'fi'
    }
  }

  vm.languages = ['en', 'fi', 'sv'];

  vm.changeLanguage = function (key) {
    // eslint-disable-next-line angular/controller-as
    $scope.$emit('update-language', { key });
  };

  vm.course_types = {
    NONE: translate('NONE'),
    NAVY_DIVER: translate('NAVY_DIVER'),
    NATIONAL_FIRE_ACADEMY_RESCUE_DIVER: translate('NATIONAL_FIRE_ACADEMY_RESCUE_DIVER'),
    REGIONAL_RESCUE_DIVER: translate('REGIONAL_RESCUE_DIVER'),
    COMMERCIAL_DIVER: translate('COMMERCIAL_DIVER'),
  };

  vm.skipper_course_types = {
    NONE: translate('NONE'),
    SKIPPER: translate('SKIPPER'),
    BOATMAN: translate('BOATMAN')
  }

  vm.deleteAccount = function () {
    UserService.deleteAccount(vm.user._id, organization._id).then((data) => {
      console.log(data);
      UserService.logout();
      $state.go('index.logout');
    }, (err) => {
      console.log(err);
    });
  }

  vm.saveData = function () {
    vm.showAlert = false;
    vm.alertClass = null;
    vm.alertMessage = null;
    vm.errors = {};

    let updatedData = { ...vm.userdata };
    updatedData.course_date = dayjs(vm.userdata.course_date).valueOf();
    updatedData.skipper_course_date = dayjs(vm.userdata.skipper_course_date).valueOf();
    // updatedData.settings = vm.userdata.settings || {
    //   lang:
    // }
    UserService.update(updatedData).then((data) => {
      vm.showAlert = false;
      vm.showAlert = true;
      vm.alertClass = 'success';
      vm.alertMessage = translate('SAVE_SUCCESS');
      $timeout(() => {
        $state.reload();
      }, 2000);
    }, (err) => {
      console.log(err);
      vm.showAlert = true;
      vm.alertClass = 'danger';
      vm.alertMessage = translate('SAVE_FAILED');
      vm.errors = err.errors;
      Object.entries(err.errors).forEach(([key, error]) => {
        if (error.path) {
          $(`#${error.path}`).addClass('is-invalid');
        } else {
          $(`#${key}`).addClass('is-invalid');
        }
      });
    });
  }
}
SettingsInfoController.$inject = ['$scope', '$state', '$timeout', '$translate', 'user', 'organization', 'UserService'];

export default angular.module('SettingsInfoController', [UserService]).controller('SettingsInfoController', SettingsInfoController).name;
