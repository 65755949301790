import * as angular from 'angular';

const StatisticsController = function ($stateParams, user) {

  const vm = this;
  vm.user = user;
  vm.diverId = $stateParams.uid;
}

StatisticsController.$inject = ['$stateParams', 'user'];

export default angular.module('StatisticsController', []).controller('StatisticsController', StatisticsController).name;
