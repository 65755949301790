import * as angular from 'angular';
import DiveService from '../services/DiveService';
import RescueDiveService from '../services/RescueDiveService';
import SiteService from '../services/SiteService';

// eslint-disable-next-line max-len
const DivesListController = function ($state, $stateParams, $translate, $window, DiveService, RescueDiveService, currentUser, user, organization, sitesMap, SiteService) {

  const vm = this;
  vm.user = user;
  vm.currentUser = currentUser;
  vm.diverId = $stateParams.uid;
  vm.searchDive = '';
  vm.sites = sitesMap;
  vm.diveType = $state.params.diveType || 'water';
  vm.rescueDives = [];
  vm.calcConsumption = DiveService.calcConsumption;
  vm.calcRescueConsumption = RescueDiveService.calcConsumption;

  vm.alert = (msg) => {
    $window.alert(msg);
  }

  vm.isDive = (dive) => {
    return DiveService.isDive(dive, organization);
  }

  vm.getDepth = (depth) => {
    return DiveService.roundDepth(depth);
  }

  vm.fetchDives = () => {
    DiveService.list(vm.diverId).then((data) => {
      data.forEach((d) => {
        // to fetch newly created sites
        if (!sitesMap[d.site]) {
          vm.fetchSite(d.site);
        }
      });
      vm.dives = data;
    });
    RescueDiveService.list(vm.diverId).then((dives) => {
      vm.rescueDives = dives;
    })
  }

  vm.fetchDives();

  vm.diveTypeFilter = (value) => {
    return value;
  }

  vm.filterSolver = (value) => {
    if (angular.isNumber(value)) {
      return vm.convertTime(value);
    } else {
      return vm.plotSite(value);
    }
  };

  vm.fetchSite = (id) => {
    if (!id || (id && id === '????')) return;
    return SiteService.get(id).then((data) => {
      vm.sites[id] = data;
      return Promise.resolve();
    }, (err) => {
      console.log(err);
      return Promise.reject();
    });
  };

  vm.plotSite = function (siteId) {
    return sitesMap[siteId];
  };

  vm.convertTime = function (time) {
    return dayjs(time).format('DD.MM.YYYY');
  };

  vm.getTime = function (value) {
    return Math.round(((value.time_end - value.time_start) / 60000) * 100) / 100;
  };

  vm.removeDive = function (params) {
    DiveService.delete(params.did).then((ret) => {
      console.log('success', ret);
      $state.go($state.current, {}, { reload: true });
    }, (err) => {
      alert($translate.instant('COULD_NOT_REMOVE') + `${err}`);
    });
  };

  vm.removeRescueDive = function (params) {
    RescueDiveService.delete(params.did).then((ret) => {
      console.log('success', ret);
      $state.go($state.current, {}, { reload: true });
    }, (err) => {
      // TODO fix this alert to notification
      alert($translate.instant('COULD_NOT_REMOVE') + `${err}`);
    });
  };
}
  
// eslint-disable-next-line max-len
DivesListController.$inject = ['$state', '$stateParams', '$translate', '$window', 'DiveService', 'RescueDiveService', 'currentUser', 'user', 'organization', 'sitesMap', 'SiteService'];

export default angular.module('DivesListController', [DiveService, RescueDiveService, SiteService])
  .controller('DivesListController', DivesListController).name;
