import * as angular from 'angular';
import DiveService from '../services/DiveService';
import MapService from '../services/MapService';
import SiteService from '../services/SiteService';

const SitesMapController = function ($scope, $stateParams, $translate, user, organization, GoogleMapApi, MapService, SiteService, DiveService) {

  const vm = this;
  vm.translate = $translate.instant;
  vm.user = user;
  vm.diverId = $stateParams.uid;
  vm.site = {};
  vm.divesites = null;
  vm.mapOptions = MapService.DEFAULT_OPTIONS;
  vm.layerOptions = MapService.getLayerOptions();
  vm.showNauticalLayer = false;
  const markerOptions = DiveService.getMarkerOptions();

  vm.toggleNauticalLayer = () => {
    vm.showNauticalLayer = !vm.showNauticalLayer;
  }

  SiteService.list($stateParams.organizationId).then((sites) => {
    const { label1, label2 } = MapService.getLabels();

    vm.divesites = sites;
    MapService.createMarkerStyle(label1, document);
    MapService.createMarkerStyle(label2, document);
    MapService.updateMarkers(sites, {}, vm.diverId, markerOptions);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        plotMap(pos);
      }, (err) => {
        plotMap();
      });
    } else {
      plotMap();
    }

    const plotMap = function (pos) {
      GoogleMapApi.then((maps) => {
        MapService.createMarkerStyle(label1, document);
        MapService.createMarkerStyle(label2, document);
        MapService.updateMarkers(sites, {}, vm.diverId, markerOptions);
        vm.map = {
          center: {
            longitude: pos && pos.coords.longitude || organization.longitude,
            latitude: pos && pos.coords.latitude || organization.latitude,
          },
          zoom: organization.zoomLevel,
        };
      });

      vm.markersEvents = {
        click(gMarker, eventName, model) {
          vm.site._id = model._id;
          vm.site.depth = model.depth || '?';
          vm.site.name = model.name;
          vm.site.longitude = model.longitude;
          vm.site.latitude = model.latitude;
          MapService.updateMarkers(sites, model, vm.diverId, markerOptions);
          $scope.$apply();
        },
      };
    };
  });
}

// eslint-disable-next-line max-len
SitesMapController.$inject = ['$scope', '$stateParams', '$translate', 'user', 'organization', 'uiGmapGoogleMapApi', 'MapService', 'SiteService', 'DiveService'];

export default angular.module('SitesMapController', [MapService, SiteService, DiveService]).controller('SitesMapController', SitesMapController).name;
