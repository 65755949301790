import * as angular from 'angular';
import DiveService from '../services/DiveService';
import Highcharts from 'highcharts/highstock';

const StatisticsUserController = function ($stateParams, $translate, sitesMap, user, DiveService) {

  const vm = this;
  const sitesObj = {};
  const sites = [];
  const siteNames = [];
  vm.user = user;
  vm.diveData = [];
  vm.diverId = $stateParams.uid;
  vm.deepDives = DiveService.getDeepDives;
  vm.problemDives = DiveService.getProblemDives;
  vm.alertDives = DiveService.getAlertDives;
  vm.testDives = DiveService.getTestDives;
  vm.iceDives = DiveService.getIceDives;
  vm.otherDives = DiveService.getOtherDives;
  vm.specialExercises = DiveService.getSpecialExerciseDives;
  vm.otherTestDives = DiveService.getOtherTestDives;
  vm.getLast = DiveService.getLast;
  vm.getFormer = DiveService.getFormer;
  vm.divesDone = (dives) => dives.length > 0;

  DiveService.list(vm.diverId).then((data) => {
    angular.forEach(data, (value, index) => {
      if (value.site in sitesObj) {
        sitesObj[value.site].value++;
      } else {
        sitesObj[value.site] = { id: value.site, value: 1 };
      }
    });

    const sorted = _.sortBy(Object.values(sitesObj), 'value').reverse();
    angular.forEach(sorted, (value, index) => {
      if (sitesMap[value.id]) {
        sites.push(value.value);
        siteNames.push(sitesMap[value.id].name);
      } else {
        console.log(`Could not find dive site for dive: ${value.id}`);
      }
    });
    Highcharts.chart('sites-container', {
      title: {
        text: $translate.instant('DIVES_PER_SITE'),
        style: {
          color: '#fff'
        }
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: siteNames,
        labels: {
          style: {
            color: '#ffffff',
            fontSize: '14px'
          }
        }
      },
      yAxis: {
        opposite: true,
        title: '',
        tickInterval: 1,
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white',
          },
        },
      },
      legend: {
        enabled: false,
      },
      chart: {
        height: `${Math.max(sites.length * 20, 500)}px`,
        type: 'bar',
      },
      series: [
        {
          name: $translate.instant('DIVES'),
          data: sites,
        },
      ],
    });
  })

  DiveService.list(vm.diverId).then((data) => {
    const tmpDives = [];
    vm.diveData = data;

    const currentDate = Date.now();
    angular.forEach(data, (value, index) => {
      if (currentDate - value.time_start > 0) {
        const tmpDive = [value.time_start, 1];
        tmpDives.push(tmpDive);
      }
    });

    Highcharts.StockChart('dives-container', {
      title: {
        text: $translate.instant('DIVES'),
      },
      credits: {
        enabled: false
      },
      color: {
        patternIndex: 0
      },
      chart: {
        height: '500px', // relative height not good for mobile
        type: 'line',
      },
      series: [
        {
          name: $translate.instant('DIVES'),
          dataGrouping: {
            approximation: 'sum',
            forced: true,
            units: [
              ['month', [1]],
            ],
          },
          data: tmpDives.reverse(),
        },
      ],
    });
  });
}
StatisticsUserController.$inject = ['$stateParams', '$translate', 'sitesMap', 'user', 'DiveService'];

export default angular.module('StatisticsUserController', [DiveService]).controller('StatisticsUserController', StatisticsUserController).name;
