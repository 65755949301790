import * as angular from 'angular';
import UserService from '../services/UserService';

const ResetPasswordUsernameController = function ($state, $timeout, $translate, UserService) {
  const vm = this;
  vm.showAlert = false;
  vm.fields = {};
  vm.errors = {};
  const translate = $translate.instant;

  vm.sendUsername = function () {
    vm.passwordShowAlert = false;

    UserService.sendUsername(vm.username)
      .then((ret) => {
        vm.passwordShowAlert = true;
        vm.passwordAlertClass = 'success';
        vm.passwordAlertMessage = translate('PASSWORD_RESET_SUCCESSFUL_CHECK_EMAIL');
        $timeout(function (){
          $state.go('index.login');
        }, 6000);
      }, (err) => {
        vm.passwordShowAlert = true;
        vm.passwordAlertClass = 'danger';
        vm.passwordAlertMessage = translate('PASSWORD_RESET_FAILED_CONTACT_ADMIN');
        vm.errors = err.errors;
        Object.entries(err.errors).forEach(([key, error]) => {
          if (error.path) {
            $(`#password-${error.path}`).addClass('is-invalid');
          } else {
            $(`#password-${key}`).addClass('is-invalid');
          }
        });
      });
  };
}
ResetPasswordUsernameController.$inject = ['$state', '$timeout', '$translate', 'UserService'];

// eslint-disable-next-line max-len
export default angular.module('ResetPasswordUsernameController', [UserService]).controller('ResetPasswordUsernameController', ResetPasswordUsernameController).name;
