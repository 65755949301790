import * as angular from 'angular';
import AuthenticationService from '../services/AuthenticationService';

const RootController = function ($transitions, $rootScope, $state, $window, $translate, AuthenticationService) {

  if ($state.current.access && $state.current.access.requiredAuthentication && !AuthenticationService.isAuthenticated) {
    $state.go('index.login');
  }

  $rootScope.changeLanguage = function (key) {
    console.log('changing language to ' + key)
    $rootScope.lang = key;
    $translate.use(key);
  };

  $transitions.onStart({ }, function(trans) {
    // const SpinnerService = trans.injector().get('SpinnerService');
    // SpinnerService.transitionStart();
    // trans.promise.finally(SpinnerService.transitionEnd);
    if (trans.to() != null && trans.to().access != null && trans.to().access.requiredAuthentication
                            && !AuthenticationService.isAuthenticated && !$window.sessionStorage.token) {
      return trans.router.stateService.target('index.login');
    }
  });

}
RootController.$inject = ['$transitions', '$rootScope', '$state', '$window', '$translate', 'AuthenticationService'];

export default angular.module('RootController', [AuthenticationService]).controller('RootController', RootController).name;
