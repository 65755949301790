import * as angular from 'angular';
import DiveService from '../services/DiveService';
import ExposureService from '../services/ExposureService';
import Highcharts from 'highcharts/highstock';

const StatisticsAllExposuresController = function ($stateParams, $translate, user, ExposureService, DiveService) {

  const vm = this;

  vm.user = user;
  vm.diveData = [];
  vm.diverId = $stateParams.uid;
  vm.exposuresByKey = ExposureService.getExposuresByKey;
  vm.getLast = DiveService.getLast;
  vm.getFormer = DiveService.getFormer;
  vm.divers = [];
  vm.translate = $translate.instant;

  const plotExposures = function (data) {
    const tmpExposures = [];
    const currentDate = Date.now();
    console.log(data);
    vm.exposureData = data;
    angular.forEach(data, (value, index) => {
      if (currentDate - value.time_start > 0) {
        const tmpExposure = [value.time_start, 1];
        tmpExposures.push(tmpExposure);
      }
    });
    Highcharts.StockChart('exposures-container', {
      title: {
        text: vm.translate('ALL_ORGANIZATION_EXPOSURES'),
      },
      chart: {
        height: '500px',
        type: 'line',
      },
      credits: {
        enabled: false
      },
      series: [
        {
          name: vm.translate('EXPOSURES'),
          dataGrouping: {
            approximation: 'sum',
            forced: true,
            units: [
              ['month', [1]],
            ],
          },
          data: tmpExposures.reverse(),
        },
      ],
    });
  }

  ExposureService.listAll(vm.diverId).then((data) => {
    plotExposures(data);
  })
}
StatisticsAllExposuresController.$inject = ['$stateParams', '$translate', 'user', 'ExposureService', 'DiveService'];

export default angular.module('StatisticsAllExposuresController', [ExposureService, DiveService])
  .controller('StatisticsAllExposuresController', StatisticsAllExposuresController).name;
