import * as angular from 'angular';
import DiveService from './DiveService';
import options from './options';

const RescueDiveService = function ($q, $http, $stateParams, $translate, options, DiveService) {

  const translate = $translate.instant;
  const rescueDiveService = {};

  rescueDiveService.getAlertDives = (dives) => {
    return dives.filter((d) => d.type === 'ALARM');
  }

  rescueDiveService.getLast = (dives, type) => {
    return dives.filter((d) => {
      const pastDate = dayjs(d.time_start).unix();
      const thisMonth = dayjs().startOf(type).unix();
      return pastDate > thisMonth;
    })
  }

  rescueDiveService.getFormer = (dives, type) => {
    return dives.filter((d) => {
      const pastDate = dayjs(d.time_start).unix();
      const lastDate = dayjs().startOf(type).unix();
      const formerDate = dayjs().startOf(type).subtract(1, type).unix();
      return pastDate > formerDate && pastDate < lastDate;
    })
  }

  rescueDiveService.calcDivetime = function (dive) {
    if (!dive) return;
    return DiveService.getMinutes(dive.time_end - dive.time_start);
  }

  rescueDiveService.calcConsumption = function (dive) {
    if (!dive) return;

    const diveTime = ((dive.time_end - dive.time_start) / 60000);
    const diveVolume = ((dive.pressure_start - dive.pressure_end) * dive.volume);
    return Math.round((diveVolume / diveTime)* 100) / 100 || 0;
  };

  rescueDiveService.find = function (name) {
    const d = $q.defer();
    $http({
      url: `${options.api.base_url}/users/${$stateParams.uid}/organizations/${$stateParams.organizationId}/rescuedives`,
      method: 'GET',
    })
      .then((results) => {
        angular.forEach(results, (value, key) => {
          const regex = new RegExp(name, 'i');
          if (value.description.match(regex)) {
            results.data.push(value);
          }
        });
        d.resolve(results.data);
      }, (err) => {
        d.reject(err.data);
      });

    return d.promise;
  };

  rescueDiveService.get = function (id) {
    const d = $q.defer();
    $http({
      url: `${options.api.base_url}/users/${$stateParams.uid}/organizations/${$stateParams.organizationId}/rescuedives/${id}`,
      method: 'GET',
    })
      .then((results) => {
        d.resolve(results.data);
      }, (err) => {
        d.reject(err.data);
      });

    return d.promise;
  };

  rescueDiveService.add = function (data) {
    const d = $q.defer();
    $http({
      url: `${options.api.base_url}/users/${$stateParams.uid}/organizations/${$stateParams.organizationId}/rescuedives`,
      method: 'POST',
      data: JSON.stringify({ dive: data }),
      headers: { 'Content-Type': 'application/json' },
    })
      .then((results) => {
        console.log(results);
        d.resolve(results.data);
      }, (err) => {
        console.log(err);
        d.reject(err.data);
      });

    return d.promise;
  };

  rescueDiveService.delete = function (id) {
    const d = $q.defer();
    $http({
      url: `${options.api.base_url}/users/${$stateParams.uid}/organizations/${$stateParams.organizationId}/rescuedives/${id}`,
      method: 'DELETE',
    })
      .then((results) => {
        d.resolve(results.data);
      }, (err) => {
        d.reject(err.data);
      });

    return d.promise;
  };

  rescueDiveService.list = function (userId) {
    const d = $q.defer();
    $http({
      url: `${options.api.base_url}/users/${userId}/organizations/${$stateParams.organizationId}/rescuedives`,
      method: 'GET',
      cache: false,
    })
      .then((results) => {
        d.resolve(results.data);
      }, (err) => {
        d.reject(err.data);
      });

    return d.promise;
  };

  rescueDiveService.listAll = function (userId) {
    const d = $q.defer();
    $http({
      url: `${options.api.base_url}/users/${userId}/organizations/${$stateParams.organizationId}/rescuedives/all`,
      method: 'GET',
      cache: false,
    })
      .then((results) => {
        d.resolve(results.data);
      }, (err) => {
        d.reject(err.data);
      });
    return d.promise;
  };

  rescueDiveService.update = function (data) {
    const d = $q.defer();
    $http({
      url: `${options.api.base_url}/users/${$stateParams.uid}/organizations/${$stateParams.organizationId}/rescuedives/${$stateParams.did}`,
      method: 'PUT',
      data: JSON.stringify({ dive: data }),
      headers: { 'Content-Type': 'application/json' },
    })
      .then((results) => {
        console.log(results);
        d.resolve(results.data);
      }, (err) => {
        console.log(err);
        d.reject(err.data);
      });

    return d.promise;
  };

  rescueDiveService.loadOptionsAjax = function (queryParams) {
    return rescueDiveService.find(queryParams.data.q).then(queryParams.success);
  };

  rescueDiveService.formatResult = function (res) {
    return res.name;
  };

  rescueDiveService.formatSelection = function (res) {
    return res.name;
  };

  rescueDiveService.getDiveTypes = function () {
    return ['TRAINING', 'TRAINING_HOT', 'TRAINING_COLD', 'ALARM', 'RESCUE', 'TEST', 'OTHER'];
  };

  rescueDiveService.getVolumes = function () {
    return {
      '1x6': 6,
      '2x4': 8,
      '2x6': 12,
      '2x7': 14,
    };
  };

  rescueDiveService.getApparatus = function () {
    return ['2x4', '2x6', '1x6', '2x7'];
  };

  return rescueDiveService;
}

RescueDiveService.$inject = ['$q', '$http', '$stateParams', '$translate', 'options', 'DiveService'];

export default angular.module('RescueDiveService', [options, DiveService]).factory('RescueDiveService', RescueDiveService).name;
