import * as angular from 'angular';
import UserService from '../services/UserService';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const SettingsTestsController = function ($state, $stateParams, $timeout, $translate, user, UserService, organization) {

  const vm = this;
  const translate = $translate.instant;
  vm.user = user;
  vm.userdata = user;
  vm.showAlert = false;
  vm.testsData = {};
  vm.notifications = {};
  vm.errors = {};

  vm.tests = (organization.tests && organization.tests.length > 0) ? organization.tests.map((t) => {
    return {
      ...t,
      errorMessage: translate(`${t.name}_DEPRECATED`)
    }
  }) : [
    {
      name: 'medicalsDate',
      title: 'Sukelluslääkärintarkastus suoritettu, pvm',
      // errorMessage: 'Edellisestä tarkastuksesta aikaa yli 12 kk.',
      errorMessage: translate('TEST_DEPRECATED_12_MONTHS'),
      limit: 31104000000,
    },
    {
      name: 'deepDiveDate',
      title: 'Koulutussyvyys sukellus suoritettu, pvm',
      errorMessage: translate('TEST_DEPRECATED_12_MONTHS'),
      limit: 31104000000,
    },
    {
      name: 'problemTrainingDate',
      title: 'Ongelmatilanneharjoittelu suoritettu, pvm',
      errorMessage: translate('TEST_DEPRECATED_12_MONTHS') + translate('REQUIREMENT_2_TIMES_YEAR'),
      // 'Vaatimus 2x / vuosi.',
      limit: 31104000000,
    },
    {
      name: 'searchTestDate',
      title: '200m² testi suoritettu, pvm',
      errorMessage: translate('TEST_DEPRECATED_12_MONTHS'),
      limit: 31104000000,
    },
    // {
    //     name: 'dentistDate',
    //     title: 'Hammaslääkäri suoritettu, pvm',
    //     errorMessage: 'Edellisestä tarkastuksesta aikaa yli 12 kk.',
    //     limit: 31104000000
    // }
  ];

  const updateTests = function () {
    UserService.getTargetUser(vm.user._id, $stateParams.organizationId)
      .then((result) => {
        vm.userdata = result;
        if (!vm.userdata.tests) {
          vm.userdata.tests = {};
        }
        for (let j = 0; j < vm.tests.length; j++) {
          if (vm.userdata.tests && vm.userdata.tests[vm.tests[j].name]) {
            vm.testsData[vm.tests[j].name] = dayjs(vm.userdata.tests[vm.tests[j].name]).toDate();
          }

          if (!vm.userdata.tests || !vm.userdata.tests[vm.tests[j].name]
                                || ((Date.now() - vm.userdata.tests[vm.tests[j].name]) > vm.tests[j].limit)) {
            // if tests are not done, or are deprecated, show warning
            vm.notifications[vm.tests[j].name] = true;
          } else {
            vm.notifications[vm.tests[j].name] = false;
          }
        }
      });
  };
  updateTests();

  vm.saveData = function () {
    const update = {};
    update._id = user._id;
    update.tests = {};
    vm.showAlert = false;
    vm.alertClass = null;
    vm.alertMessage = null;
    vm.errors = {};

    if (!vm.userdata.tests) {
      vm.userdata.tests = {};
    }
    for (const i in vm.testsData) {
      update.tests[i] = dayjs($(`#${i}`).val(), 'DD.MM.YYYY').valueOf();
    }

    UserService.update(update)
      .then((result) => {
        vm.showAlert = true;
        vm.alertClass = 'success';
        vm.alertMessage = translate('SAVE_SUCCESS');
        updateTests();
        $timeout(() => {
          $state.reload();
        }, 2000);
      }, (err) => {
        console.log(err);
        vm.showAlert = true;
        vm.alertClass = 'danger';
        vm.alertMessage = translate('SAVE_FAILED');
        vm.errors = err.data.errors;
        Object.entries(err.errors).forEach(([key, error]) => {
          if (error.path) {
            $(`#${error.path}`).addClass('is-invalid');
          } else {
            $(`#${key}`).addClass('is-invalid');
          }
        });
      });
  }
}
SettingsTestsController.$inject = ['$state', '$stateParams', '$timeout', '$translate', 'user', 'UserService', 'organization'];

export default angular.module('SettingsTestsController', [UserService]).controller('SettingsTestsController', SettingsTestsController).name;
