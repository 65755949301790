import * as angular from 'angular';
import DiveService from '../services/DiveService';
import MapService from '../services/MapService';

const DivesMapController = function ($scope, $stateParams, user, organization, GoogleMapApi, sitesMap, MapService, DiveService) {

  const vm = this;

  vm.user = user;
  vm.diverId = $stateParams.uid;
  vm.site = {};
  vm.dives = null;
  vm.markers = [];
  vm.mapOptions = MapService.DEFAULT_OPTIONS;
  vm.layerOptions = MapService.getLayerOptions();
  vm.showNauticalLayer = false;
  vm.sites = sitesMap;
  const markerOptions = DiveService.getMarkerOptions();

  vm.toggleNauticalLayer = () => {
    vm.showNauticalLayer = !vm.showNauticalLayer;
  }

  vm.convertTime = function (time) {
    return dayjs(time).format('DD.MM.YYYY hh:mm');
  };

  vm.getDate = function (time) {
    return dayjs(time).format('DD.MM.YYYY');
  };

  vm.searchTemplate = '<input type="text" id="search-field"></input>';

  vm.searchEvents = {
    places_changed: function (searchBox) {
      console.log(searchBox)
    }
  }

  DiveService.listAll(vm.diverId).then((dives) => {
    const { label1, label2, label3, label4, label5 } = MapService.getLabels();

    MapService.createMarkerStyle(label1, document);
    MapService.createMarkerStyle(label2, document);
    MapService.createMarkerStyle(label3, document);
    MapService.createMarkerStyle(label4, document);
    MapService.createMarkerStyle(label5, document);
    // MapService.updateDiveMarkers(dives, {}, sitesMap, vm.diverId, markerOptions);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        plotMap(pos);
      }, (err) => {
        plotMap();
      });
    } else {
      plotMap();
    }

    const plotMap = function (pos) {
      GoogleMapApi.then((maps) => {
        vm.markers = MapService.updateDiveMarkers(dives, {}, sitesMap, vm.diverId, markerOptions);
        vm.map = {
          center: {
            longitude: pos && pos.coords.longitude || organization.longitude,
            latitude: pos && pos.coords.latitude || organization.latitude,
          },
          zoom: organization.zoomLevel,
        };
      });

      vm.markersEvents = {
        click(gMarker, eventName, model) {
          vm.site = model;
          vm.alarms = vm.site.dives.filter((d) => d.type === 'ALARM')
          vm.alarmDiveEvents = {}
          vm.alarms.forEach((alarmDive) => {
            const diveDate = vm.getDate(alarmDive.time_start)
            if (vm.alarmDiveEvents[diveDate]) {
              vm.alarmDiveEvents[diveDate].push(alarmDive);
            } else {
              vm.alarmDiveEvents[diveDate] = [alarmDive];
            }
          })
          vm.userDives = vm.site.dives.filter((d) => d.user_id === vm.diverId)
          vm.otherDives = vm.site.dives.filter((d) => d.type !== 'ALARM')
          MapService.updateDiveMarkers(dives, model, sitesMap, vm.diverId, markerOptions);
          $scope.$apply();
        },
      };
    };
  });
}

DivesMapController.$inject = ['$scope', '$stateParams', 'user', 'organization', 'uiGmapGoogleMapApi', 'sitesMap', 'MapService', 'DiveService'];

export default angular.module('DivesMapController', [MapService, DiveService]).controller('DivesMapController', DivesMapController).name;
