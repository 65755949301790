import * as angular from 'angular';
import DocumentService from '../services/DocumentService';
import FileService from '../services/FileService';

const DocumentsController = function ($window, $scope, $stateParams, $translate, user, DocumentService, FileService) {

  const vm = this;
  vm.errors = {};
  const fetchDocuments = function () {
    const promise = FileService.getFiles();

    promise.then((response) => {
      console.log(response);
      vm.documents = response.data;
    }, () => {
      vm.serverResponse = $translate.instant('COULD_NOT_FETCH_DOCUMENTS');
    });
  };
  fetchDocuments();

  vm.user = user;
  vm.diverId = $stateParams.uid;
  vm.pdfUrl = DocumentService.PDF_URL;
  vm.mobilePdfUrl = DocumentService.MOBILE_PDF_URL;

  vm.isMobile = $window.innerWidth < 768;
  angular.element($window).on('resize', () => {
    vm.isMobile = $window.innerWidth < 768;
    // manuall $digest required as resize event
    // is outside of angular
    $scope.$digest();
  });

  vm.removeDocument = function (documentId) {
    FileService.deleteDocument(documentId).then((ret) => {
      fetchDocuments();
      vm.fileShowAlert = true;
      vm.fileAlertClass = 'success';
      vm.fileAlertMessage = $translate.instant('DOCUMENT_REMOVED');
    }, (err) => {
      vm.fileShowAlert = true;
      vm.fileAlertClass = 'danger';
      vm.fileAlertMessage = $translate.instant('COULD_NOT_REMOVE_DOCUMENT');
      vm.errors = err.errors;
    });
  };

  vm.uploadFile = function () {
    const { name, newFile: file } = vm;
    const promise = FileService.uploadFile(file, name);

    promise.then((response) => {
      fetchDocuments();
      vm.fileShowAlert = true;
      vm.fileAlertClass = 'success';
      vm.fileAlertMessage = $translate.instant('DOCUMENT_ADDED');
    }, (err) => {
      console.log(err);
      vm.fileShowAlert = true;
      vm.fileAlertClass = 'danger';
      vm.fileAlertMessage = $translate.instant('COULD_NOT_ADD_DOCUMENT');
      vm.errors = err.data.errors;
    });
  };
}
DocumentsController.$inject = ['$window', '$scope', '$stateParams', '$translate', 'user', 'DocumentService', 'FileService'];

export default angular.module('DocumentsController', [DocumentService, FileService]).controller('DocumentsController', DocumentsController).name;
