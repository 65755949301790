import * as angular from 'angular';

const reverse = () => function (list) {

  function toArray(list) {
    let k; let
      out = [];
    if (list) {
      if (angular.isArray(list)) {
        out = list;
      } else if (typeof (list) === 'object') {
        for (k in list) {
          if (list.hasOwnProperty(k)) { out.push(list[k]); }
        }
      }
    }
    return out;
  }
  return function (items) {
    return toArray(items).slice().reverse();
  };
}

export default angular.module('reverse', []).filter('reverse', reverse).name;
