import * as angular from 'angular';
import SiteService from '../services/SiteService';

const SitesListController = function ($window, $stateParams, $translate, user, SiteService) {

  const vm = this;
  vm.translate = $translate.instant;
  vm.user = user;
  vm.search = '';
  vm.diverId = vm.user.id;
  vm.sites = null;
  vm.isMobile = $window.innerWidth < 768;
  SiteService.listAsMap($stateParams.organizationId).then((data) => {
    vm.sites = data;
    console.log(data)
  });

  vm.trim = function (value) {
    if (value) {
      value = (`${value}`).substring(0, 9);
    } else {
      value = '';
    }
    return value;
  };
}

SitesListController.$inject = ['$window','$stateParams', '$translate', 'user', 'SiteService'];

export default angular.module('SitesListController', [SiteService]).controller('SitesListController', SitesListController).name;
