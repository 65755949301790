import * as angular from 'angular';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import MapService from '../services/MapService';
import RescueDiveService from '../services/RescueDiveService';
import SiteService from '../services/SiteService';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(AdvancedFormat);

const EditRescueDiveController = function ($scope, $state, $stateParams, $timeout, $translate, user, organization, 
  RescueDiveService, GoogleMapApi, MapService) {

  const vm = this;
  const translate = $translate.instant;
  vm.did = $stateParams.did;
  vm.diverId = $stateParams.uid;
  vm.user = user;
  vm.mapOptions = MapService.DEFAULT_OPTIONS;
  vm.errors = {};
  vm.layerOptions = MapService.getLayerOptions();
  vm.calcConsumption = RescueDiveService.calcConsumption;
  vm.calcDivetime = RescueDiveService.calcDivetime;
  const volumes = RescueDiveService.getVolumes();
  vm.dive_apparatus = RescueDiveService.getApparatus();
  vm.types = RescueDiveService.getDiveTypes().map((v) => { return {name: translate(v), value: v }});
  vm.diveTypes = ['smoke', 'chemical'].map((v) => { return {name: translate(v.toUpperCase()), value: v }});

  vm.updateVolume = () => {
    vm.dive.volume = volumes[vm.dive.apparatus];
  }

  const { label1, label2 } = MapService.getLabels();
  MapService.createMarkerStyle(label1, document);
  MapService.createMarkerStyle(label2, document);

  const plotMap = function (pos) {
    GoogleMapApi.then((maps) => {
      vm.geocoder = new google.maps.Geocoder();
      vm.map = {
        searchOptions: {},
        searchEvents: {
          places_changed: function (searchBox) {
            const [place] = searchBox.getPlaces()
            vm.dive.longitude = place.geometry.location.lng() 
            vm.dive.latitude = place.geometry.location.lat() 
            vm.dive.address = place.formatted_address;
            plotMap({ coords: {
              longitude: vm.dive.longitude,
              latitude: vm.dive.latitude
            }})
          }
        },
        mapTypeId: 'roadmap',
        center: {
          longitude: (pos && pos.coords.longitude) || organization.longitude,
          latitude: (pos && pos.coords.latitude) || organization.latitude,
        },
        zoom: organization.zoomLevel,
      };

      vm.marker = {
        id: 0,
        coords: {
          longitude: pos && pos.coords.longitude || organization.longitude,
          latitude: pos && pos.coords.latitude || organization.latitude,
        },
        title: translate('SITE'),
        options: { draggable: true },
        events: {
          dragend(marker, eventName, args) {
            vm.dive.longitude = marker.getPosition().lng();
            vm.dive.latitude = marker.getPosition().lat();
            const latlng = new google.maps.LatLng(vm.dive.latitude, vm.dive.longitude);
            vm.geocoder.geocode({
              'latLng': latlng
            }, function (results, status) {
              if (status === google.maps.GeocoderStatus.OK) {
                if (results[0]) {
                  vm.dive.address = results[0].formatted_address;
                  $scope.$apply();
                } else {
                  console.log('No results found');
                }
              } else {
                console.error('Geocoder failed due to: ' + status);
              }
            });
            // marker.zIndex = 950;
            // marker.optimized = false;
            // $scope.$apply();
          },
        },
      };
    });
  }

  RescueDiveService.get(vm.did).then((dive) => {
    if (!dive.dive_type || !['smoke', 'chemical'].includes((dive.dive_type))) {
      console.log('Invalid dive type, redirecing to correct one..')
      $state.go(`index.users.organization.editdive.${dive.dive_type}`, { uid: vm.diverId, did: dive._id });
    }
    vm.dive = dive;
    console.log(vm.types, dive.type)
    vm.type = vm.types.find((type) => type.value === dive.type);
    vm.diveType = vm.diveTypes.find((type) => type.value === dive.dive_type);
    dive.time_start = dayjs(dive.time_start).second(0).millisecond(0).toDate();
    dive.time_end = dayjs(dive.time_end).second(0).millisecond(0).toDate();

    plotMap({ coords: {
      longitude: vm.dive.longitude,
      latitude: vm.dive.latitude
    }});
  }, (err) => {
    $state.go(`index.users.organization.editdive.water`, { uid: vm.diverId, did: vm.did });
  });

  vm.editDive = function () {
    vm.showAlert = false;
    vm.alertClass = null;
    vm.alertMessage = null;
    vm.searchBoxTpl = 'searchbox.tpl.html';
    const updatedDive = { ...vm.dive }

    updatedDive.time_start = dayjs(vm.dive.time_start).valueOf()
    updatedDive.time_end = dayjs(vm.dive.time_end).valueOf()
    updatedDive.length_s = (vm.dive.time_end - vm.dive.time_start) / 1000;
    updatedDive.type =   vm.type.value;
    updatedDive.dive_type = vm.diveType.value;

    updatedDive.volume = volumes[vm.dive.apparatus];
    updatedDive.pressure_end = parseFloat(vm.dive.pressure_end);

    RescueDiveService.update(updatedDive).then((ret) => {
      vm.showAlert = true;
      vm.alertClass = 'success';
      vm.alertMessage = translate('SAVE_SUCCESS');

      $timeout(() => {
        $state.transitionTo($state.current, $stateParams, {
          reload: true,
          inherit: false,
          notify: true,
        });
      }, 2000);
    }, (err) => {
      vm.showAlert = true;
      vm.alertClass = 'danger';
      vm.alertMessage = translate('SAVE_FAILED');
      vm.errors = err.errors;
      Object.entries(err.errors).forEach(([key, error]) => {
        if (error.path) {
          $(`#dive-${error.path}`).addClass('is-invalid');
        } else {
          $(`#dive-${key}`).addClass('is-invalid');
        }
      });
    });
  };
}

// eslint-disable-next-line max-len
EditRescueDiveController.$inject = ['$scope', '$state', '$stateParams', '$timeout', '$translate', 'user', 'organization', 'RescueDiveService', 'uiGmapGoogleMapApi', 'MapService'];

// eslint-disable-next-line max-len
export default angular.module('EditRescueDiveController', [SiteService, MapService, RescueDiveService]).controller('EditRescueDiveController', EditRescueDiveController).name;
