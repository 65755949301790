import * as angular from 'angular';
import options from './options';

const InventoryService = function ($q, $http, $stateParams, options) {

  const inventoryService = {};

  inventoryService.update = function (data) {
    const d = $q.defer();
    $http({
      url: `${options.api.base_url}/organizations/${$stateParams.organizationId
      }/inventories/${$stateParams.inventoryId}`,
      method: 'PUT',
      data: JSON.stringify({ inventory: data }),
      headers: { 'Content-Type': 'application/json' },
    })
      .then((results) => {
        console.log(results);
        d.resolve(results.data);
      }, (err) => {
        console.log(err);
        d.reject(err.data);
      });

    return d.promise;
  };

  inventoryService.list = function (organizationId) {
    const d = $q.defer();
    $http({
      url: `${options.api.base_url}/organizations/${organizationId}/inventories`,
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => {
        d.resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        d.reject(err.data);
      });

    return d.promise;
  };

  inventoryService.add = function (data) {
    const d = $q.defer();
    $http({
      url: `${options.api.base_url}/organizations/${$stateParams.organizationId}/inventories`,
      method: 'POST',
      data: JSON.stringify({ inventory: data }),
      headers: { 'Content-Type': 'application/json' },
    })
      .then((results) => {
        console.log(results);
        d.resolve(results.data);
      }, (err) => {
        console.log(err);
        d.reject(err.data);
      });

    return d.promise;
  };

  inventoryService.delete = function () {
    const d = $q.defer();
    $http({
      url: `${options.api.base_url}/organizations/${$stateParams.organizationId
      }/inventories/${$stateParams.inventoryId}`,
      method: 'DELETE',
    })
      .then((results) => {
        d.resolve(results.data);
      }, (err) => {
        d.reject(err.data);
      });

    return d.promise;
  };

  return inventoryService;
}
InventoryService.$inject = ['$q', '$http', '$stateParams', 'options'];

export default angular.module('InventoryService', [options]).factory('InventoryService', InventoryService).name;
