import * as angular from 'angular';
import UserService from '../services/UserService';

const SkipperController = function ($stateParams, organization, currentUser,  UserService) {

  const vm = this;
  vm.skippers = [];
  vm.organizationId = organization._id;
  vm.isAdmin = currentUser && (['admin', 'superadmin'].includes(currentUser.role));

  UserService.list($stateParams.organizationId).then((skippers) => {
    vm.skippers = skippers.data.filter((d) => d.skipper).map((skipper) => {
      return skipper
    });
  });
}

SkipperController.$inject = ['$stateParams', 'organization', 'currentUser', 'UserService'];

export default angular.module('SkipperController', [UserService]).controller('SkipperController', SkipperController).name;
