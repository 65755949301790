import * as angular from 'angular';
import UserService from '../services/UserService';

const SettingsPasswordController = function ($translate, user, UserService) {

  const vm = this;
  const translate = $translate.instant;
  vm.user = user;
  vm.userdata = user;
  vm.showAlert = false;
  vm.fields = {};
  vm.errors = {};

  vm.changePassword = function () {
    vm.passwordShowAlert = false;

    const oldPassword = vm.fields.old;
    const newPassword = vm.fields.new_confirm;

    if (vm.fields.new_password === vm.fields.new_confirm) {
      UserService.changePassword(vm.user.email, oldPassword, newPassword)
        .then((ret) => {
          vm.passwordShowAlert = true;
          vm.passwordAlertClass = 'success';
          vm.passwordAlertMessage = translate('PASSWORD_CHANGED');
        }, (err) => {
          console.log(err);
          vm.passwordShowAlert = true;
          vm.passwordAlertClass = 'danger';
          vm.passwordAlertMessage = translate('PASSWORD_CHANGE_FAILED');
          vm.errors = err.errors;
          Object.entries(err.errors).forEach(([key, error]) => {
            if (error.path) {
              $(`#${error.path}`).addClass('is-invalid');
            } else {
              $(`#${key}`).addClass('is-invalid');
            }
          });
        });
    } else {
      vm.passwordShowAlert = true;
      vm.passwordAlertClass = 'danger';
      vm.passwordAlertMessage = translate('PASSWORDS_DO_NOT_MATCH');
    }
  };
}
SettingsPasswordController.$inject = ['$translate', 'user', 'UserService'];

export default angular.module('SettingsPasswordController', [UserService]).controller('SettingsPasswordController', SettingsPasswordController).name;
