import * as angular from 'angular';
import AuthenticationService from './AuthenticationService';

const TokenInterceptor = function ($q, $window, $injector, AuthenticationService) {

  return {
    request(config) {
      config.headers = config.headers || {};
      if ($window.sessionStorage.token) {
        config.headers.Authorization = `Bearer ${$window.sessionStorage.token}`;
      }
      return config;
    },

    requestError(rejection) {
      return $q.reject(rejection);
    },

    /* Set Authentication.isAuthenticated to true if 200 received */
    response(response) {
      if (response != null && response.status === 200 && $window.sessionStorage.token && !AuthenticationService.isAuthenticated) {
        AuthenticationService.isAuthenticated = true;
      }
      return response || $q.when(response);
    },

    /* Revoke client authentication if 401 is received */
    responseError(rejection) {
      if (rejection != null && rejection.status === 401) {
        if ($window.sessionStorage.token) {
          delete $window.sessionStorage.token;
        }
        AuthenticationService.isAuthenticated = false;
        $injector.get('$state').transitionTo('index.login');
      }
      return $q.reject(rejection);
    },
  };
}
TokenInterceptor.$inject = ['$q', '$window', '$injector', 'AuthenticationService'];

export default angular.module('TokenInterceptor', [AuthenticationService]).factory('TokenInterceptor', TokenInterceptor).name;
