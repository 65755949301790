import * as angular from 'angular';
import ItemService from '../services/ItemService';

const ItemsController = function ($state, $stateParams, $translate, ItemService, userContext) {

  const vm = this;
  vm.translate = $translate.instant;
  vm.user = userContext.user;
  vm.search = '';
  vm.invId = $stateParams.inventoryId;
  vm.orgId = $stateParams.organizationId;

  ItemService.list($stateParams.organizationId, $stateParams.inventoryId).then((data) => {
    console.log('items', data);
    vm.items = data;
  });

  vm.removeItem = function (itemId) {
    ItemService.delete(itemId).then((ret) => {
      console.log('success', ret);
      $state.go($state.current, {}, { reload: true });
    }, (err) => {
      alert($translate.instant('COULD_NOT_REMOVE') + `${err}`);
    });
  };
}
ItemsController.$inject = ['$state', '$stateParams', '$translate', 'ItemService', 'userContext'];

export default angular.module('ItemsController', [ItemService]).controller('ItemsController', ItemsController).name;
