import * as angular from 'angular';
import UserService from '../services/UserService';

const SignupConfirmationController = function ($stateParams, $translate, UserService) {
  const vm = this;
  vm.isSuccess = false;
  vm.errors = {};
  UserService.confirmNewUsername($stateParams.token)
    .then((ret) => {
      vm.isSuccess = true;
      vm.showAlert = true;
      vm.alertClass = 'success';
      vm.alertMessage = $translate.instant('CONFIRMATION_SUCCESS');
      vm.errors = err.errors;
    }, (err) => {
      console.log(err);
      vm.isSuccess = false;
      vm.showAlert = true;
      vm.alertClass = 'danger';
      vm.alertMessage = $translate.instant('CONFIRMATION_FAILED');
      vm.errors = err.errors;
      Object.entries(err.errors).forEach(([key, error]) => {
        if (error.path) {
          $(`#${error.path}`).addClass('is-invalid');
        } else {
          $(`#${key}`).addClass('is-invalid');
        }
      });
    });
}
SignupConfirmationController.$inject = ['$stateParams', '$translate', 'UserService'];

// eslint-disable-next-line max-len
export default angular.module('SignupConfirmationController', [UserService]).controller('SignupConfirmationController', SignupConfirmationController).name;
