import * as angular from 'angular';
import FileService from '../services/FileService';

const DocumentController = function ($stateParams, $sce, user, FileService) {

  const vm = this;
  vm.user = user;
  vm.diverId = $stateParams.uid;
  vm.docId = $stateParams.docId;
  vm.resourceURL = '';
  vm.doc = {
    type: 'pdf',
    id: vm.docId,
  };

  vm.trustSrc = function (src) {
    return $sce.trustAsResourceUrl(src);
  };

  const promise = FileService.getFileUrl(vm.docId);
  promise.then((response) => {
    vm.resourceURL = response;

    // For loading binary file, not working yet!
    //   var blob = new Blob([response.Body.data], {
    //     type: 'application/pdf'
    // });
    //   var objectURL = URL.createObjectURL(blob);
    // console.log(objectURL, blob)
    // vm.testPdfURL = objectURL;
  }, (err) => {
    vm.serverResponse = 'Virhe dokumentin hakemisessa';
  });
}

DocumentController.$inject = ['$stateParams', '$sce', 'user', 'FileService'];

export default angular.module('DocumentController', [FileService]).controller('DocumentController', DocumentController).name;
