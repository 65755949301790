import * as angular from 'angular';
import UserService from '../services/UserService';

const LogoutController = function ($state, UserService) {

  UserService.logout()
    .then((res) => {
      $('#bg-div').empty();
      $('#bg-div').append('<img class="bg-image" src="images/ocean_top_black.jpg"/>');
      $state.go('index.login');
    })
    .catch((err) => {
      $state.reload();
    });
}
LogoutController.$inject = ['$state', 'UserService'];

export default angular.module('LogoutController', [UserService]).controller('LogoutController', LogoutController).name;
