import * as angular from 'angular';

const options = function ($window) {
  const options = {
    api: {},
    app: {}
  };

  if ($window.location.hostname === 'localhost') {
    options.api.base_url = 'http://localhost:3001';
    options.app.base_url = 'http://127.0.0.1:9001/';
  } else if ($window.location.hostname === 'divelogger.org') {
    options.app.base_url = 'https://divelogger.org/';
    options.api.base_url = 'https://s9lkl9y8nj.execute-api.eu-west-1.amazonaws.com/test';
  } else if ($window.location.hostname === 'pelastus20.com') {
    // options.api.base_url = "https://api.pelastus20.com";
    options.app.base_url = 'https://pelastus20.com/';
    options.api.base_url = 'https://rpl4e14v04.execute-api.eu-west-1.amazonaws.com/site';
  } else {
    throw new Error(`Unrecognized domain: ${$window.location.hostname}`);
  }

  options.user = {};
  options.user.defaultZoom = 12;
  options.tests = {};
  options.tests.deprecatedLimit = 31104000000; // 12 months
  return options;
}

options.$inject = ['$window']

export default angular.module('options', []).factory('options', options).name
