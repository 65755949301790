import * as angular from 'angular';
import MapService from '../services/MapService';
import RescueDiveService from '../services/RescueDiveService';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const NewRescueDiveController = function ($state, $scope, $stateParams, $translate, user, organization, GoogleMapApi, MapService, RescueDiveService) {
  
  const translate = $translate.instant;
  const vm = this;
  vm.user = user;
  vm.diverId = $stateParams.uid;
  vm.uiGmapGoogleMap = GoogleMapApi;
  vm.mapOptions = MapService.DEFAULT_OPTIONS;
  vm.calcConsumption = RescueDiveService.calcConsumption;
  vm.calcDivetime = RescueDiveService.calcDivetime;
  vm.searchBoxTpl = 'searchbox.tpl.html';
  vm.address = '';

  const { label1, label2 } = MapService.getLabels();
  MapService.createMarkerStyle(label1, document);
  MapService.createMarkerStyle(label2, document);

  const plotMap = function (pos) {
    GoogleMapApi.then((maps) => {
      vm.geocoder = new google.maps.Geocoder();
      vm.map = {
        searchOptions: {},
        searchEvents: {
          places_changed: function (searchBox) {
            const [place] = searchBox.getPlaces()
            vm.longitude = place.geometry.location.lng() 
            vm.latitude = place.geometry.location.lat() 
            vm.address = place.formatted_address;
            plotMap({ coords: {
              longitude: vm.longitude,
              latitude: vm.latitude
            }})
          }
        },
        mapTypeId: 'roadmap',
        center: {
          longitude: (pos && pos.coords.longitude) || organization.longitude,
          latitude: (pos && pos.coords.latitude) || organization.latitude,
        },
        zoom: organization.zoomLevel,
      };

      vm.marker = {
        id: 0,
        coords: {
          longitude: pos && pos.coords.longitude || organization.longitude,
          latitude: pos && pos.coords.latitude || organization.latitude,
        },
        title: translate('SITE'),
        options: { draggable: true },
        events: {
          dragend(marker, eventName, args) {
            vm.longitude = marker.getPosition().lng();
            vm.latitude = marker.getPosition().lat();
            const latlng = new google.maps.LatLng(vm.latitude, vm.longitude);
            vm.geocoder.geocode({
              'latLng': latlng
            }, function (results, status) {
              if (status === google.maps.GeocoderStatus.OK) {
                if (results[0]) {
                  vm.address = results[0].formatted_address;
                  $scope.$apply()
                } else {
                  console.log('No results found');
                }
              } else {
                console.error('Geocoder failed due to: ' + status);
              }
            });
            // marker.zIndex = 950;
            // marker.optimized = false;
            // $scope.$apply();
          },
        },
      };
    });
  }

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((pos) => {
      plotMap(pos);
    }, (err) => {
      console.log(err);
      plotMap();
    });
  } else {
    plotMap();
  }

  const volumes = RescueDiveService.getVolumes();
  vm.dive_apparatus = RescueDiveService.getApparatus();
  vm.errors = {};
  vm.updateVolume = () => {
    vm.fields.volume = volumes[vm.fields.apparatus];
  }

  vm.fields = {
    equipment: {},
    apparatus: '1x6',
    volume: volumes['1x6'],
    pressure_start: 300,
    time_start: dayjs().utc().second(0).millisecond(0).toDate(),
    time_end: dayjs().add(15, 'minute').utc().second(0).millisecond(0).toDate()
  };

  vm.types = RescueDiveService.getDiveTypes().map((v) => { return {name: translate(v), value: v }});
  vm.diveTypes = ['smoke', 'chemical'].map((v) => { return {name: translate(v.toUpperCase()), value: v }});
  vm.checked = true;
  vm.type = vm.types[0];
  vm.diveType = vm.diveTypes[0];

  vm.createDive = function () {
    const data = vm.fields;
    vm.diveShowAlert = false;
    vm.diveAlertClass = null;
    vm.diveAlertMessage = null;
    data.type = vm.type.value;
    data.longitude = vm.longitude;
    data.latitude = vm.latitude;
    data.address = vm.address;
    data.dive_type = vm.diveType.value;
    data.time_start = dayjs(data.time_start).valueOf();
    data.time_end = dayjs(data.time_end).valueOf();
    data.length_s = (data.time_end - data.time_start) / 1000;
    data.user_id = vm.diverId;
    data.volume = volumes[data.apparatus];
    data.pressure_end = parseFloat(data.pressure_end, 10);
    
    RescueDiveService.add(data).then(() => {
      $state.go('index.users.organization.dives.list', { uid: vm.diverId, diveType: 'rescue' });
    }, (err) => {
      vm.diveShowAlert = true;
      vm.diveAlertClass = 'danger';
      vm.diveAlertMessage = translate('COULD_NOT_ADD_DIVE');
      vm.errors = err.errors;
      Object.entries(err.errors).forEach(([key, error]) => {
        if (error.path) {
          $(`#dive-${error.path}`).addClass('is-invalid');
        } else {
          $(`#dive-${key}`).addClass('is-invalid');
        }
      });
    });
  };
}

// eslint-disable-next-line max-len
NewRescueDiveController.$inject = ['$state', '$scope', '$stateParams', '$translate', 'user', 'organization', 'uiGmapGoogleMapApi', 'MapService', 'RescueDiveService'];

// eslint-disable-next-line max-len
export default angular.module('NewRescueDiveController', [MapService, RescueDiveService]).controller('NewRescueDiveController', NewRescueDiveController).name;
