import * as angular from 'angular';
import DiveService from './DiveService';
import options from './options';

const OrganizationService = function ($q, $http, $translate, options, DiveService) {

  const os = {};
  os.translate = $translate.instant;

  os.sendFeedback = function (feedback, organizationId) {
    const d = $q.defer();
    $http({
      url: `${options.api.base_url}/organizations/${organizationId}/feedback`,
      method: 'POST',
      data: JSON.stringify({ feedback }),
      headers: { 'Content-Type': 'application/json' },
    })
      .then((results) => {
        console.log(results);
        d.resolve(results.data);
      }, (err) => {
        console.log(err);
        d.reject(err.data);
      });

    return d.promise;
  };

  os.getLogo = function () {
    const d = $q.defer();
    const logoPng = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPoAAAD6CAYAAACI7Fo9AAAABGdBTUEAALGPC/xhBQAAACBjSFJN
    AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAACA
    AElEQVR42uxdd3RU1db/7XPuTHpCIJkBRAwIooINkCSABStg12fD7rM+e8fy7AV7ee/Zy7N3scIT
    VERKJgh2sCGETiaBhIRkkpl79v7+mAkkM3cmgxQJ3+y1WIuVe+eWc89v90JI0f8LkpmFe4lSdwhk
    OAAQaAaJ+SeVVn+TWp1tnyi1BNs+hcq67q+IJwJIjzrUxKJGuUpXfplapRTQU9SRJfns7pls2/MA
    7OB8AharTNqV9qhsSK3WtksqtQTbNtnGlMQFeZjV97QDMjS1Uimgp6gDkyUcbPccJc2plUoBPUUd
    W3kPtH+ODqTWKQX0FHVonLsakzirMbVQKaCnaFuX6CQpiZ4Ceoo6NLG0L62FUxJ9GycrtQTbiNwu
    L9iJoU6GoJsQZupG/2s0AjbEBNrl58oOAIC8BW16esYQMAyEFUrxG7R39a+p1e34lIqjd3SAT4HF
    GZ5xAC5rzbhJ5GNaUnU0CkGc4QklxHlOYRp2nWtLuWe8AEe2OmRAeFRp/1gajFBqtVNAT9FfAfK3
    oKWn9wOBHOYIYHIVUPGyVcbnCQJwxbmM0SV+S2bn57HtqnXeJDSBApVH0QjYqVVP2egp2tLmd8/C
    B+KBPEx2buQ/iWzwyLGMvLgMBTKaM7wPplY8BfQU/TWf74x2ZH4L0BN51cPHmHPaudZZqfVOAT1F
    f4nhJRVJAr19iW5zXjt3W5ha8BTQU/RX4JyREOgmKYkejqHb6891vhdQkVrxFNBT9BeQqHakrOgc
    ABBJFEunAACQRkLVXSApiZ4Ceor+EuLEqjsBYSlNFIgP4Ba1XuW2s1VSEj0F9BT9JRK9XfCF1XGV
    QHVvObaOKcS7Urv+gBSlgJ6izULaatdBlhPhCEmE1xLb6DrljEsBPUV/EbnbA18YvJJAoktL0Ysk
    ttGhQymJngJ6iv4KirR/qk7wecNSWiVyxqkWJpBAolMNDa5Zk1rxjkupopaOZJPPLNiPtT4ODL9y
    6Ydo8PJGAVUQpMARnuvUcRUAJN5VW6nucTKiI/a5zOyRwRS6EgpeZcy7NLR6auqrpICeok0FcAGx
    z/sYk1wMEYAANsYD4FIFLBRgcJyfJpMwE3HGUW48VkASNhFYNd8N0OUQgJW6xPg8/1HF/kuI4nKR
    FKVU9xQlS1xeeDtILo5C/5kRGzuu7czrgJ6wsURj23MdGA21JMvEpNxexL7CO1NfKAX0FG2sNC8v
    2AmgsQ6HcmRWYVckyFhrFTJrNzMuYXiNpUJmdy8AJD/2JnStfF3QL/WlUkBP0cZIc6Fj45pYgr4i
    CTPWciMXSQD09p1xotRCGLtvPPOPbX186kulgJ6ijZHogD/eMSPoo2FVtAd0Uaoxgf0fOUZxga4V
    KgzQN8EzVqa+VAroKdoI0kRz4qvmqi9EVyC+Sz1bBCqh6r6uMaTEj6M3m0UkEhfoGmZO6kulgJ6i
    jaFG/9x4QCWgDw1dGkB8iapQ3jl7fQjNkQLyvTcrrnlAqKLh1fUA+sT5fRA1neamPlQK6CnaCKIR
    sAX40VllDktZSVxCmpsoMw4ijQiZ+Pb5usIZFUei0480en5q0ksK6CnaaLAT4qnGfUVAlDAP3Z1r
    JZDoAh2AxHfEkVKRa0sciS4ptT0F9BRtChLEtdOzMKegK0AVCSR2TqI4uiVoBCfIc2epkBleD4A4
    HWgoBfQU0FO0KUgTxwcTU18kUN1tklyQlTCObkMnqlyrgIrviFMpR1wK6CnaVF+pai6AJqdDRlRf
    TpAdR0AuEnWYEWmkBCWqAqkwiAv0IFZ3+in1gbZ+2ipz3WV2QTe26QyAekFQK4q/soqrP2n3d3P7
    u7G2ahebqdDSXA3lWkqDl1d3eBt9MEK2Dz8QMCTmmKCPhnzFcVv0Sy5IApA4x4UDibrLaOGFrNUw
    5wDetuOIk/LtugChHjZToaWkCtmFP1P/ue2OnLZ9haNJaD8QOgGyUNnyEg2vXp4CejsUKvMexLa8
    s84mJIBEXWvKC+/SxVU3xWcM6m6urzoRQIYiATMBbDeJr2A4lVR3OPVSZhXuy0K7KNAkKq5cSIQ5
    EAegQ/oix7MI9VXspKERVC441Bj3U0soQHDHq1wTuNMWw5i+zqH6sCNOyroWseJDleFfOmJFm8wq
    3JM5NHPd3hEA9VUBU+Z5S7n4ehpcvcJRmyrz3ArglvVLR2CLxoZ8nhNcJf5JKdU93oJPL+iuqBXI
    2xykG02Z58qYP08pzGZbTQdwJoCMqMPpDPWOzOzRuSNtPOPz/IeZpkLwJIt8J7MLusVzyAnQNyJ5
    VsSV6Nbq+DZ6fWFjAoleSYOXNyKu6k5zZIbXw8TfQfAkK/WlKfM80aFA/m2nTiz0jsPeyQDhDLbV
    DJlekBML8sJLQbjF4ZJ5Cnhbphd0TwE9nl/JotMR17sLgPCAXVZ4ZpvfZNKpAHonuGyRqOArkQyx
    rZ5CPs/BAP7R6k+5xqZhCRxyfURAEj/ElhOZm+Y0Tokxan4wXneZddcU52QZBTPHWDK0zTcjXBAq
    9xzaIUAuIAm6XoJgxwSn9Yrsy1bquuc0ED2S4De5bNEZKaDHR3J7VVBERM/a5Z5jWkn6nu1+UGAU
    l3tu6gibTwHHxfI3KkngkMtEWZfuCfquJ6pgC4RryZ2dcQRURCrknBhBEKs7/USM0ph3EBzbEdaa
    fd4bROiI9jnC+j1ml3mPJOB5tDe3UKhfCujxqSqJczQBr4dmeQ8MvwC9AmB1Er+7xS4rHBXF0ZUp
    K7zULiucZXyeauPzrGSf9xPb5znuL2N1QNfYPYNSGoyQiDhmyNnk6ov4Ej1R84nElWsiCyFxi1l+
    otHzm4Vige70DluK7DLPsezzfmJ8npXG56kOf1vvZdEaXajcewBIbkviknVKy+th/1HX/YnkzaR8
    W4TqFNDjMkFOzpEjSFMs42Vm1yFUsnKeEhqNxF1UAEAR0StS7u21nqMXXgOiR4lobwBdAHgFMpqA
    d2xf4dl/yRqAYpgWCQbK3P5uimOnE6RvvAYUtL4qLeBws8YIMHOdn0VVmPhAnyNTYBEwyEGD+kv6
    y9nlntOJ8K5ARgPwAugS/rbyCJcXXrve+dZ5ewV5A4Bu55JBFjqOhlR9J76CQYr4AwDpyZkF9FUK
    6HHIKq7+BCJjkzw9hxVPEF/XXam0slyETopjh7amzizynszskRGxAwbHZ8j0kPg699hKmF061qza
    U+IDvY+Qc493TjR/jcLg5ziqu5BUEFSfOAs0BxkFewDIjAW6fLnF1216QXeS+HYzgfYGAJkNl2Hr
    dQgK29PsheRUV2nlZ+Ir7MtQn6Cd3vet7vaYLqn8KAX0RHp5adW9ILkEACdxehcGT5Zyby+rtPIj
    gC5K4jd7MgUfinzJRCGQPCHrGZEtO0NeEz6FQyyLyZRo4ThAR18rjuq+Xlo7TGuhFonuXItusSyk
    OB53BZ7DoBLHd1Bm8hYHuqanHDvgrDfTPgMAtj0PEzAsCbBeaRVXvS2+zj0YNDmiISShkOE6XVJ5
    2dbWR2+r9ETr4qp/C3BGEhIaALozZLLMLuimSyqfBqT9HmaEC2xf4Vm6pPI5AiYn2BwjzSzvFlXh
    aUjVSgCx2WZEJXBX/eTkkBOgLxorl8RZr0hv99jsOElsozNq85aIc8OJEBqzf4Iz0OfRkNVLtqxd
    XnimEB0eX0uiqWqJ/2m73HsygCSEAd2jSyofla+6FjJckwHskMRjGBE6Xxf779saMbXVhpysEv8r
    Em6j1JQEH92RbfWpTMvLV8VVN0PkufaxTo/DV7AnGToVcWPQAIk8JLO799zCr++kaYQdciAHJoAd
    sT8YwLJ4QHcay6TWT3BxAvpyjJofBBxCT4SfaERFExwccYBs0UQRmV7QnUg9lOAUvw6ZMejl3YVE
    nkliL72miitvFF/nXHbLREB2TuIxgiIyxiqtfGZrxdNWHVu2Sis/YlGjANQlcfpuxpU2AT94M9WS
    qvMJeL+d89OZ1JvIbA6yqDEATJzzcsW2n4tW4e0y7xHG573HlBVeJzM8O27K92Zy1DKKZFZhV3Iu
    C83A1523izPxNL4zbl13mVigC7AQMwq6Ach2QMMcmd29ABKbvyAJNKQ/BeQZnh1NWeF1xue9xy7z
    xoTCxFL/SaCyMwOnAahnw28ByErI/AWfq9zCs1DWI92Q60NABiXxiA3McpRVWvXW1oylrT6JxFW6
    8ktFfCCo/dAbASUSkPeR3ccido8Rwsx2ftJHmlwvWyUrp0JwZ3wmj4Naq/Cm3HMtkXwIyFgQjWML
    c02Z58JNxuAaM6c6AdMIlYg4O+RscfUl53JVl8zskSEOzjiBCshsuODgSSagAgpxM+JMyJQiNpYc
    1AFsMm+zKfdcwBo/gWgcIGOJ5ENTVnhdG5UdODrBJe6yiv2TxVLPA7RLO8b1bGqSoxGYK0LNb5HI
    fkmo+DWKcYhraNX/tnYcbZVAlymF2cbnPc/4vPfIrK79qbh6tmK9H4Cl7WteOEg6172GpUuDGq4j
    Afol4flEh3N54XWqxH97Inu9jQrPOC3qpmkg/McuKzxhk9jpIyqaCJjuYG6UxOvPpiB9QXGSZiSU
    4+iME2mE2S6eJ7nCkHZ2xJGaQ+ASB+YwnUZUrd0kdrfPcxwEj8cwIcIZyajsAnylFvtv41meqwX4
    WzsS4g8NORz7VzWw7X0qkb3filYqkhE01D9TyrrtYnzee4zPe55MKcxOAT0ZkM8u6MYZ9D0gTwEy
    llmmSXnhPlS64mdl9HAAvyUB9mN5e8+/qXjZKkUYncgGj3zpu+xyz0EUVvNWxFXhQ/bzYRVePnS6
    CBE9Jr7OuZtkHRzUdwFKkOv9CYRmh5fuE3eYA3Gu40RVQgCw48TQpYKUY+prCA3pPxLFOuIEsknU
    dpnZI4MED8Ep+4zUhxGV/fG4KjuhSrMZgx6eYgjuagfkVQo8mkr8lezzPAjIWUk8YoVi2YeK/d/L
    TM9QJjMdkLGAPMUZ9IPMLuiWAnp79mlI3YM2ueuSz6DJdlnhCTRsxSIF7Avgu/bFIi4wZYV3UXHl
    QsV0KIDaROuggJcB25XIXhfCgcbn/buqybsdkJ8dTvEy9DV/eoP7PLvb5d6/Rz7MJAetYm/457II
    /eggTftqipMdR5FS1VhkNsZLfxXohY6dXwk/oaoi5DQGSknYEWf7Cs+Wcs8ef95HEbwOBCcH6EKV
    jjtsX+FZAhyV0C5X2maFtwG4EtyqXjGPpOLq34zPczsIVyTxePMUm+E0tGq+XeY9khUmA2hdNNWL
    bTUuBfREG31ufzcIJzjsujQies2UeS6kEn+lSguOEAfV1kHG3mDKPdfQ0MofWdQxjpJwPXkMrLet
    3C4zgfhSgEgehKfRqxQugGPtJl0tZV2LNtge9XkvY+BbEnlCpuXlY4j/BwftIhPpBbs7OeQE6Avl
    cpToNixniQ4JxAO6RVLhHFqTOdjBM8DBgVeNkurvxNc5l4geZ8E3ptx7xQbvgZldtgPhaqdDLHQe
    6k0eQT2YwKN2j9Xo/9wAbwFIVEEWYsLxVFr9jfEVXgTgn+0+m8jXyrL2o6Grlhmf9zwieQ8OCUMA
    TpAJfdJSQI/rv/TvithywRbSIDxufIV3YM/aNdqyDiVgYvs7B/faZd5zXKUrvxSmM5AgEYeAEq6v
    vl8V+28jhBMs4qnw2LtqGoBnHY6nM5l7Nmhzf+0dAMhDke/hMq60wyMJF5/FSjvl7JAj7IgFy1cC
    CMYeklzHvnGkAsYZ6DayK5fBsSqQ5jBQ4rDOk4jAhlyHQZAWdhvIAzLTu9sGSXOt74Wjd5xecJVW
    fiaWfiaeyi7ANNVYdSune+6jsOYXH7Mk57qK/Z/aPs+pAD3WvoIoU7SRA2nw8upwHbo8hfgptOnI
    r+2fAno8B4yoJOrG6SYu9z6L+uVBCviPhODFduU6yZN2eeHxVmnlmwAuaQd2l5pZnlMJiBtfX6fC
    W6Fr4Bi7ppOkvHCfpDc3y3Gtv4USHBPZuE42r7NDTpCG7bp1A2GJo+oep3oNzv3ilqK+s8cJcIrU
    HAji2uctz95yOpMkXSAkZd5iCMY4HKpWlr4urLLL6AR2+ckm03M4CJe3g9rrrOKqFyOVaC+0hwMC
    PqBA1mgsr240Ps/TcerQ2+5lUl1SQI9DluGaJLfE2ZLhmQBXQYYq8Z8FyCPt/ECT0CuhmYUjdYn/
    cQAPJvywgsehVAFDnRLPXieSB4GMPAEucwSv0CMtFVPhKILnavZ53zI+z5NS5hkWhZLuUYxkpHzv
    zdJhOz3aPIjrkLMV9yWO9bwTkCtwGsvk3C9OIBU23H3jOeKcMuI0mc9kSlG6QEZG3bztu5V5hpky
    zxORtbi6pamDCMiQPOjkgBORi9AUdCdQ2UWY/g5NWRRm/InSlh/Xxf77Q76uI5KsRHuJAv6/IWCJ
    9PS8AeDcpPYyeHUK6PHI7VqCZDLhwrv/YGOpyZjTvYsuqboiiWIYt1L0rpR5hqli/zXtaAJZzPye
    heAcQO5JpMLrYv97JBjvcHygmeU5FQBMOh0C4H6BHA/gfCZMM+Wtxg0TRTO4DNMoh1KJvxLAD1HH
    +mDVqjxIbIacgvQV5ZQ046y6R1Jgc2MZg1qonHPc56JTbTogO0Vd6CcqWb3UpAcOQWzt+pr1fojC
    O5gwDYQLImtxv9E0KnzMM8YpB51AE6zSqrfEUs/GT4yhcToTX7Co95Cw8ETeVMX+S2RW4Z4K/B7a
    rUSjx1Sx/0xkbpdnOtdNaTdMt/6hmwG1NAX0eOszeHm1YhkKYEFy64litu1ymVnYR5dW3QuRS5G4
    GCaTCR9jlmd35fKfS8CnCc7dSWC9pIqrbiXB5wlU+HPIxRfBwatPgrvle2+WLvGPB+HbtiYf3Wh8
    nn+0OHliNYZ1KnC0951MmhQ7Dk5Q6AN2qGILd5BxjqM7dpeRCseBDSJzTJOrNGbfqPAzEskxTg4s
    IJz8AtBNUdL2B11S9Y7M7JFB5OgArScVusD2FZ4twKg4DN+nrMpbOCBPAOgfXzDIl2p13hkoL9yR
    mf4HoFNCOSK4TZdUXoaZ3Xqy2NMJTum+jrRUCe0fYdIpoMcF79Cqb5UVGphECmsL9WZF06SsYKAu
    rfqXhHvHJSqG6cSCCTDeHmTz8QC+SaA1HMU+z2VEOAXAyjgq/AOA2wXBDQ6Ht+OAXEsEEdDNDsfv
    lWl5+Tqn8COHzL8jZG5/t1OFHQkXOw1OiF+XrnLFqeUzOXvdhVBBjh53mkOg4hgzBZgkU2ABiE40
    qdWS9pHMzs+D4D6H+9xCBGYdvBZOhSMkNyCoDEE9EOcTrdZGn8S29x9AVBJTlMah00LHoPPqwiQq
    0QxAF+hS/63ytXcAazM9yXx3EGSKAgZTSaVva8PVVpkZR4Nr1lCx/9iIOm6S+ElXJjU1NLNwpFXi
    f1mEjmvHBOjOkMlIR7YKqpEAfk/w9e6Fkn5MFM9ez5WQ/bwq8T8dJ+R3jczotoNVXPmxAGVRx7LZ
    nXYO9Z8bBOOVqGN59tqqEVZTxnREpa+SUKnT4AQR1Vc7tZQiybWcVHexHBtD6jihNSVqDsUWsjRZ
    2ppmp3v3B1AQdeOXaOjSANuuc2JVepqjh/g/kJldtoPgGgcmW64WVT2RQGUXEToLmrsBcl9CCavt
    0ahPdyVRiRYUkTG6pPLpUFnX/dnIdADJ9CQQEN1Li6sO3tok+VYN9IjqKrq06l6GOhjJzd/OVoo+
    sMu9Y6zSyg/bLYaJVLzBsowyGJXgHhYzvWWFzC8QuTeBCn+21nyOA4PJYGXuDu9MB6nOuFimwFJi
    no4FFo6hERVNRG3zx4UwBJw+L9YhJ70RtBc7OeNgWY2xDiMJOA5vsEKLEBtas+FWP0mkgUOra0+j
    wcsblYParhS/IG9Bo22zy/Brk9xIBGGl70Gsdz+olfq76Vl4RjyVHUT3aYaPIe8AcMf5dqsUm4PB
    WMNu/l87krmxpTjFLvcco4gnIlGj0lY+CCEcp4srx9IJSQmlFNCdyFWycoqCPViAZNQhN4m8YsoK
    r1tXDIOEvbt2Myo4AUGpVMSHA4iXp+01LvW2aqq6g4Av4qrw5A4AuMdBKzhZfIXDXaWVnxFkStSx
    niaz8BgauuoXB4l/tLwFLRwTZssBQn0giB5X7Eamy+XAbJwz48J/y43xrIfSFGLzGebC5qJo6SqE
    yZHKviOjJPIsGlL1nenpOTqaaQgww1Xs/1TKCgYCOMVhOcchaNfEU9kFKFedK28RLa8A2C7ONwso
    wVFAxiID6yMAAxPsg1ol4eIUU1Z4CQneQTItowjfKk0DrWL/+K0dRx2iBTKVrF6qV+fun0xiQ9hR
    S+OMz/sohlR/o0TviwTFMAQUSwaNx6pOP4rICfHsexIM5QzPHaQknr2eK7b9guqSey8QA0AyoAdE
    QCT0TweVIBKik+gEHC96FpYqK9ZOZ4VSIHbKqm2kD4DFbZUGyoVTKymRRo4GOmGxTc6JMoak1GED
    TcIsT0mMiivU8i6XOZgYN4XDaepRhz34qwpk3hNfZacabfSJvNp7mwAHx7OzhXAKXP5ZokJvt5M8
    s1IR9keJf6Yp89wKoseSxMXLSlvDae/KBR0BQx1m9hqNnt+sSyovk7DTpSEJs+lSKfe8haa0hZFi
    mN8TON0Okvy61/WSqkkiOBOI2wboKsMYzixnwcG7L8ABvKr+DCX092h7noBiU+45hUr9M4jwv6hj
    w2Rm1yE6Q70ZbW4w0TG0d+VPDsyqGA7jlBXQJ3qMMkFyQcYhYcbEqO4kWKjEaUSyzCGRaEdcJYb4
    f+C2STIA0KAp9KaUFQwkYJ+od53sKl35pZnlPYmA4bH8S84xmYFT4qjsIsBZRvHuELk2wbe/TA/x
    v8+252mBHJZgk1QoyL5o9M9lX+EzySTCAGgSofN0if/08HCLjkFbPdAjLZmvY5/nHbvMe64u9r+m
    RO8dp6gkGnjHSUbjJ0hrqlXhDfdd3HMJx3BPz7O6xP8aiG6K7zqgFyyyFgF0b5wrPQQLVRB6wkF7
    GCffe7NI+KZoZsKaL6U9KhsAvBH1EsdGVOOodFguVXDqISd9JdYhlwttxwKdMxsR1S9OSCqcWjwr
    oTmQGEfcpEiq7lFRH+0NKlldx6Qud1C3bg5Xp4mTifMUbFlAgvvjfND7NfNcInkJ8ZNibtYlVf/h
    8sKHEI7AxKN5CvY+yFDLJcPzIYj+noTLbbFSal9dUvmcXe79O/s87xhf4diOMBxkq39A9hVeDKJx
    AhxHJE9LuedTkFmtYEoIeDcJsB/Atmsa2FiRYpgZCU4/k8u9d+niyrsBeTSe04/JjFehpvsFmOZw
    PEuMPKNy+XrENmzcjhvlGiqpnkMSFT4UnCC+zj0Uq+g2WEUoK9xTiKLsdNoZopYgKredoPoiNsSW
    C/d28fq650bJVKfQmg3GQhB2iVrbyeLz7A5gp7ZOOHpOZng90QVKBHxIJZU+VsGrHbzfK5Q7eEM8
    lV2AWYpy72JNbyF+DPxpXeK/w/gK7wDo8vjCI1ycAsoImIBMjuvwa8ugPlF2854IySIp90wkkWcF
    OA6ge7jce0kK6Bsr0cM5523UbAa+s8k1mIr9x0c+aKidy+zGSk+HbXm1ZR2SuBhGrjflnmtVcdWV
    BLwT56R+7HI/rmGPcXL2CXAA16sxInKRw465VmZ370la/TNK/Xcx6Qto6MpZ0ZoHKzpGaz0p6nyy
    CXsiqpGkQPqKqGgGkwn/XI7yPwhKlwajPd6isFBis+Lm2gq7o20Rh2iLP+OYqSz0CxX7y1jLxZHi
    lvXKgpJbxOfxAg7hNMI/TDDtOGfQUY0WdSKj7lEI9nL2ocjHKuC/yJQXXhxJzIkD2HBxCozJYQnN
    TCIRJhw6K648Etq1O2v5VoBDos45LQX0jbXNiZz6sXVVIpN4ludmtbjy30rJQXEcZG0kIxs1E0F7
    T8opPBqQNxN82nHG5/075RSeEi8rDqCTGPooETkdjtl48pC21K8O98lg276LhqycC+DNqN1+oczu
    ngmR56N+c2xk/PN3UR+vxMEh10uLxBa2WHk5aOuNb0J556zoPaCFFwPUK9oRp2Ir1n6gwdUrQDH2
    +VOREs3zoj7k2zSk6jsW3IuomDoB72hjvo6jsosAZ7HikfFUcQHKKVOdZDI8J0Po0QSO13Bxigv9
    WMQXrYk4UDUDI9WQyhvY572BlfoczqWvvVNA33iKl/iiIbhVenomAfhNsRmcRI+4zqwwyaytPFgV
    V40B8FR8/iJPmDr/0UT2sQC+j3PawxpUB6L746rwbn2Jg9Q/Rco8wxTkligp29mEzCkqPfRylJe8
    v3xd0A9o630noMTBIecCVGzkQGVGe94b4ZQXzjqImNCSzCGKAfqkyNSb3Vv9LaiC9KrpUn8q2maf
    GcXqVplZuBcoRvqtITaXi4pbfvqgVrQcwCPxbG3N7tGmEQdGZqLF29MvUcD/NzsrMIxFfQ7A046I
    maNE7W0B30u5538guQNxy1KlOQX0jZXooO/atcGZvrMta2fd6N8PFM9Jth6AJOoD4/P+XZf4L0hQ
    DKOJ6GWb9VBl82gAixzOcTHhDdVsHnXKihPgAA7xsQK5Lvq1DOEBFFfNB+jlthxGLsOetWtAbQtl
    2NAx0d1hRVCilPk2+r625nxERyaIo0tVA4A7GuhNtuK82E1C3wjaetwZmMyQqCIPeY/2XVlFItE2
    66tUuuJnoygmnCZC1xiyRjmp7ALMUirzQWZ+M8oMaKHlyujRtrL3SFyJFi5OMRmekxTLRLQ/ceVp
    lVMw1Cb0YuDbBGG8Fg/tdymgb6wzDvxCEqd5FctkzvTeqRZV3ihhdTLR/C9NJE+ZMs+tkWKYy+Ac
    UnMrRe/Cot4KajSchzn2EDe9qk3wVCd7HYIHNeOr6EYWBJSYWd6TlVG3RWW49bfLPQextHXKCegY
    K7tgOtom9XSCyWiKdshFKs8WRRmxuVENIp3aSC1SsSOSTSSxpnV6a5PF7ukSFVZjUc9Fhl+2biMV
    Uga322XeE6NDbQJ8pSk0kUjud7TLLYwRaXwBQC+Hda9ThNFwiUchwUw0ont1SeVlXO69lIAXkbi1
    VJNA/q6K/Rdwnf8KBZ4MoN3+b8zyQgroG0lWSdU7QnIC2p+YShC5zuzg/VyHeJbSXIzYxJUoZQG3
    GJ/3OdVU9bhQ3GKYTAZ9DGXc8YY5CmgEW65zI/a6xKjwip4iTedHS1kSuRfZXA3Bc21tErrMKl75
    JVrF/gnYGw0rvIS2w/sMmgeBot5TVF8CLWx7nuQC0mgYMGGPQsCOjqGHe87FOOJMW+CCgKmwmvMI
    aC3lF1ollV9o4egEmecRzFxGJOOiVr9Zi75AyHrGwYsuQnI22zhdBCMdvkmQFR0LcICZP4bzWGcD
    wgVqSOX1xue5P9KzINF+/10xDdFB/ZGUeyaGW0y3O4SxDqDzdUnV21u/ZtxBSGYXdBNbPSlRqZZx
    3qqKjZxuNWM6Z6rnEM54S7QI7xO7xxgVOoTCUzadpINfEe9jWPeL9AqzYjcnjqNwrPkah5tcAJZO
    kQ3Umm5RFj/DtpqP9f3HRLHZlZU6BqC7W93hUpDSgDwsAqxcxZjza2h8xXKTDdDBy/wGy6oYP863
    a1bVsaS5qHMwJGgICOobJWCbtmmtlkZTTialZ2UQ3C5CMCSrO+cqDNjR6rydR6GHR4NAn2/vpdq9
    d3Ud17WLAoV3zNURM+A/rRyJ/1TCb7CiX9cBitCsyO7LxnU6KGpUFtGNwvATOUxPITzEgv+pcHQk
    GmwsJCdpMj421vQ4TSSDInS6zi0Yz/X+FwE6KbHqLR+RO3S63ZS+pyJ+LRkpTsAksqxzafDyxR0B
    Px0G6Ovsz/LC40noSbTtvBnHfKd/Kavyara9ZwHy70RqmwA+Ta7DbTG7R1RBJymxQFk8nG19RKRn
    WDTVKuJiI+p5hyYKDYp4IIt6HW3zrgPKsnZmY18GwZWt/v4fpeROZlpc1yCu35cYTP8u+PNDrwXm
    9NtBn/rdbzZWreEtuvZuF6FPD41Vdeajc47K7LffXq6ddu9robCTMkrZvVhc16CNfS6PKtA9HG7R
    3Vp7+FG5g0dw0P1dtDQXka+1K/1ktpt9iKmGAwC6XAXpNXbzNAD9HB6zUUT+ppswTTLoXYdQWJSi
    gztVif8O9hVeDaK7kpTi16jiyme2tkGK2xTQN1S6C/CVtvlkWNSbQW8icWfQecqyRoHZy8wTHDea
    4Ccl7v1YBS+Hc+fQHxTkbwyaGf17Ar4gJVcx06wopvOSsqyr2LYXrK7jHN+PNmb8EAw9/Hrgi7xs
    2se/mjO35u/h7awCNXU89YpTMkcM392dVjzAQpc8FVA292FL3xnVK52Vwj4i+KeDWl6r2Co1yrxM
    kNiR1iJ3KzL3MqwpcC5SqVWCw+HiBWyrCQD2TKj1MY2xGD+IlpfbYQgt328yWdY5HUWKd3ig/wnp
    Xi0ip+uQni0ufl0IByY4d7kijAbrIJOZBId6ZAHKtc0Hs6aHHVMnCa+KyGsE+jhmjQkXIuzwugoA
    FiwzmPF9SO54ruGtoC37Lavirszo8NQlT9WmuWTGNadmjR62p5v22smCCnfQe0REzSOSpx1NH8ho
    CJ3jtKZKW+cZ254Yp0ilUikZCWM1M5mJSFB3LsB0bfOJtmX1U+BXk1DVO6QU32aAvoHSPazKc+VY
    Js91INwc//2pRik+GkYvZuJJDg4qkOBzqraOlAL7FYlNGoEInUck/VoADQBr1grKfwo2Xfbg2ue2
    86izvvnVzlyzVvD/gfKyCXv1s5oWreBn/nVNzllDd3Nl52W3Xn55REBzCHjZca0z6SgJ4M04RSoV
    CnIIRHVmJR9BUJjgUZ5W7L48MiTipvZU9Y4sxbcpoG+odBfgK81mjCFrEJG8iHh504RmYTldE03l
    cG+5PRydeDafbiw1MdomD9lovvmJuvPTM/QtC5aZXt/8YuOXRTa2BWm9qahbgcLAnV3o00Mtr1pN
    dz51Q9YDmemU2fZ70WyN0IEM/ZSzU01+VjCHGHHtRSRvwHmgAgCsFaFzNGNKkqp6I0A3qOLKxzqq
    FN9mgG7KPddCcDpI3leNWXciZ22+GHpKhI5ISpUnzGfQuwB2i+usAS5VacHXTLP742gwrw0I5s43
    4y+4d81rY0Zl/Hu5n72/LbYxf4nBwuWmJYyVoiRJK6BXd40+22vs1NNCD6+qfuPTwEUPX5Fz2J79
    XKdnZxBimIClR5lQ6HAiegbxWzf/qpQ6zmZ4klHVBZimWc5Gbd4S7lx3I4BjIfKyLq26NwX0LS3B
    y7xHhEcXr6PfFPN5NLR6ali6q6cSzM1ep8oPOmPVW6tq+aM+21v5edkEK6LI5WYp6EjUdd5Ce+7X
    c0O/7dHX2teyqMvKVYyVqxiBZkmhcwtSRhqhaxeFrl0UDEvNd7/aU/fYybXDnjtZewHh/IC6hjB3
    NQaoXStYuMzUeTvT0TOf7bxfEqp6I0RuVyVV96O8cChDPdO6/ZQIHWWVVn6YAvqWleYPRoWjIuDF
    syoteC2MlZGMdJ+30P5+t5NX75GC0bZL37zcecYefa1hSUlxt13FIdc4EM6PwYfgYV3qv7IjroHq
    sF9PpDoO4zqXm93zjK2GquKqI8NZdRR3AkzPrjoF8m2cenXXw9qR4mN1sX9/o2kA2655IFzgLARp
    VUddgw4LdGXorQTTUbsR8A77POO14ZnKMv2J5COnE7MzCJnplELDNkrpbkJuFsWV4oplD+WSl6Tc
    81Zk4k73OLpvsxJ+MwX0LW1zDPP/AcYViN/fDQIczUrPZaOOoiFVR8WT7p58lULENkreziqRFB9h
    SI1gW80Ld4tJoNmLXEVDq+angP4XkC71PyEiJwFYnuC0PAieMOWer7ThH52kewro2y55ooC+Toor
    GW/KPV9EEnc6JbjECgGN0SVV/+nI69Dhd7hVWvWWsqy+kbrytXE1AGA4K/0DG30Zrco7vrV093RO
    Af3/AdDDUjzHfxATjmNR37fTBjoI0GMK9s5WSeUbHX0dtokdToOXN+rSqnsV7F0QzqyKp867IHId
    59fN1qClyjYDSORjbwro27TqLsB0xbKHEkzieu/MSAVh3AENJPKx0rSLLqm8jEpW120L67BN7XAq
    Wb1Ul/hPV0oVJ2wrRRjAghlsqVuIzCmLV5rxKUhsm1Rbx5N1joxirc5hRV8DMijB6d8oJfup0qoj
    OspghqQ1323x49KQlV+LYLiZVfg3ErofzgUOBOA8hnV4QSdybBvdOVdhbUAQDHXMxBiXBZwyMh3H
    H5iOfjtouF2EVWsYv1TYmDXXxoSZzfh1kenw39vtIuRkknPZrsIPXE8/AZJouOJygG5Tiyuf25rn
    p20UJrZ1ji6zu2dyKHRJZChDttM5U78N4YALY0PtuxRZ+N9jnfDfjwN46ZMm/LGs4+yBLnkKnzyc
    h736udZl+znRtO9CeOCVRkyY2dzh8vB7b6dxxmHpOOOwdBx2xRrMXRDbIOiLJ/Kx315x2xAEQPSY
    ktDd24qK/v8O6DKlMNuk0yG6xD+eCCIzu2zHSt8M4Jxok2XRSoPeRzvnQnz/amcM2NGCCDDjhxAm
    zGjG/8qC+GG+DdnKBP3ufSzstEMY1Rcfn4l99nThhY+aUF3L6NVdo3YtIzOdcODe7piw02+LDZ79
    IIAf/7Ax9w8by6q2PtQThd9xZKkbo4elYdjuLhABcxfY2H2Mc6exBe93wQ5dtaMdTkpdSsWVC0VA
    ZpbnaB3iz2h4dX0K6B2IjM9zNYD7QfhWQDdbxZUfA4DM6rq3YX64dYGKYSBrXz9CDh3jjt4/De+O
    i52eW1XLKPshhK++DaHsxxC+/93+y3Lf997VhRduzsEuRbGWmG2Af73ViLH/XotOOQqXnJCB1z5t
    wuwXOzsmCokAJWevxuyf7b/8G2akEfboa6F0Nxf23cuF0t1dKOwU61Y6/vo1eG9KbO6UpYGGrzxR
    Gg3NUcRXUHHVNACwfYWjCXQHgIEgXKuL/fengN6BiH2etwVY145YAJ8ousk1pPLzMAcv/BsJ3Qeg
    CAB2PGYVKlY4q+ZP35CDvx+ZkfB+tgF+XWTj219tfPebjR//sPHbYoPFKzevut+1i8IPr3VGl7zE
    ftXDr6zFwH4u3H5+FgLNgoy02E8fsoGbn1qL+17e8rMDe3bV2KmnxoAdLey1k4U9d7Kwc5GV0OwA
    gBc+asI5dzlr3UXdNP4Y36WtHV5c+SwROFTuPYAgd5BgaCswvKNK/Mdvi3iwtlWgC7AqiqOVEMtn
    tq/wS/joJqvU/7bM7v5JxH6/cYduOice0C++fy3S3YRTRsYfmW1poH9vC/17Wzi1VYfyhoDg18UG
    vy2y8XOFwS+LbPxaYfDbYoPmTeDkO210ersgB4CPH+rURlI60SsTmzYryNNchJ16avQr0ujX08Iu
    RRo77WCh3w4a0SWoydBrnzbhH/fF17R36KbX2+EhcxcNr66XmZ6h7MMdInKAw56p2VbxsM0CXQle
    ZsJ50VoLgfZnwnT2eSbCBG/WpdX3yvSCl9Pc+BzAzk7XCoYEZ9xWh29+tXHLOVlxc6edKCuDMLCf
    hYH92i61YaBihcEvC8MM4NfF4f//sshgdV3y9vEuRRY+nt6MNyc3Y84vIRCAwbu6cMkJmRi8y4Z9
    3v0GujbJ2ufnEHYusrBLLwv9emrsUqTRr8hCr+56XenvxlB9o+D2Zxvw8OuNCf0k2Rm0UIk6gEpW
    VkhZwUD2eW/n+GOURUFeSjnjOqKdXl54J4RuTCT4SfA+WXQz7V15KIAH2rumt7PCDWdlYcyhaeic
    u3nSEJZVhe3/GT+EMP27IL75Nb693CmHUFu/frd78hUOLXWjX0+NU0amo2dXvUH3zt6vaoN9DQP7
    WRi+pxvDdg/b0dsVbp51WV3HeP3TZtz93wasXJUUM7xaZnX9nzDfJsCxifc73aNLKm9IAb2jgr3M
    cyEI9yFOaK3FpH/+w8C0c++u3y/RtVwW8MwNufhwWjO+mB3EPnu6ccz+aSge4EK/nrql5/kmpz+W
    GbwxqQn/ejOAqlqOI43duHJMBg4tSYNrI/Q0z6HVSbWRLuikcOmJGTjpkHTsuJ3eLO8tEo4GlP8U
    wvtfNWPqN0GMGOTGkfuk4fxx9e3mNzx7Y86Us47I2A+JE8MaALmuo+ey/78HOgCIr3Muw3UWIJe3
    ON+iqWKFwY7HtF9u/OTYHJx7dAYaAoKJM4P48psgpn8XwopVjL13tVAywIWSAS7s3tfa5MUyq+sY
    F923Fm99tn7upFbA63fm4bgD0jbJPbqPrkbl6sRAP+GgdPzn2uxNrtH4axg/zrdR9mMIvp9C+Hqe
    jW5dFIbv6cJ+A90YNdSN7AzCcx8GcN7d7UfB/hjfBUXd4jKhFRA8rZTrX1S8bNW2joFtDujytXcA
    sxwHQXcQ1QjLLF2T+wmNnt8sAmV83sMUyaUCHNRGpDPQ6cAqNAQSSwlPvkL5C/kxKnFtveDbX0P4
    bXHY3v5jqUHQFuRlETrnKfTpoeHJVyjopFDUXWOHriquUyyhhsLAwNNW46c/1qvz3QoUBu3sQqA5
    PJWFGejbU+P00ek4aIg76WuHbCBzX3/CxJn+vS18+0rnP2VrB5oFi1YyKpYbVNcy/DWM+UsNVq9h
    rGkQuC3Cjj00+vUMe+D37OdCfk7bNVq80qDk7Jp2mVFmOmHNF4WRFtNttvwcIXlMa//rNBghmdvf
    bdZWHUagYojkg7BcKXqX9q78KQX0rVVN93kvA+QhB1WtGoKXFalnqWTlPACQsoKBTOp8AKcjUuCw
    9xmrE9rDLdSzq8Z79+Vhr53+Gl/mdf9eiwdeSc47fu/F2bj61OTmP3z7m43BpycecXflmEzcf2n2
    X/Le3/1m45hr1yQVshy0s4VZ/13XENiQyEQCjaNS/4zw9++2C5P5OwinO7SHZoCu0iWVj2wr2Nhm
    ilrE59k9DsgBoACEKxg81y73zLB9hWchU/+qS/znK6AIgtsAVO9clBxwF680KDlrNa54eC0Wrdzy
    abFt+6Enptufa0BdQ3LONd+PoXbP2ZCIw6aixSsNrnh4LYrPWp10XsIuvSwAWAPQY8qyeqvSqiPg
    sr61ywrPtH2e6UxmHoCr4vSAV4A8KLMK99xW8LHVhtdCPs/BSnAsETwCWiPEU7WWSTS4eoWjNBca
    QiSmPeYVTpCgoRyQR4zP8waUelYPWXmrTOhzT0197StolWSTiAb2c6GmnrHPuTUYsKOFI/dNwyHF
    bvTeTI6p1rTUn3z4rSEg+GOZSUr7SEab2ZB7bwwtWGYwqTyIj6c344ffbew/yI1BO7tQPjeU1O8D
    zfKxyqCTaI/KBikvGGzKPNezbY8hotwkH8GwUDHgPPtcZhV2NUwHE2gEQfIEqGLgPVeJf1JKdU9a
    Bff8B8A/4hz+EcAkBiZZ7J5GQ5eum/kt33bqZIKugync+fVoOA9KdPpsP0Pw4oCTV1f8XGGSajLQ
    p4fGz291gVLAj3/YmDI7iFnzbCxabpCbTei7vcYO3TR26KrRs6tG51xCRhohPY2QnUEI2YLmEJDu
    xgbb6gdcWIOp34aSPr8lX789Gn5uDcrakerD93Bh6lP5G/S8TUFBoBlIcwEui7A2IGhqFgSaBTX1
    gkUrDBatNFi0wuD3JQZ1awU7dNcYsquF/Qe5sVuk1qD/Savw2+LkJHrvHvpvv79T0AWQ8+E8p83x
    UUnkMyH1kTJ4n4ZV+tftkClF6XZG4z4qPPjhEIRnAZADop7Qxf5/pIDeHuRmFe7LTFOT/jDANAEm
    KWASiv0/tkzVkJk9MgyFDiKSw0E4pp0xPQCAqloT7DpyVdLeq8euysFFx8emxq6uY/xcYVCx3KBi
    RXiUMTPQ2CQwDHTrorBDN429+lko7u/a4HBYsiEwIJyxV/N5YVINMAefvhrf/pZYqufnEKonF27Q
    89oGKJ8bwje/2Fi80mB5NUNROJlIKaB7gUKv7hpF3cPJNU7e/CfeDeDi+5OvN1kxoaDR01kl45xo
    JJEvWNHbOsTjWxe1yEzvbqzkEAIOEWAfABlJ2cPM+9PQ6qkpoCeS5uWeCyB44k/+fCWAyQJM0sBk
    KvFXAoC8BY2ehaUMdTwgf0OCiaq9j16VtN3tsoDnbspNmBq7qakpKMjatyrp84fu5sK0Z5KTwEP/
    XpOUarx2auGfihj8WXrt0yacfUedY9GRE/XqrjH/vS6JTlkFYIIIva1rcibR6PnNACAzvB6j5GAC
    DgbhECQxJ91ZWuEfutT/xNaEqy1io0t5wU4sdKwAfk00B43+uTQCtjM3VP9j4jq0naedLHUFcBoB
    pzEgxuf5HoJJNmiyFciYrkdUTBfBFSgv2ItFHQHCyQB2aiPVdrWSBnrIBk6/tQ7vfNGMa07LROkA
    12ZLmmmh+sYNy1o7bHjy8fXtuyqUz23/vLoG2exAFwF8P4Vw/yuN+GBq8wb9dpBT6q9gMYjeZ8FH
    VlPllzQCtkzok2YX1A835d6DATmERfakjRd+9cqiT+O+1xRYyPDsaiCDCPAqkveouPq3Di3RRUBc
    Xng7QGOjmEqTAD8QYY6A5mjiOVBVc2kwQkB4QqqxaRgRlQpQQoKBSNDjK0lqJMJXApqkFCa3xEll
    Vtf+bPh4EB0OyKD7Xm7E9f9Z+6duUNhJYd+BLvTpobG9V6Owk0JuFkG38s8ZEwZKc0hQXctobBL0
    7KoxpL8Lfbdv35G3IRJdKWDB+C7Y3pucg/D2Zxtw27MNm0yiz18azmpbvNIgM51Q0EkhzUXIyaI2
    VWkta1JVy1hSaTB/qcG0b0Pw1/w5x9+4i7JxzWmZALAAoI8V+G0UV80ggsisrv2Z5WCCHCLAfog/
    lDFpJUsI3xCjXJSU6ZDMoOHVy9eBOtPT34gMItAgAQYRsHuUCWCD5F41pOqfm3OY42aV6OzzPgaS
    ix0OpRMwBIIhBAELAexpsn1h8BtDczTzN3D7P6DBCMkUWMjq2o9ZhgEyHMAgALtsIKPKFMFIQEay
    AYzP4yfQVGb5TBl+moZX3yrl3l5ujasAXLSh79olT+Gp63OwrCqcCPLjHzZq6gQsguYgoDWQlU7I
    ziR066JQ1E1j6O6udjvAxCycm9BvB51UC6gDBruTBjkADNuj/aKWnXrqpKV5nx4afXpo2CYcA/95
    oY2fK2ysWMVY2yhoaBIYA6S5AUWE/FyCJ19h/4FunHJoOi4YV/+nwJ6TSS8pqHupZOU8md29wBge
    QeWeJ00ZRjJzz7CA/9O0gkTmCDBdEWYgkDWbRlQ0yWy4wIX9jUuPMuWeQSIYyMAeEKRTZJtSPAwK
    3cjlnk6A/+IOJ9FlZsF+rNSXG/l0zcLyA4HCkl94DtxVP9FghGRWYVdj1N4EGUSQQUI0HIn7c7dH
    80D00Te/2GV7n7HqPfyJHIOW9NjNTbc83YA7n29f8r5yWy5OPjR5RSjQLPCOrE6YHXj9mVm484Ks
    zf6Oyaa5OmrH/+p0wL6DXcVCdAQBpfjz+SIhgH4AMENI5mhWX1HpygqZDReChQOMVgMJMkhknaTe
    KK2ToQ5wlayc0qGAbnyef/8ZyZgU+IV+JMgcEZqjYebAVf0jFoBR1HVnIzyIBMMADP8TUh8AsMsJ
    q/i3xWaDN4elgbv/kY3LTsrcICm9odQQEAw6fTV+XxJfqnfKISz9uGCDbeljr1sT1ybu00Pjm5c7
    Iytj81l8tgEeezNsPtl/Ihepz/aaf327y58F9goCTRdghgLPweq8r+GZzwgWDjCkwgIFMogU7Q5B
    2mZ4/cd1if+izbGum091F1oJ2gwmhyCNIIMBDCYSMBRge4LSk34g5jkE+kbBPI0c7yWoX5Fui2uI
    ggyPSP2hADq3d4v9BrrVb4sDf2qTXvuvtXh6fABnHp6OUUPTsEsvjTRXcsCI1/klmrIyCO8/0AlH
    XFmLBXEaVp42Kv1POcyOGJ7mCPTe22l88GCnpEHeFBSkuSgp52RzSPDzQoOJM5vx4idNCRlYezRi
    kDtZkK8V4HsCzRHi6dpWU5FWWYNQwW4MPYiJTpX8NQ+R8ewOhbQW85lA2HyWNCo7nuo+u3smGzMO
    Imci6cSVTUpBgH4EZA5AcxTMHOR4f0RjdQ/DMpyEBgEYBshe0ardG5OacMrNG9cU1GWFvfJaAT08
    GgWdCC6L0CmH0LNruMClcy5he284saZTDqFXN+1QhJHAvdsouP/lRjw1PoDqqPLVH17rjP69N5yP
    +2sY2x1Wva6wpaCTwvnHZOCa0zKRk5n8dhEBFi436xJillQarK4T+GsYi1ca1NYLQragulaw1G9g
    eP2abQy9dkcuTjzYUYNeAGBGeC/wHGSobxEwOxvRA4lkkIAGEWR3AO6/YK+uBfCisqxrafDyxr8c
    6PIWNO/gvQsil4bVHCwQYAFEFojCAi2yAJaZT4Nr1rT53bS8fLhcvQ1Rb2L0BlF/gHYFZKctzARs
    AL8BmLPugxv1GzTvxIJhBAwXopIV1Vyw/RHVG9Xl9ZXbcnH0/mmoqQ9ngQFAThY5NjfcWAo0C97+
    rBmfzw5i2rch9Oyq8OWT+X/6egdfUovuBQoHDnbj+IPSNksoraqWUR/JwU9PI3TKJnw8vRkn37Rx
    DHbJxwXoXqDqw1Edmi6MGVrz14DuEjbrKKx+h7PlMrbYziM0Q7AsjBmaB2AuCxZYFhZgcOXC1h53
    mdAnDYX129k2eitCbxB6k6C3AL0B6Q7gI2X5L26JUm1SoMtsuCRU+J4QHZ7EVauEpUKRqhCRhQAq
    BFKhYVWgKW0hjahocmYC1J+Ido280JZiArHgh17d+eDKz2rqpdufvWhhJ4WvX8zfIK/3JlFjQoL3
    v2rGCQf+eb/QY2824uLjMzdIu9hYWupnDDlzdbvlp4moIE9VVk3MPxJuK2CM7NUK1IOw8eHZJLVI
    LI0D5goicNhbWJSOtPoiG6pIEfUCUARFRSJSRIReyWRxEugTsiqPSRbsSQPd+DwPALhqEy3ICgEq
    CKgAqEIEC4WkwoJUIMeziPrPDbZlAqo/gXZtxdX6IXHHmI0G/xm31dW/MrEpf2Mu0rOrxou35GLf
    vVxbDDAfftWMQ0rcSHf/eSn8w/xw6+ri/lvmuad9F8IZt9ZtdCXgmEPTV7x8W27+ZgZ1KzBjAUDz
    WDC3NZhlbn831vh72op6kVARgYsAKhKFIhL0wp/NuIulB3WJ/+pNBvRwZpuaiy2TSccAlkcYQYs2
    sFCgKyySCjRWLqERsBMwgZ0BbHT85+3Pm3HSjWs2XmMj4KAhbpw2Kh0HDHajW4FKaNcuq2IsXG6Q
    nUnYeQe9wWrzv95uxCXHZ278Dno1gKtOSV6zbQoKfq4wWNsoKOqm0MOTuLXWylWML2YH8dKEJnw2
    K7hJhmG8eVce/nbgJnGGhwAsaWOaQs3TFuZiYeUiFIKQ3rWHTehFMEUEKgLQS4BeFO5g1B1bpgTc
    KDYDaOiqX9o70UoOeepkbLmSVgWgBwE9EA6RgUAgMFgAZHhs48NSA6kgUAWFGcE8RfIJkanA3quX
    YXpeHlzu/gy1a5R9swuSzITab2A4nXVjN6AIMLk8iMnlwbCdnhl2xqW7w11Q3C5C51xCYb6CJ19h
    r34Whu3hjumskgzV1gv6bb9pPlOgecNU6HQ3Ya+dLNTWC6Z/H8R/P26Cv4ZRVcOoqRc0BwWNTYKm
    YLi+fENTeZNhqPtsmOYUH8xUuQTcuStEFzGoF4GKCDjGGL6SenqKAPQA2FJRsvIvKBzRTPokALdu
    EqBDNpmqsSnIAtDCRQEJLzCDALGAck8QbiwhwUIQV4BVBUM+0IKFCNlLkGW5bRs7KkhfAH0IaieB
    9AHQC8C6neLJV9i9j4Xvf980E0tOPjQdj1yRva4JhNtFyMumDfJkt0cTZjTj+IM2jdbq6azQFJQN
    NgE65RAOH56Gw6Ny7NcGBGvWhgEPhBtYXPnIWrz6v6ZN8rx79LXgMP46BKCCQL8L+HcAvzPRfEvR
    7zChINjqaTQVEVAEkj5K5CA2KAI8PRHxvlMrENDWWNVNyWEzKaALYSYJzkfHIDcEOwqwI4QAkjAj
    IABpFmCjSQGLqMU3AP5KoF7SIktgqZBtJK/F0zl4F+uI73+3d90UD/XGpCacNiodh5ZsvuiNf41s
    VAfY1jSwnwvf/WajZMCmsdOzM6jNkIbJ5UG8Pqlpk737sN1dCwB8tj4CRLWAFTAS6g5IL4CKSOFw
    JVTERooAKx0KoHUq22ZMNN+MJCQzNxnQ9SL/q7K952ghHIOOT+kA+gnQL8ygCQQJMwJmKBUJgwgW
    HLFP2q/Pfdi0SYAuApxw/Rr895ZcHLP/pk+qWl7N2KvvpvPw71Kk8cZnTZsM6K1p/JfNOPO2uk06
    vXXE3mnTCJQvRMeTSF8GcgEb1MpREMa0YFshAiaqxqpXN5kzDohU4qT1yIUr1AnCuRDkGEEOoHIJ
    yAVJJ4jkAMgBVA4BOYDkM5BLkByAIseQ12G4pQBFR1Vv8vZJo4a6cckJmThoiDupbqq2Aeb8EgJz
    uAurU9+2lz8J4OSRGZs09fbu/zbghjNj/Zp1DYK5C2wQAYN3Sa4oxzDw2awg/vVWIybODG7S9exe
    oLD4o4LNXiK8iWkNgHpA6gVUr4A6ALUCqge4DkA9iOohVCtAHcjUa1AdCPUgVYeQqxbNS+vilXvH
    legyu3umbUyJJRwEJACWBrisINhaA3eDob1qa4GlqwGs3mgAzc7PA1Mu2J0DkRwbyCHF+RDkEFQO
    ILnrmYLkEVQOQ3IoXKOeA1A+IDmb20FIBIweloanxwc26XUnzgxi4swg8rIJA3d2YY++FrrkETrn
    KmgVznhbUc1YtNIgPyfcfeWY/dPQb4f4iFpZI46Aaw5J0im4sQ4557/nZhFKd3Ph10UGD77aiAXL
    DGrqGTt01ehWoJCTSWAGVtUxVq0R/DDfxje/hNpMlNmUdMS+aVsK5DZA9YDUAKgXoF6B6gRcD9Ca
    9eCleiHUA1wnrGqsFtCqYD1cXB/G0iYSRt926oRgloay8wDjgkE2QBm2UmlWGny0R2XDOqCHyrru
    z7b9XwXswKCwoFcAjIT9Gc1uGJ+n9Ys2A2gE0ABQkMB1ABmAagRsAKoDUfgckUaINIOoHiBbhGtN
    iGwB1VnaBMHSYMFaDqUWIIi14KYQhq+pTbY2V2b2yAA35cNt5YNNvhGVD8XpAGWQUH64VzflA5IO
    IINE8pkon4B8APkApQOShzjhkCOGb3qgr5NyBgg0CX6cbyM9jdAlj7BDV40eHoXS3dKwex8r6dxy
    Fee0iuWMu662MeRgYMxJrg0autCettFvB43rTg8HMRoCgh//sPHTHzaW+sNMatWacFZgY0Bgb8Yp
    zEck11yjCeEhijUC1CiRGpAKCKQJoBqI1ICoBkBASJrAVKOJa6B0DYJ2DSQUSGZfioAwPa8TJMNC
    hsqBUDpgZ0BRgW1c3clIrl1eqElUPiAWRHJAlAaiTACZEEkDkEMgC5B8QLRA5QLiRjhsnBHZszkA
    LG6O+BwN2mxhxQIOyJKQr+sZrpKVUyyZWbgXE09EckkGkZu3eTXIOgtA1lsDreNStP54i81EEDBH
    mAo4/KAagE4Dyj0wPnCYS0oI4VzgJoACAmlQ4aSFNUJkWIK1UMqGSD2EmggcANAAoaAAlVCyXJhq
    QMpYsOtg6WYNNEK4Ec3BZthr6mkE7LYMw04HKMOIyi/e3VXotujxoC2b3LAONAtOHZWOC4/b+EzM
    rPT4YOyeJzjm7R54+81aVO2/Bhdc4kJBEqm46WkbNkyyZUpNND31XgCXPFC/WUDushAq7u++QQh+
    MNUIELCIm9YBtK7TKmTMN7DycpDmTgOpMKAMp9lCOQAsUpwPKB02QdlNjHwAvZlUOpizYSkXKL0T
    ladp9iGPiVwkyG4RHgCyAXIBksflUOHYTWRPQ8IAZEC1FHkJrfcVkANeIqhab11H85ZkNSPaXhFP
    FF/BMEuUuiPywFsbqVZMxbOOUbR+zdaLI5E427rqogj7EQKRAIhUuplWC+hKA1weGB/AiDAM5jpA
    BwE0gCRQkENNxbu5Vk/7NrjJQ4yGgYvvr8enviDuuSgLuyTRV/7XRQYrqg32H+Ruc530tPjA3e1Y
    G6sesnGMyof9VSe8NHUVXGPqcdGZ6QnTXPOzCQ0BaaNVfPlNEF27aOy8Q/uG+S+LDK7/z1p8+FXz
    ZtskJQPcVV3ycCgL0omQQUAWg9xgzoWlNTrX5a/bPtwaJAqKOBZ40qqZVNT+WrdzHJMrtlInnyBN
    SN1Jxuep7UgOsr+C3pzchDH/rNus99AKGDHYjUNL3Ojf20JBJ4WQLVhVy5i70OCH+TYWrzA4pMSN
    q0/NbBPfXrNW8PnXQRw7wlnpWLNW8MThbpztKlj3t2qx8dR2y3H5PWEfgBN9NK0ZOxdZbVpcNYcE
    D7zSiE/LgujZTWP3Phb699Lo0knBZRGqaxlzF9iYVB7EF18HYTZzG/jX78zFCQelpzZpYqpPAT0Z
    4y4o6D66GmvWbnmuPWqoG0N2dWHo7i7ss5fL0bG2eKXBb4tNwjlrF58Qws01PWNk0LPw44AHm1C6
    e6zKPf37ELQCSneLPRYMCb76NoSyH0OYNS+ECTOCW3xtcrMIyz4pSKqV9f9zWqsINCO1DkDYuUg1
    ECxGuHb5GxH5moDPMtw0ceju7oq/6sGuOS0ciovnPW9sBtLayWDL3isUo1wSgHPhwZwrszF1TixQ
    87IpLnNzuwgHDQlrF/QXxbX22dO9MCtdTSDgMwFmAfgGwILwN6SaiMP4/z0RYbpFYv4ppA7Alinj
    22CVAyAbkFoABqA1AgkpYC1EmsJeU6yNOOzWAGQgXAsiOxzi4IjnHw0iKmgJ1UHZBlrXIKgMuLEO
    uelB2qOyYZ0zLk2nQygDbPINVD6T5JNQ+kFDXAsmzmy+aEsvwMSZQewxZjWuOS0To4elYbtCtc6R
    t3C5wZdzQhj/ZTPGnpE4hb9rEeCXELwUK51PoM549TpGl6cCbSa65GYRznqqAQuWGew/yIVe3dcX
    2SyvZkyY0Yz7X27E/KXmL9kch+/j/kqEfwapJpDUSIunnCQAZTUhaGoQqvYjp7sbgYY0ZGTlQIwF
    Y/LBlrZJconYDUJWJEqTDpFsQFwg1QkQHdZ2yUVANojTIZTBQBaB3JFojQaoUzj/+i9psNIOytFM
    wjcREA6vKfCLIPRM4qcR0GENws3z1gqkSQFhbzcoKMRrIGRAVAuwDUY9SDUDaBRII0iaBVQHJmOR
    qYEiA1J1MKEgQqYBdn6gdc16Ql9DHG85lOSvC68pSocgA5D89eE1SgckA+EwWyHaicmLAH2OXYWK
    FeYv/W5uFyHdjZjBiR88kBeTXx7tZyi8pysG6Pge/nG5y3Dvm2pdGm1tvaDLwVUx6nJTMKy6/5XU
    s6vGH+91SbZmviW0FkC41XiNEqmRSDgNoKaW8JqQ1MTz3rcMeki4Tyb0SUNOVSa0lQmXlQbhXLBo
    W3Q+lGiC5ILJTUThUJlwOhRyAGVBpBNINInKA8QFIJuBjMhezUa4FiPCXJJphCpLmNSZruLKLywA
    cJWu/FK+9+5qN6METGzBrgPrIFxoAJkAAqEmZKxZuyEdLRLefnb3THAoFyQ5AHJtqC7EUgRYOeTS
    uXA15JhybydAcsNJNMgBJFegIhl4aMmyy2AEww3MmdESRySSdZ73sJt+/aYUailN2LCNSgScdEga
    xr3412qDwZAg6PAVmtrZgvk5CqskcTD7/DVePPPKEvzjzPQIU4k9J9nJrJubThmZviGNMdLRqgac
    IvugjbciHKIJtzlsqY8AhfeVpYDOdTA+TwBAPQj1EKqN5I/UhzPXUA9QHcuaWsBdL0T1YKkTUvUW
    TK1FvAiEeqSp+pYklo3G0RRYsPJykOFKh+gMhJAFZdw2rFwoUVaDlOsRlWvRWopFbv55UtKztXrb
    RnqGE1UiCSr5FE5WSQ9zpUiCCqEz23ba+hgGQbFEWY5R4Fz/ef7SKMapo9L/cqDHo4amxAtTU8/I
    psTIyCcL1W9lwj6NYWm0GTyxtdHJh6T9FbfNCEtheNrmj2C9YGnp4d4SJpdIWBcIh/cC0pJ8ti6B
    J5If0rRey6AaEAJgaWqtZWjiGqDFLLFrkF5dRYPX1CTz4MlVr70FLT09bzGCx0K1xKLDL9VWelIb
    aAo5NK7f+msK2nYQEawA0XIRXrNzkeXu1kXdvmIVF21tD+1vpwVTda1ge2r/c49szofvp5UYvocL
    W6svu4dXLdm1j75ZiTQYEU3QXhC6bcEORJuCWrSMbuvyQ1rjZx3PWK9lrMtajWgZZHs/kSmVRyeT
    754U0E1PzxgCjt1GnJAhEBYTo0KULASrSD87VABmNZQqYFG7SEvDCsIgQHahcIoi7rkoG2feXrfV
    vVR7vdYWLWOMovYr0YqUG69PFQzfA9C6faj3VelYLkE0CG+xd73rguztiekFDouaECBLSNY1kHhb
    oOZplmqIaONSPYnRC4qLSKhIwh1g1tWbd2QSyGGc6RkD+F/aJEAnYFgHen8bwDIhqiCRhSBUCLBQ
    k1RA2RUwbm0DvZSSvhDqQyR7AxjDhB0hOm2ddhLHSjjx4DRc92+1UU0Mk6W+Kh2/c9OmAXo5tau6
    t9DaeeFt0dIkIhExBNOydsb1TUvxqb35GWBhJxXdLsoFtEjysDOFIGANgKiZhBcQ0W8iNF8I7zDR
    fMtFf0CaBCFXTwPqRUAvCIoE0tLQpAe2XEeljaLIsJJNA3QQVmxFKjcj3FxyYauechVCqsICFkJX
    LkUgL7t1PzklOJyFeoNd/QDJVk6WRJLv53YRzj06I6mRSBtLI61cPGD1wGNBP76w6xI+4opV8YE+
    d4GNActzk5Zh1nIXANOu3Q8Af3AzAsL4b0YvvBZajeualsLejJvl/GMzku96I0gDaBcJT+wBBFAi
    4GYB4A4CWKrWN3n8ToRe1RYWILNyKdZ6t7OFiv7innDJ0MpNZqMrxW+wUf9E2K2/RR4+AuQKgCoA
    VDBkoQWpwOq8xTR6fnN0m2gFjGSgN2zPTnCF45kUkc6baNsZAL8CmHPkPmm/3/1Cw80sm5frPxWs
    wt/dBXgloxfKzFpc37QMv8aR8H8kiGX/+4UmXOXqnvR9O691IxhqXNd3PRmw91ZpGOPqjCxSuDCw
    aLNAXRHMAXu7rgGoSCCDiDB4I0YjudtoAhAoAtgAqPcEAVmqEe4jh3Db5mktnV4xr7+Fev8ONqho
    XZdXol4CFEUYwZZqvWaU6DeS1MqTvGK550EIrtw0+karvu+QCgAVIrIwuu97gsEPffHn5qdvMKgB
    mqM05oJEcUj2IqISAUpOubmu6xubsBVSPLo2rSuucHsRdtMybm5ajpdDqxyYMVA3JXac8bTvQphz
    RTbGWJ2Tvuc3phF9XlqNxibBXqe2337ghYwijLTWZ1Hf3LwczwSrNvlanHhwOl67I7eSAJ9AfIr5
    a2j3GgOz67oe7hsH/mT2bqQDkcwTpeZCsCB6EENL33ZDqldEGwj3bWfuRYqKkunbnuSzPKSL/Vdt
    UqDLbLjE9r4vkNFJXLYGJBXEqBCgApCFAlWhLSzEWq6gEVXrBpD/hWB2BjV4DgytMJYMJFHD422e
    sh9DGH5ubGQjhzQ+zOwDNwiVEsKv3ISvTSO+sOtQKxuebDNAZWBy1k5t/vZ40I87m2Otqe9f7dwm
    s81fw7jpVMadwe036J7VYmP53csgAoy8rLbd8z/O7ItBen1mXq0YDGn4GfWyaZOLpj2Tj6Gxefet
    v990RVIORcawDNxi4N8AJgAA8r03C81UZER6EXMvKCoiQZEQekGoKLYU3BG4E8nyH7XJBzgA4QA9
    Z3gfBOQsABVEUiGiFgLhAQyapAKuUEV0Bw0HMPcWoD8BA7BlC2rij2SCGkTAMIHsh3V1jYmp5Owa
    fD0vdp1vT9sO57oLom4smGCvwb+Dfvxokm9kYYEwP2c3pEV9qoeDlbivua159u64PBwd6UdnG+Dc
    85pw66IdkEEbZk4KgAkXLIStBReMS1xHngbCrw7Pd0XTErwRSqwN7G/l4Es7uTr1vXayMPulpLWS
    OgJmiWCGgOZokllQqqDNSKYtBf711ARgAYHmCmFBayZAe1cuaCtU8/NgXEVGqIjC3Yl7EaQlYtAL
    wEsq4L882TZSyTvjWrjCCNhA5WUALmt3s8zskcGq+W5AncERDkWt+lLQlgH1XIDmADJHCc2BuH6A
    K9QtPGQRgwzoeNIyJNI0YEPsygUAfbzzDq76r+eFbow++ETQjzPdXdA6Em2BcKTVCUdYnfBssAp3
    Nq9AMIk72hCs5BB2UG09aZe7vfjRBDDRXj9kovUU0psfasQ/KrZHxp+Yq0QAahZorMpuv478MFen
    GJADwN46KyHQLRCeTN8Bhzb+hkXcfuVbT4+6CQIXiA53GowZRbkCHATCQQQJl6EzLyBgBoA5GvwS
    tPtnBHkAEw8CZBCIBgHoj1YtvzcxpQPYVSC7tnz2Fp9AuIKUXlKWvp4GL2+MzC78PvJvk9BmcyYx
    ha4E6PItlCETAjAX0jI5lb5BU8b3cK11waI9WDBMIDeJCpbCoEtLCuwGMBs/gaYK8Jky8rlRtCuR
    HP/CP7NP/2F+MKb3+woJYaa9FvtZOY4gOtddiEE6CycE/kgq/ixxwHh3+naY1lCPtZFr/BYB+vtf
    NqPfhALsYP35UHFgqcKCnMTP5gLhH25nc7N7OzF7r3IhjzTGpfXAyYEFCc/t39vCO/d1uoEgXzDh
    MQ2aZsCDSeggCEYmWaPRO/xPTguP2rbrRbUMYqQPtKXHot69FmlNuzPxICgZCNAgCAZsRvC3UCdA
    LmXbrgZwx+a4weYLEZB022ygJnwLkecg+IdiVawCmblqsX+w0vphUWhgwhjOCExnS9UyaBqIxkWG
    Q3ZJdp9TuEf4WAUerLhpFyZ+G5DhrPEdkXwI4DRS6HTT2c7Tn6aYxCrpQJ2Jp9J3gNUOu3GB0E05
    77Ou5MIF7vVWxs8LbdQ3Ct69HxhlbaRF5NeYNTex+Xex24P+KuNP2YT9VNo69b1YJ56gdeu5WVAK
    mUJ0OAleZJF5Sug8gOYpwhClaUeAzifQ2wCS7VmVQ8AwiFxHJB+ybVdxRuMCUXI1iNxK8KLKLixR
    q3NzlFJDQLgQJM+C8G1EsGwO6rr53AebiWRmwX6s1JebANQ/rZtxTmoOqrN/oNHzm2V2fp4dcu+t
    IMMJMkhIDUvGiZFYHcdnQvKZbsRE5LjSjbFHK5bjhegQxIlCi2BN72Oq7cUruQ0T2V1n4NPMndq9
    6bjmFXg06E/IED7J7Bv3+CqxMWjtPDRDkJlO+MeRWTjpk+2xndq4xK+XVDWuW7Ms7vHjXfl4JL1n
    XEnxib0G5wQq4v7+3vQeON0VXrIpdj3GxJHq3QpU1eKPCqAorqfaCOAj0NuK7XfQvKoSGYUlTOpw
    CA5KQs1PRA0CfEegOUI8XdtqKg2r9MuEPmnoUrsbQw2CYBDC01p321jJz0QHuoorv+hQEp2GVk8F
    8J8NVL+/AfAMCBcopYao1bk5utg/UC2qukCRmmaEd+HOdY8Zn2cu264aRTIZhFvC0noDQU6oCksA
    Ol/B3l6X+HdURt9NorpLBo1n215BghcjmoADamgOQOdr1nuccVjGxOijFZxcx5VL3d6EoDzTVZDw
    913IwmhXWHo3NglqP0zfaJADQN9Q/PYEx7ny8XD69gk3z0JuTqilHGqtD6gMt7KRR84pGpeemDnd
    YpQKyQkEfOZgyehw5qY8wkov5QzP9yx0kDL2f3VJ5WAVVF2F5AQATwNYuoHLkBW59qUk9BZrqTQ+
    z3LpXP8yix6mROaq1bmX6BL/IJVTmK2I9wbhgvC9aA7CdRPJ0n82F8g3u09MBMS+wjtBdG2UPyAI
    0I+AfANC2Pud7f1h3bjkGV6PsWQoMUqFUELAYCQ5HDGR15OA6UKYpEgmY++q74kgMqtrfzbmcCE6
    goCh7azJShC9qAw/CwvZzHQhgNNEkDH4jNX47re2tvqv2QOQS+3nGL0aWoWrm2L3YA/lxsysndFe
    ecnH9hqcG6iABcLMrJ2x/SYA+lph7Lr2J0T3pWlPkrfQeYFF+MiudT7mLsRtaW0TeC5qWoz3Qm3D
    lQN2tPDty52hFIIE+oBIHobYS1j0KSC6EMAO7TzGPBB9pIQ/RnFVuJPSLM/uLDiYgEME2Acb33Cl
    UYA5BPiEUKYFM6nEXwkAMre/G/WVuzF02OEHGgTIblGCwwboflVceSNtxqlQW6RASb4u6Me2Ph4E
    v4KZgxzvjy2gjjm3rGsRE3+HTRF2E/wEhUlsZLLldn1Fg5c3AkAY3Hw8iE6MjFlOrFEBXzDJ07pz
    1Yem2nuIIrlUgINan/T+l804bmzbMctlWbugKAnQNUMweO08VEfViz+Qvj1OcbUfUqoXg13WzsU+
    VjZez+i9yb7bSYEFmNoq/NVfZWBCVl+429k2DGDA2p9Q4xBH70YufJnVL4YBPhr0Y1zzijZ/e+/e
    PBy1X1qMJiUkj+ls/xt2nXdfTThPIEcnoTZXAPShAr+N4qqZRGCZ2SPD1sF9leBgAIdE1O+NpTpF
    tCcVVy503JJz+7tRVznAQA8iwKss8zbtXf3r5sbgVleJaMo950Pw5J98myoIPhORSdrIJBpevRwI
    l9miZ2EpQx0PyHEAtkviaktB9Kqy1ROQ0Gq29MmAXAmgn9O5hunB7qOqLqpew31a/vhp5k7YXSfX
    s/3m5mV4Jli93g1LGj9k90eyxaIHN/yGs9wFGOPqvMm+xSuhVbgmomkQgImZfbGHbl+x+s40YlTj
    7zF/zyaF9zP7ODrw3gvV4KKmxeu98p3VwiWfdLldE/0T69JU29ByCJ5R4n4M7qY0DtHpUHQuBDsm
    8WrVACaK0Ns6t+DTdZrk7IJuxtAhxHQICAchyXwKBwHzD13qf2JrwpXa2oCuDP+yAcBuJsgUQK5X
    woPUEH9XXeIfY5VW/Reh7NWhMu9Bxud9lHt6ljFoGiCXtgPyIIHeFqEj1WJ/kVJ4mi2+kC21GJCn
    HEBeDZGxSqyjLC2HfvJIpz6tw9btdXRpTUdYndoC18rFhlSE76LTsa/etCXYx1r5yI9I3v2snKRA
    DgBTzdqYv+WSxssZveN66bNbSXgi4L378nopogsU6TMBOh/A8qifdAfhFlbBRWyrWxXpj3Sxv48C
    D47Y44k6hBQAOI1IPuT66pXs875ll3tORz3VW8VVL+pS/ymq2N9VsQyEyFgCvgAh6eb0SnjeVoer
    re2BaGj11MQSXX4G5FGBHKbSqYsqqTpAl1SNo9LqbzCne7pd5j3C+DwvcUZjpSKZHAG3t527/gKR
    sSqoeqiSyhM0cY309L7ORn6DyHWI7c+1GoLblKi9Qao7kz1LBCMH72Lh1FHrTb5KST4KM0hnoWur
    2PPe7YScomlvnYUeatOWWGeSwlkRZ2A0I0pEU6LKVT1k4b3MHVGS4J1qWzHF00eno2SACwQUs5ip
    gAxXmvYJ52WgMppHADiPwT+yzzPZiO6uiv0XqLTgdhEG8V074jdfIMeT4EXOID+XFX5kl3tOx5z8
    XBpa9a0urbpXlfgPVNrqLCKjAXkEQCIgPxVxRG9duMJWSqFyz6FKcCwBXUVQI4qmaQpNoiGrlzid
    b/sKzyaixzcgrXEtQG8pkmep2F8mc/u7zdqqk0hwFYDd4/6G6D8qHQ9xACcDclu0L8G/mu1ex6zi
    pmZxX+n24pq05EOjrdX3dzN3xNANkNAVHEzKH/BnnHL7NfyCZzKKMDAJid4ojH5rf1pXqlqk3Hg9
    Y8d2n+3JYBVua16ONBcF54/vTN0LdLTN3QDBAyqk/sMuczaIxiJ+g8TfAHpcWfoZGry8UXzeEib+
    O4ROQvKdZ4IidLFVWvmMI3vwde5hoA8mof2IkC9AJbO85xpa9b+tEU/bTOd7KffswYJv2tNSBJgF
    oWe1MW/Q8Op68Xm8LHImiC5JoNY3AvSsgtxthIYQySNx7MbvldA/r3ti7YP3vdTQdz8rB29sgHPs
    G9OIwyK27WdZO8VVcx0ZYzg/dLOs7SS7DrvpDHRLokPNbNOAIxrnAwB6qTS8l7ljG00lHp0fWIQP
    7Vpcf0bmwrvPy7zEaPVPAoqd/CFCuFHD9Qlz6BIQrkD84qdagF5SKvQADVm9RKYX5BitTwTJOXGu
    3canqFgG09Cqb7cFfGxTIy5MufcKiDwQC3aqAeEVJfIslfh/AACZVbhnS3gM4aZ/jlwdwH+Vxbci
    hG5G6YdIZD+H8wIQuU2ASiJ6NBiS3D1PXY3li4Gfs/tDb8AyH9k4H1+bBnyS2TcpCbqliJO08/5n
    r8FZgQpsr9x4P7NPu6mwEeaL3dfORaftBD+83hlpLmoE5A4Q1UBwPxz6pQswSxMuh7Z+Z2Ouhsil
    ib5jS3iOiv1lACBfewew4XMAOg1A55jXFVyjS/0PbSvY2OZm2chM725MchwI3QDUidBs3ZTxAY2o
    aBKBMj7vYU7hsRgBCbyhNN0K0nVsm38CchEcGm8I0VSt5EaxZWwkuSYskWcFceiltXgjo7djzns8
    +pmbcFjj73gifYc2SSUdhT636/D3QAU+zuybsId8a/rBBHBo42/45OFOGFnqbg3m6VowVghXC3C0
    0+cm0Dtk6athNzHDdQ1Izk9svkXCc43+12gEbJnQJ810WXMkCe0NIA+CFUroXRpa+eO2hIv/F0Or
    ZHpBTjvhsXWcnEDvEvhG5HgWcX31hYDc7qwaUg2AsaK4llg96ZSZN+ycmiVdfsnc/tmMog163hlm
    LSo5hGNd+R1urdeIwSf2mg0K813etAS/9aqfP/ul/B0d9mQjRG4XjYUk9O84TRsaIbhfifteuLmQ
    bftGAGcjcdHWuvAcDV26elvHgNrWX9CUe85nSy2LEx5rLRneVqIHqJLKE4yonbm+6ueIhzU3ljvS
    24qxHxEfTkxvOoC8XqDOfunWvO/mZK3dIO87AAzT2TimA4IcAPJIbxDIq8TGF2m1eO3OnAoRnBZh
    oK0pE0TjwHSxCvIohENnEnsObmEV/M0Ye39V7L9AsdkNkDcjVocTrQvPmTLPhSmgd2SQ+wrviITq
    4urORPKRUjJQlVSeAIQybJ9naqQ6zTFJQwjHMPHbrDBVhI5w8qkp4G8EvnrHHuqIh67OwmMJilb+
    X6taAJ4OVuHuS7OwU0/rICLcqkidLECZw3rswy71FUQWKPCBCDcQiaYeJHjR+ArLAd1Zl1SdpIC9
    CPgQ8euls0F43JQV3pUCekdU18s8wwC6MQGQJiuhElVcdSSUVBqf5ykmNYuAfeP4op5WkP0V5GwS
    eitOEc3LIupfDLwHYFcg3OesfthaM5cDSFFbWsDN+Ll/rTnriHW2fB8W8z4UvQCie50lN40zULcp
    Q8dFzjGxzJv2ZoXp7PO+BaPXqBL/UYpVCQGT4nNWul58hcNTQO9gxMCpToJRgOmKeX9V4j8EuQXf
    Gp/3MrbVLwDOg3OX2x+V0FAh+YyhyuNI8TphOgVEy4n4BQCtM0NWX3hc1nH30PKgpLDdhm6TZfbp
    R6cdT4TWKk86sTwNke4CdQKA1Y7SXUs5mGuUxUMENNsJugI5nrWZZ8q949Bs5qkS/6GKZF8BvnI6
    n0Gnp4DewYgIbeo7ReRrJoy0Svz70NDqqXaZ9wiur/olnh0OoAmC21SOdbgovjGBFP9GMUZBycWR
    LLrWVKGUHLj/YH3+pbdqd3s91P4/0TuhGpx+HVmnHZp+ibLoIADRyfGnEfh6pa0jhTDT4RJh6W6r
    R7Q2p0ay5hoczxO5jjPoF+PznochVTOsEv9+DBwamanemoHkp4De0VR3oLxFIgvoaF1SVewq9n8q
    voJBts/zVcQO7xXnt9OV6IGiZC7Xm+/iSHEAeJphjWWFdwkojbrIT8ptH2aY/iXAqMOHp+HnEasw
    n5v/34O8goP4dvgqnHxoOgQ0woT4OeXSR0VquFvTQDb2B7D5bghuc3KsEbAPG/UNhNOVS/aM1Kw7
    0XaAPGV8hT4p8wxzlfgnWSX+YhE6CpHebEJtgb9NCb5tFujTC3KMplG6pOodIrBML+jOlroFwDkJ
    GNwagG5R6fY70qyeSADwOhE5j4AiEN0VrfITZAoB5zPoLQB7tvx9VS1/Neroxl3ece1YmL7tBzwc
    KQjBCfYfqz54L+PbwnzVOpdhngrxMeJSjwgwKob3Et0njJlE8gJiE1xaGPQ0zXK20diLhB4HEK9r
    Rzj+LnQtla6sEIEyswqP02I+pZLVdSmgd0TAz+yRwdR8KYhuRCLvu8jHROZCQ7qUhJ6Mt5kAmqNC
    8ndx4WZxGDxJwHsUcl3PrtBHAHZqtQlnaYvOmfFtcMrLl1pdbrW6/78E+q2hZRjzqKndd2/X/iaI
    fxMwvI2po3kkG30tIGfHri1NIMZYo+VJEgyNx4QBukYp/pCZ7kM48zEeNYLoXypk7qLh1fXb8rpv
    uxJdQGZW4d9I6D6E+2HHoxUCuVRbMkNs9aQARyY492ml1LMs/Lpz3TM9phQ9zcz/Q3hQXwvNVeQ6
    liUM/n+/HUDu413aTDf5/0Cf2nWov6ga//hbBgAsVMQjGeoNCPZqddpKpWQUGzoahFscvuwSxXQS
    Ew4B4Z/xtDMCJpNlnWNMcDdi9Xg7nWKXAXS7Kq58lgi8La79Nqk/SnnBYFPu+SrsQIsLcgHwsmL3
    ABCIbfVTApDXCckJAkxn5i8dQC4Q3KbIvMzMX7YFuSxR2jrOSOi/LRL+4uMzMHHgysBv3PT/BuQL
    uBkT9lpZHwE5APRiUe8okRPRNibelZmmKMEkiFwWa5fT9qzxBYhqROhoOHjlIx/3YLbtH0n0diqT
    do0Ximtjv5d7y7fVENs2BXSZ2WU74/M8xaLKo1TCaJrPUAcqi68TFXwhwhDi2H00W7FVTKKGU3g8
    bXSliS2QcxjqSxb1eRu7kFCllBklbD8S5axruvPSzNPu7rLE1G3isUVbI60Vxh35i/nmizPOQtuG
    ELsJ8JRi6yi0bSzRiRUmseA3ERyP8JST1h8lDZBHiOR4RVwq4cEMTpQLyFMSkPFK68cVYR8Ac+Or
    tzKYQdO4rPAjKetalAL61gbw2d0zTVnhdaz0zwjHw+O9VwhE96rVuQOITEE7UlwAekxrnGiU/d9I
    A4uYPcwsR0BUlSKeiLZhujWKeSSz6yoRjGzrCeJLehdZVz7xWIa+xloMg203ws4ArlOL8dijGWrn
    3q6xQurCKEY6glXoFuXCqCjpnKUUfQCIxVCjAaxxuPxpLOpNTXx2PK98a+nOQrupHP9AiIxN1DFG
    iA5n4nmm3DtOphfkbAvfocPb6LbPcxwBj0TZxE4fu0wrdS6UvToJW3yNQM6BoIGIXokj7SsV+DAD
    tQsBL6BtAUVAkRzKUIc5xNafCTuMcBUATP8+hPcud2Os1XWbBPq9oRU44sEg9h/UUpUmj0AoPdIW
    uTU9oFi9zYo/R9vmEEaELtQWytjIxDjfuU4EZwmoTpG8ggQdhQiYRJZ1LkKSZcg8E27nnJCWCeRy
    q6TqnZRE/4vIlHsuIODtdkBeD5JLdLF/uBGzaztSPKyqWxhEgh2J6OM4IF+gIPswUSkBL0aBPCSQ
    vzHTAAeQfy+ECcD68dPD93Ch29kNv761DSbTvBOqgeeMxrnrQQ4AdJlY8rlDEszVTDyciY6Kkraa
    SJ5iltFK1D4AOfUUzCXCO0rhIGWCxQJMT8DwD2Hb/pGJ99HF/v0AXBRhvPFoOwK91dELXzqsRJcZ
    nh3ZwtxEtcck8jFp8w9Ah4TpSQGOSiz06V/KSh8nJvB8tLq97iSRrzWrw1nxWSAaF62pCugUAAGC
    vIs28XWqUVqOYIP30Ta+26iUHnXTY3WTd33X6z7A2iY0RUw3a/Hd31aG7rwo90AW8wnahjZrlcJh
    zPggai1ECGcCqCPB24gpM6XHFFm3Gwl9EE8SC+RLzXwqkz43kVe+jXQPNhtR+j8J9wehWRkZQEOr
    5qck+pa0/bSckADklSJ0kiqtOsKILmGmue2AfI1ATlBiXmQ7MCMeyAn4TJM5iDVf7wByAHSlZrOC
    IG+0BTlYxJxhDO5FTBKHXCtsrrrtoiz3lP1XYrZp6PAg/9404pNhK3DbhVkugbkBhGujTulkjDzC
    Ln0q2nrCiQTPiqBRSM5BTFGLXMocelDDHk0iHzt/I9qflfYphU9Z6FDENpOMle7KOoyK/ccI5HgA
    K+OcnMakTkyp7ltcF6GCOFL5BcXuXbXmqezzvJ/Io94ioRXRXiDKZFLTESctFsBLxP4jWazHInnV
    0Ve6QWl8zkqPR/T0D8EdRGqfaCkkkC+F1GoBjiQC/n1jNp7fZbn5owOnyS7iIJ7Zean95C3ZIAJE
    MFIEawn4tO3no72VbQ4BcHvUJVwKeEcb/ACRyx2QfIbAepHq1EkAnorzGD1Y8JUCD1KwBydS5dHi
    mS/3/E9brlkq1LwrSJ6FY1mrdEkBfUur7kxfRv1pPhMdpEsqzza6+cAkpHjYq055BzH4BhK8iLg9
    x+gxFcg8X5TnNRDOcDwO8zIbnhBd+EKCz0V4NoCro37UoGFdTSIPr9vhFvDkXdl335izaMlKCXW4
    b7JSQrg6q2LZY3dk3eWy2mhCj5BYNyB60qngClH4iYD3oy6Vw5o+VYIJQKzmJMDRkicfKpuvgcjY
    OI9jgWgci3WvtkKHJ/LKt5HurvTj1ZCq81jUAYgqtBHE7LmUjb5FnHFlhdeB6HQQfaSM6zZYzXlJ
    2OIAsEqIz9CGfmVF7wDYI/73xzXKCj1rbNfHcWLzLynLuort0FcA7RL18yUKdDgDn8eq7LgIwN4A
    zmz1t6XKrQbW1Ji5Z13YWHjr8h2S6qC6NVC12LjRU4HnnsxYXZBu7cxpXBaVWPQyCDMcevbXKiMj
    WNO7iG32MV8Bwxm4DcD5jv4S5R5lJHgUgZ5C3NZR9IsCHWcr6aZYXkE744kJmETKPgcNuVWcEbgZ
    4KMBvKRLqsalgP4Xk11eeHziHPXWm0OdaID+JHgp7hRWQrMwnaFJvmTCxKg0zZbF+4BsPs1YakIM
    EyA0K6H9DeT+6GMEmWKg71Dgz1t/AyEaQ4zeILlz1RrGGec34p7qIni3crBXi43ru1Tg+acz0CVP
    AcAtIvILEb3Zxq+iZJRmulyAQ6O46SxN+iIW81W0ViUiX+t8OZBr6RmAnGzkeYrNIUZZgwnyenyt
    DPVC8ndteKZR+nUKD1hMROGc+eLKZzbn8MMU0JNV4WcVdk1Sioe96oHK6zjdM7Ydj2ytYj4a4qpg
    bSahVXFKq4X7gtbQkZInLzkVt4BwARi9HRxRDcpWxWzx+wD6tHo4n04LjuJm9x8tzGrVGlkx4rD6
    1a+m9+6/tYJ9ldg4rWnBbx9/kJnWtYtumW66RrG7t1HB8VEdexapoBrNbi5DbA+AB0XoFyJ5xsHB
    9glx5fGiPO86VLYBwELFcgiUKmDIR0hQtbYBe6DlO39Kyj433uCQlI2+haR4ErZ4WOhADleWvkMy
    PB9GiiXivfsKpWQEIJWs7WlOIBdgFgXkKM7FOEeQC14TlsWgGLscAK5lzae3BjkA0awu42bXVa01
    ki6d6JGXXsi8/e7ui+HfgDluW4qqxMbNngo8/lT67V276H+1OpTHKnS1Zrk8yi7egd18tgPzA4Ar
    oaQaoOdjl1MOY+V5mjLoeAGmOfy2FyuaBkijEr0vBIvj41YuNRmeT5WLn2KigxHPy77+Wx/KbP1k
    fN7zRDquYOyQDx6R4k/E6fUdA0ot6kQou4BFvYOEM7XlZ2W5RsIOFjKpiXFaC89V5NovMiXEoboK
    PyrIcQyaGS1ZCDKFiK5gwddoO+b3ZWVZV7JtL8D6eHOjIldP4dAzNWvlmLMvacC1S3qit0rbKr7B
    Eg5iXI/FeObfmcjPoQ8J9mkMa0krSd2ggB0ZGBflhzBKmVJhfUe0Cg9QjbJ1KVvm1fA88Zjvc6ey
    7AfYuKY4mVIA1SiWw+HmhWyriQl8LwDgZ6KTLRs/iZZXBDg4CbB0WOne4SR6RIr/lATIw151yz+c
    SQ5hUTMSgVyAcmW59kUw2IuhvnAEuWCxUvYow/axcUBer5R9sgE956A+NlBILmCh56JAHlCWdRPb
    9nVom1TyX0jIEsLhnXIILz+RhbuLFtf8ZP76JpO/chNu2W5R7fNPZKJTDkGA0bBVNohebHVaFguu
    U8BYtM0808z6WXIHzwdQG7XA+cayX1LsOgnAKgeo3cQh6wxl00gADjPFJZ8VJtlG7a7SgvvH6Q3X
    Qh4l8ikrPosC/tHteeU7unTvMA+7IVI8oqqfoQP4ijPwDEAntbMIHxK7TzIUOohI3ozj0PErzfsa
    1n1JZDxiPbwigr8R0d6AQ8hH8A8QsgDcH3XzW5Xmp9lW87G+Mk6U6P4M+8jWiTmBZrn88PPrT71w
    cffB++q/JoNujmnEQz2W/jD+iexns7PosfXvQTcqw2+xol9bCZAmBbsvi+tMkNwRtVpjQaom0m8/
    mu4X4qkk6kMHYcQCnKCVPYtZzwBoe4ffB4XkVL0q70PuUv8SRE5o5/t/QFboDNtOG6jArwLotq1J
    9w4h0TdAikOArxSbPTXJfE6nsvZADtALFPAfZ1TwOCJ5Lw7I1yiWkQhZeSTyBhzDOHSviDQCcm3s
    ppApStH/gBgtYJlKpwfYVje2AjkINJFKV/wMUq1VXsnKco3/7Pm8ytlHVOITe80W/w4T7DWYevgK
    THoxZ3luDt5uIwFFzkFp1QIi+aTVT9JZrBtVU8YDACra7jy6TYk9jQhO00evhmgLJPc47VkCXoHo
    IqX0KGfJDzcJvW7y609XQypPAvBgO3vmKLZdsywtVcrQngRMTmKfHcps/dhRpPtW/4CR6rRkKoci
    ueqVVxvbO5ogLyJqpHHs29O9urhyrCkvvBhCj8ZhfI2KZCQMVrCmmU4qfbhHnDmdYX2N2BhtgzLY
    QzQeF+CQtkINp2qXNY2N/VvrdF4GDrWYm1mpL9v4GnIK9+H6qlUAsp/9IIB5D2fgWle3zc6tBcDj
    IT8KL1yLi0/MCJsb7O5iVPDz1nX2THQgCKRYWjdoDClNOxuDIZHw1/rrEk3VEjqVYf2ImBHIVKM0
    BouRx2Nt+QjzBfYFq3SHirf1j04Yq4v99xmf9zJAHm5nz68V0Ll6ceXbvL3nnyDcBOcW4G1vQnKC
    VVz1dkqib9QD0tlJnOZnwigVqLyKjfcOgoxvB+QGhAt0ceVYU1Z4HYT+FWctQiLyN4TkD1Y0OY5z
    biXBnC6wXoRTIobgGmPJ8BiQA+W6xP8ah0I3R+Xsz7OK/ZNZqXOimMl4u37VsJYNfc5RGRg5Lrj2
    0ubFgYBsvu5HzRBc0by4eeDtjXURkANAhk2hYUQY3+ZbiZzjGlL5OSJdVSPkYiP/tEoq34j2mJPI
    fgb6EICuc7K3jZHXCDgjRhsIUx4DE+BWK5noKEQ3p1i3bLjXlHvHqeLKxyIFM4lSDrMJ8jr39Dyu
    cgvvZqGRSJArv4F7NCXRE5HxeZYB6B6fm9JUHTJjQNoWLa8LcEA7l2wS4FRd7H+Pywsfcs5bj9iC
    QmM0hSYyrKlo1c21jbSCHMCCUSC6wVHSu/WJHOK5UUxCFGRfMFayop/bmgJ0vrKCb7LtWt5anVds
    dmFlnQ7I9esVEvzvg6+CvqcfDt16+5qe6KHcm3TtV0oIt+Qtxt8vt+44Yp+0gQI5rBWnuk8pepJF
    FrS2jZVlbWeMOYpEnm39GZXo3YBQBpP6OoqprlFs+ovWzzoWExHdqwy9x5q/ilPENE+FmocbV/q+
    BHkH8QcrPqWK/f+wfd4DVNhEy0msxdBsbdTfkBYKik2vCmhEgtNX6BL/Vt3tsyPY6OnxgAjInXpR
    5YFw0Y6s5bskQL5aQQ7WAf8HXO55PgHIBaALdW7BeIH1ThyQAyJX26TcICeJhAYydC4H+dEYTYDw
    GpVUTWdNt0ZtzNUqA6+ysU5D25ZVc2noql8QqxX4jtzX1eO1lzPx+MBFmGnWbrJFLzNr8UD/RXj+
    vxk4fJ+0HiLwRb3DIVRcuRDAD61tY7bNKXpVzitRklAz2TdTafU3AF6JkczaeoiMOQcxXngAItca
    kq4QuSrOo+5qXGkTtKUnC+HviD9j7Xwp97xr1eRMU0odCMCfWALKYNZmtm2rAbS46uCIVz5O3y9K
    TznjNto+pAWOqjowUhVX3cw9vRez0OdJeEoXKTbDsDrva0n3vIW2sd3ozXW9Kq58VuqrE8RX5U1F
    9KYSedXRjiNcbZTsBODkqCMBZesbZVbX/pCoYyJP0h6VDYhRBWW8zO5eAMheUZzOB9Cg3CzCM/dl
    Y8l5lbgntEI2pjWVAHguVI0fT6rEfx/OQn4OAZBB4Xu1oT1kdkE3SFv1HSLn0Oj5zUB0hhudKLMK
    91RsbkD0RBWRE4yyBovQtY6eFJIXlLE+RrjwyEktLRHbfl0v8r8aaSgZ792Ols51E9BgflaaShE7
    HSaaChQwkbf3/FOV+G9nokPgnGCzIAX0jbUthF9u+7HkS2XxnpbNPin3vhkZqeRqZ/f+pGAPR7Na
    Kvl1nwjhmAR3fEyXVt3L5YUPRuqTnehXlRa6QASvOtnlBHyh0ullIvqPw7PcR8NWLBLm26PWP6TI
    PCHlBYOjk0GUyHhjzMFR54sl+A6C/hE1HpeclIl9bmmedZapaPwz1W/VYuNcUxHY6eb6spvOy4Ra
    f7ddLcYPURKNjK0OVCoK6IQBUuYtVpY8DiDY9lPSrTR01TIA9zlI0Md1evPbcbzwnY02byiyLwXh
    2zggPpJ7ev6jS6v+BdA9CcB+gMmgz8HWGqVkXwDftLMsGoRbxFf4gRVs+lYp2YsEn0ed80oK6Bv7
    gCVV/wbkegLeg+AfenHVQQhKHlvKlwCIbRmDKzQcxhXkTPpKCAcmOPlFVVx5ufEVjk2g1q9VUMdy
    s+uqONdqIIPzOMB3Iba2fZnKpPulrGCgIIrZCN6mktVLWdo64QBUoKT6W5JozUJ+AfH2ANoY5seO
    cPueeMn19n0DKjDVTn4mwVemHrf1W4AHXrDeO+7A9OixxRYUekHwcxRDO5iK/d8jaoQxKz6HBlev
    APBWFMiOkjJvsWL3/QAWRd2jOzel3RFPhSeghOEaG2kPHS+2eL4p896oSypvAOTRBI6pISyhqQiK
    UgHZLw5zifYFHc6utFlg8lCJ/xAQLiDgPUBuUMWVj6WccZuY7HLvySTyDNpOLI33cu9RIPMU5AQ9
    bNuTAPRLcO77FPAfbzK9J5DIK/HWRkhOENHVCjw5jsp+gSL1DTOXRR8X4HSrxP8y+7yfCGR0W6lN
    JRDXD6yCy9E61ER4SBf7rzK+wsVtk0PoeZDMii37lIsJdIQAh748oQlfPeDGDaobssiZpzeB8ajt
    R9H5Dbj4xEwowmdCeBeCJ6JOvSjiqzi3tb9OFfu7c7nnXgDXtGGGNneHRl8mNSdqnT9VJf6Rdrl3
    DIXNnrY8QrCvAe3iVNwCQCK93EEk78f5RiKQc3Rx1X+5vPDVdvIowsUweZ7FXFf1AghjktiCTUJ0
    sVVc+VxHwk2HSYGVKbBMuXccibyWDMhB9C8q9h+PtPoitkPTE4Jc8Dmtzj3JzvTuSyIvxGWAhIe0
    0FcK/JoTyAn4Qmn/8yz8lMPxb3Sx/1WZ6RkaDXIhzKTSynKjmk9CVDxZiYyXWV37R2eAicAHwaBY
    DyX9LpGhFaeNTsdlzzDO6zw/MMPBUTfbNOC8vD8CZz0bwiUnZYY7wgBFTORgu9IggUTb6V1R7tlN
    RYXZAGQbrU+k0upvoru7CHCozCzYTw+pfN2h84tiomd1Tc7LcaQsEckL2qW/A/BA3C8PetrM8hyp
    rKrTCTQhwS4JF8PUV+2sSvynRhxu7TqHSeRZ4/O8JDN7ZKSAvilBPrPLdibD85VDV9U42jpu08WV
    l6K8YC/W+qs4aZItG28WNcnR6Fw7QIl8EK0KtzqvTHkyb5SwPdY1rsoe8l7nUHAhCnIZEVgU7oj5
    JbeomfT3qCOVWFxVxiKHxBiOYS94DNAtTfPRKqd/wI4WJryW7bul66KHrw8tQ4MwmsAYF1qBq/MX
    vfDBa9kzB+zYJiK1gyVOziUZpKF9sYwFh2CI3wdgaRT3jLxLrAptlLqTCKLBlyMmv1x25i51Y+N6
    4YHOxrZfUav9/0yQwaZJ8BqCnr2JXX+LU/G2jlkx6EvM8pToUv+tEZMtmcSE01gFp0u5t1cK6JsE
    5AX7sdKzY8YSx9HshehcXeq/VWYW7Be3OKWVk06zexSylYehPoFzdhUAVGo2x3Nl4w0CHBRH2l8F
    izVIbnQ4+gaVVE2XWYX7OoQAl2lX1Xj5uqAfIWZw4Pt0AgyJRHv+6wHXfFDYEdeKgmgMhRAVknRb
    tOanNwpev/J5xlXdF+Cigj9w2tM2fnm389Pp7hgwueBqZgDR1TP9YakKgGpi7PRwY4YPYzzh5Z49
    9OKq8QAWRh0bHvJ5DqaS6jkAXnP4LjdAufJE6Jo4ZtY+nF94M4UHKC6L880yWOEDuJp6ait0RDwn
    XuSG+SyYHPJ5DtEllY8KcAKcE3CiaSCLfB0q9xyaAvqfBbiAjM97GSv1Gdpp/dMiUUXkSKu48jm7
    3Hs4KxU9OSWaFijDh8LNKjIYIF7Tf1sxn2gr104Aboiz8b5QQ/zPiKgnEBv3DyijrwcAw3Snw48f
    o8EIsVHnRpsMTBgvc/u7pW3zBpBgFlTTrrEJJLQALmt7B22kDrad2b+3hbdeyML4V7Kx104WbFCG
    gGJ7modcPREbMrIQ5AEE+Trq2vvKzB4ZLDQ+VlGRM+kEGACPx7423S0CUmzGIjrcBrgN+DldUvk8
    ARPjKOhjbcKeinB8lHe/NRWwURPBVoZqVofCseJtHWUp4CPbV/g3q8T/boLpMNHURQkmmnLvOJGt
    F09b5YOJr3OulHveiYTOrCR+UqnA+1mlVRPtMs8pJHGLU1rIr4hHId1da2y7zXhjB7oeSv0SqWrS
    jiq7pnO53HtOnISdB2jYikW2r3C0Q/uiBmXcz8rc/m7EjvddY2UXTrEbqveJ9kkISRlDD4r1NfB8
    o6nIwYNYZ4Mywqp9+F8YK3YmwDFAN0JFBMT0L2fiQSKI9sin2yq4j9VU+SWA6qgnOk2mFKWrtOCz
    ANa2BboMNj7vETR01TJIrL1NQAn7POcTm3OjtYiWvauAl2HMYhCNTWiHM01CTlNIKfvgBE0pAMBN
    oDfsMu85rpKVU5Sm4Qk0hqjXkeuk3PuRzOzROQX0ZEA+q3BPJusbx84t8SQzZB8qqZ5jfIUXEeEl
    JI6rr1FKDoWuXijGfpeAkgSe+A9Usf9hAV5G3IQcuhLCjQg3WIim5Sog94mACHSHw9teR0OXrjZr
    qw4D4Im6+cfUf25QSWzCjpD4nBozCOh3Yqd21VxHmjIcGEAGHCQ6Ab3EMZlEBrHEOOSggINpBGwA
    0Y6vLiaz4Qjaq7YWEttVhkhuF4FS4r7XEYCEcVBCQrgmjulVaJR+TTVW/gtEbyX45rtJs+s9VHf2
    K8UHI3FWnCaSp0255xrau/L/2rv6KKmKK/+7Va8HeogKI92NoGQ4IeeYMIQ1A9M9A6JEo4nRrBri
    oq6JH+jxK+5GMRqV7NnE7IJZdV2/NqLRgLrqSlyPCmpUzBFnekZMdBV344yaCA7zuicD9CAMM+/V
    3T+6e7666r1GEGbc+v0Dh/7gdVX96v7urbr3vi1YzAtRAoODQycpsfsNbpxUZ4keNFDNUw5Vihr1
    vcd18p5fEz7VUyrb6jfFrgHojpDftFOAT8ac7JvKi99jatRQQCs5fd9X6fhS0+04Al4SSXcFK6ZJ
    MTQAABLXSURBVLpTV2SSia+jBdkdfkv8VABfHap46Z9lKntnQcYmNa7LE4W/DPf/WMqKZoBqdc8M
    oao1D9oNVdIFFiCqBEFz2E7VgCbyTlTrRPvSmmDViYWN5jelKoPqAEDWZ+4G8z9h6BXVWX5L7DvU
    sHkXE3SxjYMZkX93ku59JglPwHwVjf2D2KkuAPCOmYS0QFVtfwB1nW1CiJMBdAdbaNzkNyeWUX3H
    n4TjzGOgubxVTEcooX7Hrybilugm+L1TQyT34Al+QZJ/PBrcrN8Uv0XfOWWo58nMCymVXa/S8eHl
    jVDiVws+w+sbc1QhVREmye63JL5lKA75B1mXXQUAsk+9AMKPCum2KwR4fv5SRz+rh28Su2SUnuV0
    PIHSckhtiE7IgbimNAJOrcQa6Q7KAaUWnfJjrbHoqlrpiA7MwLbxPQC9O+zfa3j9xMlSRZ7X+Nv9
    WYSyPnu9AM8HsIKAx0H4kWT/OQCQycxDuvbHDP6Wl44tDJDwAOg6L0oNQqrTEdhHjRap5vi/UV3H
    a4ro1NCAG/M1flP8btS2d0nH+ZqpO4wGY+H4U0cStZyR9DCeFBMEl3VPeyU5mcV4H0ohcR+Izwt5
    v2Kmc5z6zFq/KX6JoTjhYEt8CfpEu5BqDYz5yHQl/L5OgnO3fgfNH6cBAM3r7MbwyjJDmdU+2M4R
    8BzNcj/20vHTqPRMP41udyZQWjzOUaJVOf60kkZGQI6gqkq+iinKBHd4NWMGTXPQ26pKl0cEY3q+
    gvx5+pGDf4Efoa87yc2/Vun4WgYWDvqy9iE/NZVdD03nFCIwp2mJAjcOD0oS6Hb43pdZOlcT416j
    v74bR7Gki4j4kYDpvcxvir8vk+4tXjqxKCTjDSBcrJpj44XT/j3qwWkcTdwDhK43eCwmWItu2nVI
    hbcUJVoukplzkZkueGr8sTIGnQG6xKl3H/WaYmeAcEfwu+kOWZddxZIfNPnlRcmu2FkObSdXfpSS
    2VfKngRBqwfLYVW4gEJ6lyGtC8SBsBtjtrSDcYQuGKdVSoRKwNdZwMPhdLkoPWKDIlULKklwAYG+
    nn99SPRdCabV5Y4Dpdw0GP+heWmSioxZHiThAcT9iHxYbnIfD7r+WnjYf/Ga499zUu6TDJwPhGUB
    0SLui68FYmNF0r0AxD8PXcus/mKJbsK4+Du6xdWvTJmvkEn3WrwcG8dVuafLCtgRrpUp9x5unHgM
    CVoZ9JsZaBZbD1qiWsx+OYAcOc55aImnQLhI83qPYHntnvxsmuO+DdBVBbL3yd7dTxXKE5U8g1Dc
    pK2Qyvw+VNUkaC78MFG3TrqDVVQSdWuVXt+kKWC8pwvIidLIO8A4kRlCyt5nkD/uYjCupgb3rT1a
    kORdo5H/ALC4ryVxHHlqsUnCE/Mxamr8J8LJXh1ySYaIcW9fOn6Ck8qsCsp4GxhDHOdH6UW0TKmS
    yewNyF8JNqStogdbx2+0RDeN/oyNvWA8prNWDDpb1mdv58bDq/wovWC8uDL0g8tkMnMTv5aoUUI+
    EdRiGUCXZLEI47elwFhqdttoCTKVrspLSKFZbTdTfcef9vS3y5T7r0JwLQg/oKO3b0VzfKZGUezE
    7uxbukAcgVoBOU3vn3m5vPUu+VTU5NN6hGmki7wT1SKZeVvzuYlois2i2Vu3M9PlQnGtrM/cssdr
    INW1GfoabyQU3w0xditDLQn4ihs8L36sFHwGgC0B74sI4HFumvhVWZ+9vRwrXUyG4fUTJ8tU5i4G
    fcdgmB7Lp+paopsfKKJ+jKE3qbYpJb7hpNxHeMPkqUr0NhKQDP2ifCbadZyuOlzX/HC4WlCEs6B4
    pxLiYZNfTowXZcq9V1XlrgfwZc1bXMH+TZ94o6vLviGTmV/m5QtO0Fjm1xCfIXSBOAZafW0grhCM
    Y9JLdxI5/aL2q/VHbJiBl6srCNhQKuvzz+zUuyuoIfuHT7wGVMUyw3n3FxXtvsFJZX8VIOGFAB5E
    LwvF4iwAQZ0vDlJCPMvp2BfzVjpE8hd+v3LEen41/gUn5T4piE8cpjA+EI66dsTxaqQ9EM3u3CI8
    NQvAZSBaLhTNj9R3vMyNhx4ZlpwyaOf9L9GTWYyWKVUK8vmgu+4FK7XUqcv8liWvgrlsVY4izvlE
    YLA2n50Z9PeU6srtk3HQyHYC0si5NVplQmglY8tn0lt0RhQkDUSnatZH3iMY1zOTNefpRT99r397
    w+ZdTHyV1ncu1BIIkvBFf93Z1PEKmG8IMQgxRbSW0/GESGavBAIDeUVMUxKvcGNiJiWzrwhBR4No
    OYDLhKdmFVJ0LdFDJ3peZ7dMZe6SSfdaanDf4vTE2rDklMFWl7oOXoQxh0d87nuytMNpiV/3tKhz
    l6nm+PWssaKDJTvNbi9YGX6wJBAG/oGTch/ZF7+f11WPZU0TQAanfV0gDvmjNTD0Fl1V5ADWHFty
    FNEJpo1pmmRfW4FFsapliLTGjz2a30yM2xdj4KSyjwO4PD+2Q7CysEbagyQ8MR+TrwyTvYmA1SFk
    /4LP/BRejlUWMt6eKeMRD1MCv+PmeD3VdWyUSfdamcrcVThhgSX6ni76ltj80OSUQcE06uFTsaPN
    Y+p9iIC5IR/5M4mKc9E08WhA23lliGTv96frs8uZ6dsAbgbTUuFzTfHyy76AF905H5oouSROE7GW
    6A71tTJYR/Q+ati8izTfR4RKmrGxF5rzZAaqoUyllrhWRmSTxuJW+DvV/H01DjKVuUv4XAOmpcg3
    Yfz24NbFIRIeICz1muPHk6fOA+h/g0UdzeGoeBTdYFKR74Z0eSmOQ38yzEjn0YgmutecOFkpehbB
    ySnFlfm2VBUn0YLsDjU1fltwuah8ZFSwOh27fRHklw+R7IOJVe8+JVOZJbLevZEasm37eFJ0EvgD
    qst2sP5GXA/mdH1E0ProRYutke79frvm0gxXY27nFmgj4FRLs9s7gdKo/L6S7wMyPtsm690bZSqz
    xKl3nyp5PVjC5/31sficUDgDwM7gJcQnqWj8ftRv7pHwTkF4mSlgUDKMJfonI/lZZSSnFPG+iKgT
    qGFzl5+O/yPyRx8h+wJfhlTnG1yhgvxyMNNVA5J9v0FnIdK8ARFCaSAOwHt4GQLaM/08iZVmHNWA
    366R7zQFa6dX6MgMRg2vqx6L0oKRAGG/WreChL8q4C1x36OHsdl9h4kuLOMr/1a1xH5Gqa6c6BWG
    Hm8lyCfDNCcusETfA/jp2GXEvAphRR/zyAhS36TZnVu8psSFAH5SxmdWOKnsr/x04nxDF5Bi8Ot5
    mdq/JYO4JTYJwEzNjpNGb6wGmvLXBLSiMnEE9De8cgVLW6n5XNRMdAiM334E6SPvEVTuqEFpxRkA
    mMEtVUfszzFzUtn7gyQ8gY5Vn48vdpLuw6Wlt3STQNf7TbEraH5HtoyMt0GeFa/w0/EllujlkLy8
    5JQitgvBJ1Ky812vOXEyEd9VxmfeFKri7wAgfzRiRI4c58Lhkv1T//1MJ0BTykqwTPskag2xiVbP
    cLTG/STWBOM4b9EZbDhLF6bIOxRErdATHb6Sx+/vdUPwLoK+Ik0xznICAIhI5gomNIZ/Id3qpWML
    qa5rUxkZb4P2TvzCb04ss0QPkuvp2EllJKcU8bEAfYPqsm9wUyJpbn44ZB62CkmnU8PmXYUF/nqA
    ZF9yACQ7iOlYrQ9+yKFvEPSBOGZqJfjT9BNMAT563qILXfEJFNNVVavB96mF0/nfOr+XoP0Nn+64
    pbo2M/jKAFdtAwDQbPRJ8haBkA3jBoEe6mtJHEfJzneFEqcgOONt8IRc4zUlTrFENy/yY8p8a68C
    TqOUm+amw76kiNcgvGCkYlZn0xy3v3KKSGZvytcIo9cBdAHIEOFZZv6bwVH2/WuZuKpUSeL3NGNj
    L5uIDmo1BOIGW+uoZvajQ63+8GdR1ZKMTQ5qaTb6GHhds0GMPxBj56Sy9zPxGQUZn8nPKb0O0A9F
    Mru8//nqujYpojNhvsLa73sLxau5OT6LGjpaFNGpmuM+gyDgY0YSt0ZU9hoI5eTw+gw+O5LK/jYf
    UFLnAAiv6sH4mVOfXTt0MqAA9zYAt42UIWBNJxBiNPEGRJRHM7WTSL1tCo6p0Z+Z6BwUjAMAqoZU
    bfC0RXFreM30MYpzTaChZ/4cfPX0013Q+a6moZ1NI3Xui4XA7U9D3nqIAp0J4M1I0n3Ja46fSfl6
    9U7IRE60Ft08OmERTmamiwuXKYr/sil8/8BzIpX5KUYBFPCbUovOzaZAHIBdSHa1s/FWXMDxGhDN
    J8/opTsTphVueekkawUO3TaTRWnkXZWWfx6REMnMz0PKQRcGQm0a2EgyTzDzhQjLeCP+oyW66WE8
    XomggnyEa5z6oZJa9PAqDKsyOmxn/ZAocnYxN3ykI5LKPA8a0jyhW/bxq6ZAHIA2IiiC4VYc0M0b
    EDFYIIG10yv0VWaAQd/5nn5TErWS0ThMEfwyksw8NxrGmgiKVOScwPUDfFBYlwOqoT77AIiCjvRy
    QqmVluimgZ/X2a7ypXZ157rLZDJTUryBFmR3CEfNBbCyxH8i7BZSLKTkR3/BKIJMZi4VSh0LxqVC
    0l/RvM52UyCOgNZCcUnDXQDKwasy30U4KKstEFnAJN4wuZIMkXcwaimVcYWko8C4VLFYIFOZi0fT
    WFPD5i7BaiGG3w7Mr6VVwlFzdddaZdK9FeAbdSRn5kWFHnMjyCsegeD1Eycrh74PphoQ2pnpFZly
    nwo76uI108dgwrYZHmSVI/xt8OSHNNfN4DMArynWQkRzNGZpuWB1nyot71TgIp8vBdYqRVv0KkpN
    8SPieDJ0KhXK/5IicY6u/zuA38tUpvazML78aiIOx5/qKTnegd+FreM3lpNq6jUlTiHi+WBMhqCN
    os9/gOZ1to+4DQ0WI38RbkBEefGczkdnosXM/KEAnjcQ/buSxOuKWdvaV/iY7jv4CnFpbAAAlOJv
    EmESEd2veblXdB188EjLvbYY4dLdwgAVmwF9IA7S99vImIcOsCkXfUARVDKRMbVWSKqWBNNd/gpU
    bauxE2SJbrEP4LMwy2OFVoIyEt0B5UBeZYCoizqgoMqp1TD56ACUIW3WwhLdYo/9KzaR6WPM7dwC
    ooBGf/pSz/0+JqES5AUVy5hGqYwL42kIW6JbolvsEx9d0x65gDYiMJuP1gD05jxNQsvAJuJHQeZa
    6Mz9Oe7vGb7BEt0S3WKvSb4ODumy2VDIWsv/GdS6N0eBqb4URUTmzC588bvZVIRiJq+ZPsbOlCW6
    xd6gMj4DBqIyqLWQF54wutDJrh0IsOggVNIs92OYiigyYrwu9jnAeOe9AhO2zbATZYlusRfw2ewD
    M1QrxnRXw3xMuqNwI9Bs0fsj8mTOzBonP89kDsj5NiBniW6xdyCztYYktPkkAmV7/jtUNECaF6w9
    5wI2m2nSbNEDn9HCEt2inAkiXm2U1ZLbTOmpg4keKN0HNgGznw5Vjd3CdJbuCcf/TztTlugWe2PR
    k53vgni55qVu1HZ2ICDizkEpqgP/Q2BOekHeV9P8jiy0FVzoFzSn8492pizRLfZ2kuqySwEMLye9
    kghMAWfoYsCiBxXYrBz6Xq00L/wfNDwj606RdG+wMzTy4dghGAVWncBA5vK+9KTVgvl0EGeE49wM
    AIpVNRGFSHcESPdilRnOmWJ6xXN64cgfqz4/CyChBJ6IJN2X7OxYolvsY0RSHesArBuyCQiqNuX0
    cf/VVhU1B+b7o+5Bt+OqAYBmt+8EcKOdCSvdLfYj+M3EuOAONoU8cxUYjAspJwUAqOINEw6xI26J
    bnEg0Bt4Iw7Fs/Ggm3FUtOgUUuHUj1TbAbdEtzgA8BWCyVckr0BoMA4qmOg+kyW6JbrFgQApFWLR
    C+2Y2ByMU/3BuEAfPew+vYUlusWnN3vBVrafvMzh0t1cN664JViLbolucUAsOodId/j5K7AUHoxj
    RohFt9LdEt3igIApWE7LATkeejPOCZHubKW7JbrFAWN6iJWlci7M5F+LUIh0t0S3RLc4QAhrElCO
    RS+8RjIXsqn82o63JbrFgZi8XdklBHrG/A6nHKIXrP2u7Wb/HGtFj/tDO+KW6BYHALQAHjnuaSDc
    gmGdQQm0Bn/+aBs/BgmgIsiV5w2IoHZrNzE/Pew1H4xbycn8NS0wpMpajI61YofgM+KuNx56pBLy
    TAAJJm6SO7MP0QJ4vH7iQcoRgbJcOH3jafbW7bwOjl8ZP4sYDQBcwfIRqt/yP3Z0LdEtRvoG8Goi
    riS7gUQXfBjVZTvsaFnpbjFqZzjoDL243YtKO1CW6BajW7RFw81+Ge+xsES3GMk87yvHWluLbolu
    8Zm36PCtRbdEtxjN8IQI7aLiKbKdVizRLUYznDFIg/FhgIO+yYlSox0pS3SL0SzcZ7kfKxLngrC7
    9EXsViTOLbRksrBEtxjNiKQ61glWcwlYC2AH8q2anhWs5tpKrv8/8H/P2pViNdz9EAAAACV0RVh0
    ZGF0ZTpjcmVhdGUAMjAyMC0wOC0yM1QwNTo1MzoyMiswMDowMKo87UQAAAAldEVYdGRhdGU6bW9k
    aWZ5ADIwMjAtMDgtMjNUMDU6NTM6MjIrMDA6MDDbYVX4AAAAAElFTkSuQmCC`;
    return logoPng;
  };

  os.fetchFileToString = function (iconpath) {
    return fetch(iconpath)
      .then(response => response.text());
  };

  /**
 * converts a base64 encoded data url SVG image to a PNG image
 * @param originalBase64 data url of svg image
 * @param width target width in pixel of PNG image
 * @return {Promise<String>} resolves to png data url of the image
 */
  os.base64SvgToBase64Png = async function (originalBase64, width) {
    return new Promise(resolve => {
      let img = new Image();
      img.src = originalBase64;
      img.onload = function () {
        // document.body.appendChild(img);
        let canvas = document.createElement("canvas");
        let ratio = (img.clientWidth / img.clientHeight) || 1;
        // document.body.removeChild(img);
        canvas.width = width;
        canvas.height = width / ratio;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        try {
          let data = canvas.toDataURL('image/png');
          resolve(data);
        } catch (e) {
          resolve(null);
        }
      };
      img.onerror = function () {
        resolve(null);
      };
      // img.src = originalBase64;
    });
  };

  os.getTruthyObjectValues = function (value) {
    return Object.entries(value).filter(([key, val]) => val === true).map(([k, v]) => os.translate(k.toUpperCase())).join(', ')
  }

  os.convertTime = function (time, format) {
    return dayjs(time).format(format || 'DD.MM.YYYY hh:mm');
  };

  os.roundDepth = DiveService.roundDepth;

  os.getTime = function (value) {
    return Math.round(((value.time_end - value.time_start) / 60000) * 100) / 100;
  };

  os.getAllDiveOrganizationParameters = function () {
    return [
      { value: 'total_dives', name: os.translate('TOTAL_DIVES'), converted: (d, value) => d[value] },
      { value: 'over_ten', name: os.translate('OVER_10_M'), converted: (d, value) => d[value] },
      { value: 'under_ten', name: os.translate('UNDER_10_M'), converted: (d, value) => d[value] },
      { value: 'alarm', name: os.translate('ALARM'), converted: (d, value) => d[value] },
      { value: 'winter', name: os.translate('WINTER'), converted: (d, value) => d[value] },
      { value: 'pool', name: os.translate('POOL'), converted: (d, value) => d[value] },
      { value: 'deep', name: os.translate('DEEP'), converted: (d, value) => d[value] },
      { value: 'training', name: os.translate('TRAINING'), converted: (d, value) => d[value] },
      { value: 'other', name: os.translate('OTHER'), converted: (d, value) => d[value] },
      { value: 'other_test', name: os.translate('OTHER_TEST'), converted: (d, value) => d[value] },
      { value: 'emergency_procedures', name: os.translate('EMERGENCY_PROCEDURES'), converted: (d, value) => d[value] },
      { value: 'arc', name: os.translate('ARC'), converted: (d, value) => d[value] },
      { value: 'circle', name: os.translate('CIRCLE'), converted: (d, value) => d[value] },
      { value: 'column', name: os.translate('COLUMN'), converted: (d, value) => d[value] },
      { value: 'vertical_column', name: os.translate('VERTICAL_COLUMN'), converted: (d, value) => d[value] },
      { value: 'side_arc', name: os.translate('SIDE_ARC'), converted: (d, value) => d[value] },
      { value: 'other_search_method', name: os.translate('OTHER_SEARCH_METHOD'), converted: (d, value) => d[value] },
    ];
  };

  os.getAllRescueDiveOrganizationParameters = function () {
    return [
      { value: 'total_dives', name: os.translate('TOTAL_DIVES'), converted: (d, value) => d[value] },
      { value: 'other_dives', name: os.translate('OTHER_DIVES'), converted: (d, value) => d[value] },
      { value: 'alarm', name: os.translate('ALARM'), converted: (d, value) => d[value] },
      { value: 'training', name: os.translate('TRAINING'), converted: (d, value) => d[value] },
      { value: 'rescue', name: os.translate('RESCUE'), converted: (d, value) => d[value] },
      { value: 'has_exposure', name: os.translate('HAS_EXPOSURE'), converted: (d, value) => d[value] },
      { value: 'long', name: os.translate('LONG_DIVE'), converted: (d, value) => d[value] },
      { value: 'double_tanks', name: os.translate('DOUBLE_TANKS'), converted: (d, value) => d[value] }
    ];
  };

  os.getAllExposureOrganizationParameters = function () {
    return [
      { value: 'total_exposure_time', name: os.translate('TOTAL_EXPOSURE_TIME'), converted: (d, value) => d[value] },
      { value: 'total_dive_time', name: os.translate('TOTAL_DIVE_TIME'), converted: (d, value) => d[value] },
      { value: 'total_exposures', name: os.translate('TOTAL_EXPOSURES'), converted: (d, value) => d[value] },
      { value: 'has_number_of_dives', name: os.translate('NUMBER_OF_DIVES'), converted: (d, value) => d[value] },
      { value: 'long_exposure_time', name: os.translate('EXPOSURE_TIME_LIMIT_REACHED'), converted: (d, value) => d[value] },
      { value: 'exposure_type_skin', name: os.translate('EXPOSURE_TYPE_SKIN'), converted: (d, value) => d[value] },
      { value: 'exposure_type_other', name: os.translate('EXPOSURE_TYPE_OTHER'), converted: (d, value) => d[value] },
      { value: 'exposure_type_airway', name: os.translate('EXPOSURE_TYPE_AIRWAY'), converted: (d, value) => d[value] },
      { value: 'exposure_type_mucous_membrane', name: os.translate('EXPOSURE_TYPE_MUCOUS_MEMBRANE'), converted: (d, value) => d[value] },
      { value: 'exposure_type_injection', name: os.translate('EXPOSURE_TYPE_INJECTION'), converted: (d, value) => d[value] },
      { value: 'exposure_type_ingestion', name: os.translate('EXPOSURE_TYPE_INGESTION'), converted: (d, value) => d[value] },
      { value: 'exposure_type_eyes', name: os.translate('EXPOSURE_TYPE_EYES'), converted: (d, value) => d[value] },
      { value: 'exposure_reason_anomaly', name: os.translate('EXPOSURE_REASON_ANOMALY'), converted: (d, value) => d[value] },
      { value: 'exposure_reason_long_operating_time', name: os.translate('EXPOSURE_REASON_LONG_OPERATING_TIME'), converted: (d, value) => d[value] },
      { value: 'exposure_reason_insufficient_protection', name: os.translate('EXPOSURE_REASON_INSUFFICIENT_PROTECTION'), 
        converted: (d, value) => d[value] },
      { value: 'exposure_reason_other', name: os.translate('EXPOSURE_REASON_OTHER'), converted: (d, value) => d[value] },
      { value: 'exposure_method_firefighting', name: os.translate('EXPOSURE_METHOD_FIREFIGHTING'), converted: (d, value) => d[value] },
      { value: 'exposure_method_other', name: os.translate('EXPOSURE_METHOD_OTHER'), converted: (d, value) => d[value] },
      { value: 'exposure_method_rescuework', name: os.translate('EXPOSURE_METHOD_RESCUEWORK'), converted: (d, value) => d[value] },
      { value: 'exposure_method_smokedive', name: os.translate('EXPOSURE_METHOD_SMOKEDIVE'), converted: (d, value) => d[value] },
      { value: 'exposure_method_waterdive', name: os.translate('EXPOSURE_METHOD_WATERDIVE'), converted: (d, value) => d[value] },
      { value: 'exposure_method_chemicaldive', name: os.translate('EXPOSURE_METHOD_CHEMICALDIVE'), converted: (d, value) => d[value] },
      { value: 'exposure_method_maintenance', name: os.translate('EXPOSURE_METHOD_MAINTENANCE'), converted: (d, value) => d[value] },
      { value: 'exposure_method_forestfire', name: os.translate('EXPOSURE_METHOD_FORESTFIRE'), converted: (d, value) => d[value] },
      { value: 'exposure_method_firecauseinspection', name: os.translate('EXPOSURE_METHOD_FIRECAUSEINSPECTION'), converted: (d, value) => d[value] },
      { value: 'substance_type_pah_compounds', name: os.translate('SUBSTANCE_TYPE_PAH_COMPOUNDS'), converted: (d, value) => d[value] },
      { value: 'substance_type_other', name: os.translate('SUBSTANCE_TYPE_OTHER'), converted: (d, value) => d[value] },
      { value: 'substance_type_metals', name: os.translate('SUBSTANCE_TYPE_METALS'), converted: (d, value) => d[value] },
      { value: 'substance_type_solvents', name: os.translate('SUBSTANCE_TYPE_SOLVENTS'), converted: (d, value) => d[value] },
      { value: 'substance_type_asbestos', name: os.translate('SUBSTANCE_TYPE_ASBESTOS'), converted: (d, value) => d[value] },
      { value: 'substance_type_carbonfiberscomposites', name: os.translate('SUBSTANCE_TYPE_CARBONFIBERSCOMPOSITES'), 
        converted: (d, value) => d[value] },
      { value: 'substance_type_radiation', name: os.translate('SUBSTANCE_TYPE_RADIATION'), converted: (d, value) => d[value] },
      { value: 'has_bio_monitoring', name: os.translate('BIO_MONITORING'), converted: (d, value) => d[value] },
      { value: 'post_incident_actions', name: os.translate('POST_INCIDENT_ACTIONS'), converted: (d, value) => d[value] },
      { value: 'equipment_cleaning_ozonisation', name: os.translate('EQUIPMENT_CLEANING_OZONISATION'), converted: (d, value) => d[value] },
      { value: 'equipment_cleaning_number_of_washes', name: os.translate('EQUIPMENT_CLEANING_NUMBER_OF_WASHES'), converted: (d, value) => d[value] },
      { value: 'equipment_cleaning_additional_care_required', name: os.translate('EQUIPMENT_CLEANING_ADDITIONAL_CARE_REQUIRED'), 
        converted: (d, value) => d[value] },
      { value: 'equipment_cleaning_equipment_destroyed', name: os.translate('EQUIPMENT_CLEANING_EQUIPMENT_DESTROYED'), 
        converted: (d, value) => d[value] },
      { value: 'has_description', name: os.translate('DESCRIPTION'), converted: (d, value) => d[value] },
      { value: 'has_protection_changes', name: os.translate('PROTECTION_CHANGES'), converted: (d, value) => d[value] },
      { value: 'has_tool_care', name: os.translate('TOOL_CARE'), converted: (d, value) => d[value] },
      { value: 'has_food_supply', name: os.translate('FOOD_SUPPLY'), converted: (d, value) => d[value] },
      { value: 'has_equipment_care', name: os.translate('EQUIPMENT_CARE'), converted: (d, value) => d[value] },
      { value: 'dirty_equipment_handling', name: os.translate('DIRTY_EQUIPMENT_HANDLING'), converted: (d, value) => d[value] },
      { value: 'has_dirty_equipment_handling_incident', name: os.translate('DIRTY_EQUIPMENT_HANDLING_INCIDENT'), 
        converted: (d, value) => d[value] },
      { value: 'has_dirty_equipment_handling_incident_description', name: os.translate('DIRTY_EQUIPMENT_HANDLING_INCIDENT_DESCRIPTION'), 
        converted: (d, value) => d[value] },
      { value: 'has_dive_time', name: os.translate('DIVE_TIME'), converted: (d, value) => d[value] },
      { value: 'dive_time_exposure_limit_reached', name: os.translate('DIVE_TIME_EXPOSURE_LIMIT_REACHED'), converted: (d, value) => d[value] }
    ];
  };

  os.getAllDiveParameters = function (sites) {
    return [
      // { value: 'time_start', name: organizationService.translate('START_TIME'), converted: (value) => organizationService.convertTime(value) },
      { value: 'time_end', name: os.translate('END_TIME'), converted: (d, value) => os.convertTime(d[value]) },
      { value: 'site', name: os.translate('SITE'), converted: (d, value) => sites[d[value]]?.name },
      { value: 'depth', name: `${os.translate('DEPTH')}`, converted: (d, value) => os.roundDepth(d[value])},
      { value: 'mean_depth', name: `${os.translate('MEAN_DEPTH')}`, 
        converted: (d, value) => os.roundDepth(d[value]) || ''},
      { value: ['time_start', 'time_end'], name: `${os.translate('DURATION')} (min)`, converted: (d) => 
        os.getTime(d) },
      { value: 'type', name: os.translate('PURPOSE'), converted: (d, value) => os.translate(d[value]) },
      { value: 'method', name: os.translate('SEARCH_METHOD'), converted: (d, value) => os.translate(d[value]) },
      { value: 'assistant', name: os.translate('TENDER'), converted: (d, value) => d[value] },
      { value: 'pressure_start', name: os.translate('START_PRESSURE'), converted: (d, value) => d[value] },
      { value: 'pressure_end', name: os.translate('END_PRESSURE'), converted: (d, value) => d[value] },
      { value: 'volume', name: os.translate('TANK_VOLUME'), converted: (d, value) => d[value] },
      { value: 'apparatus', name: os.translate('APPARATUS'), converted: (d, value) => d[value] },
      { value: 'equipment', name: os.translate('EQUIPMENT'), converted: (d, value) => 
        value && d[value] && os.getTruthyObjectValues(d[value]) },
      { value: 'description', name: os.translate('DESCRIPTION'), converted: (d, value) => d[value] }
    ];
  };

  os.getAllRescueDiveParameters = function () {
    return [
      { value: 'time_end', name: os.translate('END_TIME'), converted: (d, value) => os.convertTime(d[value]) },
      { value: 'address', name: os.translate('ADDRESS'), converted: (d, value) => d[value] },
      { value: ['time_start', 'time_end'], name: `${os.translate('DURATION')} (min)`, converted: (d) => 
        os.getTime(d) },
      { value: 'dive_type', name: os.translate('DIVE_TYPE_SHORT'), 
        converted: (d, value) => os.translate(d[value].toUpperCase()) },
      { value: 'type', name: os.translate('PURPOSE'), converted: (d, value) => os.translate(d[value]) },
      { value: 'buddy', name: os.translate('BUDDY'), converted: (d, value) => d[value] },
      { value: 'pressure_start', name: os.translate('START_PRESSURE'), converted: (d, value) => d[value] },
      { value: 'pressure_end', name: os.translate('END_PRESSURE'), converted: (d, value) => d[value] },
      { value: 'volume', name: os.translate('TANK_VOLUME'), converted: (d, value) => d[value] },
      { value: 'apparatus', name: os.translate('APPARATUS'), converted: (d, value) => d[value] },
      { value: 'exposureId', name: os.translate('EXPOSURE'), converted: (d, value) => d[value] ? 'X' : '-' },
      { value: 'equipment', name: os.translate('EQUIPMENT'), 
        converted: (d, value) => value && d[value] && os.getTruthyObjectValues(d[value])},
      { value: 'description', name: os.translate('DESCRIPTION'), converted: (d, value) => d[value] }
    ];
  };

  os.getAllExposureParameters = function () {
    return [
      { value: 'role', name: os.translate('ROLE'), converted: (d, value) => d[value] },
      { value: 'exposure_reason', name: os.translate('EXPOSURE_REASON'), 
        converted: (d, value) => os.translate(d[value].toUpperCase()) },
      { value: 'exposure_reason_other', name: os.translate('EXPOSURE_REASON_OTHER'), converted: (d, value) => d[value] },
      { value: 'exposure_time', name: os.translate('EXPOSURE_TIME'), converted: (d, value) => d[value] },
      { value: 'substance_type', name: os.translate('SUBSTANCE_TYPE'), converted: (d, value) => value && d[value] && 
        os.getTruthyObjectValues(d[value]) },
      { value: 'substance_type_other', name: os.translate('SUBSTANCE_TYPE_OTHER'), converted: (d, value) => d[value] },
      { value: 'substance_id', name: os.translate('SUBSTANCE_ID'), converted: (d, value) => d[value] },
      { value: 'protection', name: os.translate('PROTECTION'), 
        converted: (d, value) => value && d[value] && os.getTruthyObjectValues(d[value]) },
      { value: 'protection_changes', name: os.translate('PROTECTION_CHANGES'), converted: (d, value) => d[value] },
      { value: 'exposure_type', name: os.translate('EXPOSURE_TYPE'), 
        converted: (d, value) => value && d[value] && os.getTruthyObjectValues(d[value]) },
      { value: 'exposure_type_other', name: os.translate('EXPOSURE_TYPE_OTHER'), converted: (d, value) => d[value] },
      { value: 'exposure_method', name: os.translate('EXPOSURE_METHOD'), 
        converted: (d, value) => os.translate(d[value].toUpperCase()) },
      { value: 'exposure_method_other', name: os.translate('EXPOSURE_METHOD_OTHER'), converted: (d, value) => d[value] },
      { value: 'number_of_dives', name: os.translate('NUMBER_OF_DIVES'), converted: (d, value) => d[value] },
      { value: 'dive_time', name: os.translate('DIVE_TIME'), converted: (d, value) => d[value] },
      { value: 'bio_monitoring', name: os.translate('BIO_MONITORING'), converted: (d, value) => d[value] ? 'X' : '-' },
      { value: 'post_incident_actions', name: os.translate('POST_INCIDENT_ACTIONS'), 
        converted: (d, value) => value && d[value] && os.getTruthyObjectValues(getNestedObjectValues(d[value])) },
      { value: 'dirty_equipment_handling', name: os.translate('DIRTY_EQUIPMENT_HANDLING'), 
        converted: (d, value) => value && d[value] && os.getTruthyObjectValues(d[value]) },
      { value: 'dirty_equipment_handling_incident', name: os.translate('DIRTY_EQUIPMENT_HANDLING_INCIDENT'), 
        converted: (d, value) => d[value] ? 'X' : '-'},
      { value: 'dirty_equipment_handling_incident_description', name: os.translate('DIRTY_EQUIPMENT_HANDLING_INCIDENT_DESCRIPTION'), 
        converted: (d, value) => d[value] },
      { value: 'equipment_care', name: os.translate('EQUIPMENT_CARE'), converted: (d, value) => d[value] ? 'X' : '-' },
      { value: 'food_supply', name: os.translate('FOOD_SUPPLY'), converted: (d, value) => d[value] ? 'X' : '-' },
      { value: 'tool_care', name: os.translate('TOOL_CARE'), converted: (d, value) => d[value] ? 'X' : '-' },
      { value: 'equipment_cleaning', name: os.translate('EQUIPMENT_CLEANING'), 
        converted: (d, value) => value && d[value] && os.getTruthyObjectValues(d[value]) },
      { value: 'description', name: os.translate('DESCRIPTION'), converted: (d, value) => d[value] }
    ]
  };

  os.tableToCSV = function () {
    let csv_data = [];
    let rows = document.getElementsByTagName('tr');
    for (let i = 0; i < rows.length; i++) {
      let cols = rows[i].querySelectorAll('td,th');
      let csvrow = [];
      for (let j = 0; j < cols.length; j++) {
  
        // Get the text data of each cell of
        // a row and push it to csvrow
        csvrow.push(cols[j].innerHTML);
      }
      csv_data.push(csvrow.join(","));
    }
    csv_data = csv_data.join('\n');
  }

  os.list = function () {
    const d = $q.defer();
    $http({
      url: `${options.api.base_url}/organizations`,
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((results) => {
        d.resolve(results.data);
      }, (err) => {
        console.log(err);
        d.reject(err.data);
      });

    return d.promise;
  };
  return os;
};
OrganizationService.$inject = ['$q', '$http', '$translate', 'options', 'DiveService'];

export default angular.module('OrganizationService', [options, DiveService]).factory('OrganizationService', OrganizationService).name;
