import * as angular from 'angular';
import inputDialogModal from './inputDialogModal';

/*
 Confirm an ng-click action with a modal dialog window (requires UI Bootstrap Modal service)
 Using this modal requires two things: apply the attribute to the dom element and prepend
 the confirmClick() action to the ng-click attribute.

    <a href="#" ng-click="confirmClick() && deleteItem(item)" confirm-click>Delete</a>

 */
const confirmInput = function ($translate, inputDialogModal) {
  console.log('confirm input')
  return {
    link(scope, element, attrs) {
      // ngClick won't wait for our modal confirmation window to resolve,
      // so we will grab the other values in the ngClick attribute, which
      // will continue after the modal resolves.
      // modify the confirmClick() action so we don't perform it again
      // looks for either confirmClick() or confirmClick('are you sure?')
      // const ngClick = attrs.ngClick.replace('confirmInput()', 'true')
      //   .replace('confirmInput(', 'confirmInput(true,');

      const modalMessage = attrs.modalMessage;
      const modalModel = attrs.modalModel;
      const modalController = attrs.modalController;
      // setup a confirmation action on the scope
      scope.confirmInput = function (msg) {
        // if the msg was set to true, then return it (this is a workaround to make our dialog work)
        if (msg === true) {
          return true;
        }
        // msg can be passed directly to confirmClick('are you sure?') in ng-click
        // or through the confirm-click attribute on the <a confirm-click="Are you sure?"></a>
        msg = msg || attrs.confirmInput || $translate.instant('ARE_YOU_SURE') + '?';
        // open a dialog modal, and then continue ngClick actions if it's confirmed
        inputDialogModal(modalMessage).result.then((result) => {
          console.log('result', result, modalController)
          scope[modalController][modalModel] = result;
          // scope.$eval(ngClick);
        });
        return false;
      };
    },
  };
}
confirmInput.$inject = ['$translate', 'inputDialogModal'];

export default angular.module('confirmInput', [inputDialogModal]).directive('confirmInput', confirmInput).name;
