const AuthenticationService = function () {

  const auth = {
    isAdmin: false,
    isAuthenticated: false,
  };

  return auth;
}

export default angular.module('AuthenticationService', []).factory('AuthenticationService', AuthenticationService).name;
