import * as angular from 'angular';
import UserService from '../services/UserService';
import options from '../services/options';

const DiverController = function ($stateParams, $translate, currentUser, organization, UserService, options) {

  const vm = this;
  const translate = $translate.instant;
  vm.divers = []
  vm.searchDiver = '';
  vm.organizationId = organization._id;

  vm.shift = currentUser.shift ? parseInt(currentUser.shift) : translate('ALL');
  vm.isAdmin = currentUser && ['admin', 'superadmin'].includes(currentUser.role);

  vm.shiftFilter = function (item) {
    if (vm.shift !== translate('ALL')) {
      return Number(item.shift) === Number(vm.shift) || item.shift == undefined;
    } else {
      return true;
    }
  };

  const diveTypeMap = {
    deepDiveDate: translate('TRAINING_DEPTH_DIVE'),
    problemTrainingDate: translate('EMERGENCY_HANDLING'),
    searchTestDate: translate('SEARCH_TEST'),
    medicalsDate: translate('DIVE_MEDICALS'),
    dentistDate: translate('DENTIST')
  };

  const getTestNames = (warnings) => warnings.map((w) => diveTypeMap[w]);

  UserService.list($stateParams.organizationId).then((divers) => {
    vm.divers = divers.data.filter((d) => d.diver).map((diver, index) => {
      let showWarning = false;
      let shiftWarning = '';
      let exposureWarning = '';
      const warnings = diver.tests ? Object.entries(diver.tests).map(([name, timestamp]) => {
        if (Date.now() - timestamp > options.tests.deprecatedLimit) {
          showWarning = true;
          return name;
        }
        return undefined;
      }).filter((t) => t !== undefined) : [];

      if (!diver.shift || diver.shift === '') {
        showWarning = true;
        shiftWarning = translate('SHIFT_NOT_SET');
      }
      return {
        ...diver,
        warnings: getTestNames(warnings),
        shiftWarning
      }
    });
  });
}
DiverController.$inject = ['$stateParams', '$translate', 'currentUser', 'organization', 'UserService', 'options'];

export default angular.module('DiverController', [UserService, options]).controller('DiverController', DiverController).name;

