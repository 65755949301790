import * as angular from 'angular';
import options from './options';

const SiteService = function ($q, $http, $stateParams, options) {

  const siteService = {};

  siteService.find = function (name) {
    const d = $q.defer();
    const returnData = [];
    $http({
      url: `${options.api.base_url}/organizations/${$stateParams.organizationId}/sites`,
      method: 'GET',
      params: { name },
    })
      .then((results) => {
        angular.forEach(results.data, (value, key) => {
          const regex = new RegExp(name, 'i');
          if (value.name.match(regex)) {
            returnData.push(value);
          }
        });
        d.resolve(returnData);
      }, (err) => {
        d.reject(err.data);
      });

    return d.promise;
  };

  siteService.get = function (id) {
    const d = $q.defer();
    $http({
      url: `${options.api.base_url}/organizations/${$stateParams.organizationId}/sites/${id}`,
      method: 'GET',
    })
      .then((siteData) => {
        d.resolve(siteData.data);
      }, (err) => {
        d.reject(err.data);
      });

    return d.promise;
  };

  siteService.add = function (data) {
    const d = $q.defer();
    data.organizationId = $stateParams.organizationId;
    $http({
      url: `${options.api.base_url}/organizations/${$stateParams.organizationId}/sites`,
      method: 'POST',
      data: JSON.stringify({ site: data }),
      headers: { 'Content-Type': 'application/json' },
    })
      .then((results) => {
        console.info(results);
        d.resolve(results.data);
      }, (err) => {
        console.error(err);
        d.reject(err.data);
      });

    return d.promise;
  };

  siteService.update = function (data) {
    const d = $q.defer();
    $http({
      url: `${options.api.base_url}/organizations/${$stateParams.organizationId}/sites/${$stateParams.siteid}`,
      method: 'PUT',
      data: JSON.stringify({ site: data }),
      headers: { 'Content-Type': 'application/json' },
    })
      .then((results) => {
        console.log(results);
        d.resolve(results.data);
      }, (err) => {
        console.error(err);
        d.reject(err.data);
      });

    return d.promise;
  };

  siteService.delete = function (siteId) {
    const d = $q.defer();
    $http({
      url: `${options.api.base_url}/organizations/${$stateParams.organizationId}/sites/${siteId}`,
      method: 'DELETE',
    })
      .then((results) => {
        d.resolve(results.data);
      }, (err) => {
        d.reject(err.data);
      });

    return d.promise;
  };

  siteService.list = function (organizationId) {
    if (!organizationId) {
      throw new Error('No organization id given');
    }
    const d = $q.defer();
    $http({
      url: `${options.api.base_url}/organizations/${organizationId}/sites`,
      method: 'GET',
      cache: false,
    })
      .then((results) => {
        d.resolve(results.data);
      }, (err) => {
        d.reject(err.data);
      });

    return d.promise;
  };

  siteService.listAsMap = function (organizationId) {
    if (!organizationId) {
      throw new Error('No organization id given');
    }
    const d = $q.defer();
    $http({
      url: `${options.api.base_url}/organizations/${organizationId}/sites`,
      method: 'GET',
      cache: false,
    })
      .then((results) => {
        const tmpSites = {};
        const sites = results.data;
        for (let i = 0; i < sites.length; i++) {
          tmpSites[sites[i]._id] = sites[i];
        }
        d.resolve(tmpSites);
      }, (err) => {
        d.reject(err.data);
      });

    return d.promise;
  };

  siteService.loadOptionsAjax = function (queryParams) {
    return siteService.find(queryParams.data.q).then(queryParams.success);
  };

  siteService.formatResult = function (res) {
    return res.name;
  };

  siteService.formatSelection = function (res) {
    return res.name;
  };

  return siteService;
}
SiteService.$inject = ['$q', '$http', '$stateParams', 'options'];

export default angular.module('SiteService', [options]).factory('SiteService', SiteService).name;
