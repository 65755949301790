import * as angular from 'angular';
import SiteService from '../services/SiteService';

const SitesController = function ($state, SiteService) {

  const vm = this;
  vm.removeSite = function (siteId) {
    console.log('REMOVING SITE')
    if (!siteId) {
      return;
    }

    SiteService.delete(siteId)
      .then((ret) => {
        console.log(ret);
        $state.go($state.current, {}, { reload: true });
      })
      .catch((err) => {
        console.log(err);
        alert('Poisto ei onnistunut');
      });
  };
}

SitesController.$inject = ['$state', 'SiteService'];

export default angular.module('SitesController', [SiteService]).controller('SitesController', SitesController).name;
